import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { withTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./stopFilter.data.form";
import InputDate from "../../../utils/InputDate";
import InputSelect from "../../../utils/InputSelect";
import {
  LIST_CATEGORIES,
  CATEGORY_CLEAN,
  CATEGORY_CHANGES,
  CATEGORY_OTHERS,
  CLEAN_CAUSES,
  CHANGES_CAUSES,
  OTHER_CAUSES,
  MACHINES,
  CAUSE_OTHER_FAULT,
  CATEGORY_HOLIDAY,
} from "../../../../constants/index";

const OutFilterForm = (props) => {
  const { t, handleSubmit, filtered, onClean } = props;
  const [causes, setCauses] = useState([]);
  const [causeSelected, setCauseSelected] = useState(null);

  const onChangeCategory = useCallback(async (value) => {
    let causesSelected;
    switch (value) {
      case CATEGORY_CLEAN.code:
        causesSelected = CLEAN_CAUSES;
        break;
      case CATEGORY_CHANGES.code:
        causesSelected = CHANGES_CAUSES;
        break;
      case CATEGORY_OTHERS.code:
        causesSelected = OTHER_CAUSES;
        break;
      case CATEGORY_HOLIDAY.code:
        causesSelected = [];
        break;
      default:
        break;
    }
    props.setFieldValue("cause", "", true);
    props.setFieldValue("machine", "", true);
    setCauseSelected(null);
    setCauses(causesSelected);
  }, []);

  const onChangeCause = useCallback(async (value) => {
    setCauseSelected(value);
  }, []);

  return (
    <div className="row no-margin">
      <div className="col-12 mb-3">
        <div className="accordion" id="stopFilter">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                {t("forms.stopHistory.filter")}
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#stopFilter"
            >
              <div className="accordion-body">
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-3">
                          <Field
                            id="dateInit"
                            labelField={t("forms.stopHistory.labels.dateInit")}
                            className="form-control"
                            name="dateInit"
                            component={InputDate}
                            language="es"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        <div className="col-3">
                          <Field
                            id="dateEnd"
                            labelField={t("forms.stopHistory.labels.dateEnd")}
                            className="form-control"
                            name="dateEnd"
                            component={InputDate}
                            language="es"
                            dateFormat="dd/MM/yyyy"
                            defaultHours={23}
                            defaultMinutes={59}
                          />
                        </div>
                        <div className="col-3">
                          <Field
                            id="category"
                            name="category"
                            labelField={t("forms.stopHistory.labels.category")}
                            // className="form-control"
                            component={InputSelect}
                            placeholder=""
                            optionLabel="label"
                            optionValue="code"
                            options={LIST_CATEGORIES.map((item) => ({
                              ...item,
                              label: t(item.label),
                            }))}
                            addOptionEmpty
                            onChangeValue={onChangeCategory}
                            classNamePrefix="dark"
                            mandatory
                          />
                        </div>
                        <div className="col-3">
                          <Field
                            id="cause"
                            name="cause"
                            labelField={t("forms.stopHistory.labels.cause")}
                            // className="form-control"
                            component={InputSelect}
                            placeholder=""
                            optionLabel="label"
                            optionValue="code"
                            options={causes.map((item) => ({
                              ...item,
                              label: t(item.label),
                            }))}
                            addOptionEmpty
                            onChangeValue={onChangeCause}
                            classNamePrefix="dark"
                            mandatory
                          />
                        </div>
                      </div>
                      {causeSelected === CAUSE_OTHER_FAULT.code && (
                        <div className="row">
                          <div className="col-12 mt-2">
                            <Field
                              id="machine"
                              name="machine"
                              labelField={t("forms.stopHistory.labels.machine")}
                              // className="form-control"
                              component={InputSelect}
                              placeholder=""
                              optionLabel="label"
                              optionValue="code"
                              options={MACHINES.map((item) => ({
                                ...item,
                                label: t(item.label),
                              }))}
                              addOptionEmpty
                              classNamePrefix="dark"
                              mandatory
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary me-2"
                      >
                        <i className="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-secondary"
                        onClick={() => onClean(props.resetForm)}
                        title={t("cleanFilter")}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OutFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
};

const OutFilterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "OutFilterForm",
})(withTranslation()(OutFilterForm));

export default OutFilterFormF;
