import React, { useState, useContext } from "react";
import * as ExtControlServices from "../../../../services/ExtControlServices";
import PaginateList from "../../../utils/PaginateList";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";
import { ALMOND_TYPE } from "../../../../constants/index";
import { ButtonAction, Icon, ButtonClose } from "./extControlList.styled";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";

const ExtControlListContainer = ({ filters, sortBy, errors }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);
  const dispatch = useDispatch();
  const context = useContext(AbilityContext);

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const columns = [
    {
      header: t("forms.extControl.table.head.date"),
      accessorKey: "date",
      cell: (info) => moment(info.getValue()).format("DD-MM-yyyy"),
      size: 200,
    },
    {
      header: t("forms.extControl.table.head.time"),
      accessorKey: "time",
      // cell: (info) => info.getValue(),
      cell: (info) => moment(info.row.original.date).format("HH:mm"),
    },
    {
      header: t("forms.extControl.table.head.variety"),
      accessorKey: "variety",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.extControl.table.head.batch"),
      accessorKey: "batch",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.extControl.table.head.silo"),
      accessorKey: "silo",
      cell: (info) =>
        info.getValue().replace("MOJADO", "").replace("MOLHADO", ""),
    },
    {
      header: t("forms.extControl.table.head.humFactoryIn"),
      accessorKey: "humFactoryIn",
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    },
    {
      header: t("forms.extControl.table.head.humPartidoraIn"),
      accessorKey: "humPartidoraIn",
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    },
    {
      header: t("forms.extControl.table.head.humSmartOut"),
      accessorKey: "humSmartOut",
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    },
    {
      header: t("forms.extControl.table.head.humSecOut"),
      accessorKey: "humSecOut",
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    },
    {
      header: "",
      accessorKey: "ac",
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const goToEdit = (uuid) => {
    navigate(`/factory/ext-control/update/${uuid}`);
  };

  const renderAction = (data) => {
    return (
      <>
        <Can do={USER_PERMISSIONS.EDIT_QUALITY}>
          <div className="dropdown text-center">
            <ButtonAction
              onClick={() => goToEdit(data.uuid)}
              title={t("forms.order.labels.edit")}
            >
              <Icon className="fa-regular fa-pen-to-square"></Icon>
            </ButtonAction>
          </div>
        </Can>
        {/*{data.hasError && errors && (
          <div className=" text-center">
            <ButtonAction
              onClick={() => showError(data)}
              title={t("forms.extControl.showError")}
            >
              <Icon className="fa-solid fa-magnifying-glass"></Icon>
            </ButtonAction>
            <ButtonAction
              onClick={() => showDialogOnDelete(data.uuid)}
              title={t("forms.extControl.delete")}
            >
              <Icon className="fa-solid fa-trash-can"></Icon>
            </ButtonAction>
          </div>
        )} */}
      </>
    );
  };

  // const showDialogOnDelete = (uuid) => {
  //   const okAction = () => {
  //     ExtControlServices.deleteMove(uuid).then((result) => {
  //       setForceRefresh(true);
  //     });
  //   };

  //   const cancelAction = () => {};

  //   showDialog(
  //     t("forms.extControl.dialog.delete.title"),
  //     t("forms.extControl.dialog.delete.text"),
  //     "question",
  //     true,
  //     t("forms.extControl.dialog.delete.buttonOk"),
  //     false,
  //     okAction,
  //     t("forms.extControl.dialog.delete.buttonNo"),
  //     cancelAction
  //   );
  // };

  // const showError = async (extControl) => {
  //   dispatch(
  //     show("ModalForm", {
  //       body: (
  //         <>
  //           <div className="row mb-3">
  //             <div className="col-12 text-end">
  //               <ButtonClose
  //                 type="button"
  //                 onClick={() => handlerClosePopup("ModalForm")}
  //               >
  //                 <i className="fa-solid fa-xmark"></i>
  //               </ButtonClose>
  //             </div>
  //           </div>
  //           <div className="row justify-content-center mb-3">
  //             <div className="col-11">
  //               <h3 className="title">{t("forms.extControl.titleNotError")}</h3>
  //             </div>
  //           </div>
  //           <div className="row justify-content-center mb-2">
  //             <div className="col-11">Error:</div>
  //           </div>
  //           <div className="row justify-content-center mb-5">
  //             <div className="col-11">
  //               <b>{extControl.errorComment}</b>
  //             </div>
  //           </div>
  //           <div className="row justify-content-center">
  //             <div className="col-11 text-end">
  //               {`${t("forms.extControl.notificationBy")}`}
  //               <b>{` ${extControl.errorUser.email}`}</b>
  //             </div>
  //           </div>
  //           <div className="row justify-content-center mb-3">
  //             <div className="col-11 text-end">
  //               <b>{` ${moment(extControl.dateError).format(
  //                 "DD-MM-yyyy HH:mm"
  //               )}`}</b>
  //             </div>
  //           </div>
  //         </>
  //       ),
  //     })
  //   );
  // };

  // const notificationError = async (extControl) => {
  //   dispatch(
  //     show("ModalForm", {
  //       body: (
  //         <>
  //           <div className="row mb-3">
  //             <div className="col-12 text-end">
  //               <ButtonClose
  //                 type="button"
  //                 onClick={() => handlerClosePopup("ModalForm")}
  //               >
  //                 <i className="fa-solid fa-xmark"></i>
  //               </ButtonClose>
  //             </div>
  //           </div>
  //           <div className="row">
  //             <div className="col-12">
  //               <NotificationErrorForm
  //                 onCancel={() => handlerClosePopup("ModalForm")}
  //                 onSuccess={(values) => sendNotification(values, extControl)}
  //               ></NotificationErrorForm>
  //             </div>
  //           </div>
  //         </>
  //       ),
  //     })
  //   );
  // };

  const listData = async (filter) => {
    const result = await ExtControlServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  if (deviceSize < SIZES.md) {
    // hideColumn.itemCode = false;
    resize = false;
  } else if (deviceSize === SIZES.md) {
    // hideColumn.itemCode = false;
  }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default ExtControlListContainer;
