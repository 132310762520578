import * as Yup from "yup";

export const dataModel = {
  harvestYear: 0,
  workPartidora: false,
  batch: "",
  locationUuid: "",
  totalWeight: 0,
  itemCode: "",
  date: "",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    date: Yup.date().required("forms.validations.required"),
    workPartidora: Yup.boolean().required("forms.validations.required"),
    harvestYear: Yup.number().required("forms.validations.required"),
    batch: Yup.string().required("forms.validations.required"),
    locationUuid: Yup.string().required("forms.validations.required"),
    totalWeight: Yup.number().required("forms.validations.required"),
    itemCode: Yup.string().required("forms.validations.required"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
