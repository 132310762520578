import React from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./extControlForm.data.form";
import InputText from "../../../utils/InputText";
import InputSelect from "../../../utils/InputSelect";
import InputDate from "../../../utils/InputDate";
import InputTextarea from "../../../utils/InputTextarea";

import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { ORIGINS } from "../../../../constants/index";
import { useNavigate } from "react-router-dom";

const NewExtControlForm = (props) => {
  const { handleSubmit, varieties, onCustomRegister, custom, uuid } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.extControl.title"), path: PATHS.EXT_CONTROL_LIST },
    { label: t(`forms.extControl.${uuid ? "edit" : "new"}`) },
  ];

  const cancelAction = () => {
    navigate(PATHS.EXT_CONTROL_LIST);
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">
            {t(`forms.extControl.${uuid ? "edit" : "new"}`)}
          </h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            {!uuid && (
              <div className="row">
                <div className="col-12 col-md-3 col-lg-4 mt-2">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={custom}
                      id="emptyTickets"
                      onChange={(e) => onCustomRegister(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="emptyTickets">
                      {t("forms.extControl.labels.customRegister")}
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12 col-md-3 col-lg-3 mt-2">
                <Field
                  id="date"
                  labelField={t("forms.extControl.labels.date")}
                  className="form-control"
                  name="date"
                  component={InputDate}
                  language="es"
                  dateFormat="dd/MM/yyyy HH:mm"
                  mandatory
                  showTimeSelect
                  readOnly={!custom}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-3 mt-2">
                <Field
                  id="variety"
                  name="variety"
                  labelField={t("forms.extControl.labels.variety")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={varieties.map((item) => ({
                    ...item,
                    label: t(item.label).replace("ALMENDRA EN CASCARA", ""),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                  readOnly={!custom}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-3 mt-2">
                <Field
                  id="batch"
                  className="form-control"
                  labelField={t("forms.extControl.labels.batch")}
                  name="batch"
                  component={InputText}
                  fieldType="text"
                  placeholder=""
                  mandatory
                  readOnly={!custom}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-3 mt-2">
                <Field
                  id="silo"
                  name="silo"
                  labelField={t("forms.extControl.labels.silo")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={ORIGINS.map((item) => ({
                    ...item,
                    label: t(item.label)
                      .replace("MOJADO", "")
                      .replace("MOLHADO", ""),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                  readOnly={!custom}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md  mt-2">
                <Field
                  id="humFactoryIn"
                  className="form-control"
                  labelField={t("forms.extControl.labels.humFactoryIn")}
                  name="humFactoryIn"
                  component={InputText}
                  fieldType="number"
                  placeholder=""
                />
              </div>
              <div className="col-12 col-md mt-2">
                <Field
                  id="humPartidoraIn"
                  className="form-control"
                  labelField={t("forms.extControl.labels.humPartidoraIn")}
                  name="humPartidoraIn"
                  component={InputText}
                  fieldType="number"
                  placeholder=""
                />
              </div>
              <div className="col-12 col-md mt-2">
                <Field
                  id="humSmartOut"
                  className="form-control"
                  labelField={t("forms.extControl.labels.humSmartOut")}
                  name="humSmartOut"
                  component={InputText}
                  fieldType="number"
                  placeholder=""
                />
              </div>
              <div className="col-12 col-md mt-2">
                <Field
                  id="humSecOut"
                  className="form-control"
                  labelField={t("forms.extControl.labels.humSecOut")}
                  name="humSecOut"
                  component={InputText}
                  fieldType="number"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-2">
                <Field
                  id="comments"
                  className="form-control"
                  labelField={t("forms.extControl.labels.comments")}
                  name="comments"
                  component={InputTextarea}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center mt-5 mb-5">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t("forms.order.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.order.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

NewExtControlForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const NewOrderFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "NewExtControlForm",
})(NewExtControlForm);

export default NewOrderFormF;
