const PATHS = {};

PATHS.LOGIN = "/login";
PATHS.HOME = "/factory/home";
PATHS.EXTERNAL_CONFIRM_LOGIN = "/authConfirm/:token";

PATHS.NEW_PROCESS = "/factory/process/new";
PATHS.LIST_ORDERS = "/factory/order/list";
PATHS.ORDER_DETAIL = "/factory/order/detail/:uuid";
PATHS.NEW_ORDER = "/factory/order/new";
PATHS.UPDATE_ORDER = "/factory/order/update/:uuid";
PATHS.OUT_LIST = "/factory/output/list";
PATHS.STOP_LIST = "/factory/stopHistory/list";
PATHS.MOVE_LIST = "/factory/moveFlow/list";
PATHS.MOVE_ERROR_LIST = "/factory/moveFlow/errors";
PATHS.ITEMS_LIST = "/factory/items/list";
PATHS.AUX_ITEMS_LIST = "/factory/auxItems/list";
PATHS.NEW_AUX_ITEMS = "/factory/auxItems/register";
PATHS.JUNCTIONS = "/factory/juctions";
PATHS.EXT_CONTROL_LIST = "/factory/ext-control/list";
PATHS.NEW_EXT_CONTROL = "/factory/ext-control/new";
PATHS.UPDATE_EXT_CONTROL = "/factory/ext-control/update/:uuid";

PATHS.TURN_LIST = "/factory/turn/list";
PATHS.CLOSE_TURN = "/factory/turn/close";

PATHS.UPDATE_MOVEFLOW = "/factory/moveFlow/update/:uuid";

export default PATHS;
