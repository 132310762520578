import { store } from "../store/index";
import { filterAuxItems, register } from "../store/actions/AuxItemActions";

/**
 * List items
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterAuxItems(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Register item
 */
export const registerItem = async (type, data) => {
  const obj = { ...data };
  obj.type = type.value;
  obj.name = type.label;
  const result = await store.dispatch(register(obj));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

// /**
//  * Get varieties
//  */
// export const getVarietiesCascara = async () => {
//   const result = await store.dispatch(getAllVarietiesCascara());
//   if (result && result.payload && result.payload.data) {
//     return result.payload.data;
//   }
//   return null;
// };

// /**
//  * Get varieties
//  */
// export const getVarieties = async () => {
//   const result = await store.dispatch(getAllVarieties());
//   if (result && result.payload && result.payload.data) {
//     return result.payload.data;
//   }
//   return null;
// };

// /**
//  * List calibres
//  */
// export const filterCalibres = async (filter) => {
//   const result = await store.dispatch(filterCalibresVariety(filter));
//   if (result && result.payload && result.payload.data) {
//     return result.payload.data;
//   }
//   return null;
// };

// /**
//  * List order
//  */
// export const listToExport = async (filter) => {
//   const result = await store.dispatch(listItemsToExport(filter));
//   if (result && result.payload && result.payload.data) {
//     return result.payload.data;
//   }
//   return null;
// };
