import React, { useState, useEffect } from "react";
import TurnForm from "./turnForm.view";
import EditTurnForm from "./editTurnForm.view";
import moment from "moment-timezone";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";
import * as TurnServices from "../../../../services/TurnServices";
import { HOUR_TYPE_12 } from "../../../../constants";
import * as UserServices from "../../../../services/UserServices";

const TurnFormContainer = ({ onCancel, onSuccess, uuid }) => {
  const [turns, setTurns] = useState([]);
  const { t } = useTranslation();
  const [dataTurn, setDataTurn] = useState(null);
  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [hourType, setHourType] = useState({
    value: HOUR_TYPE_12.code,
    label: t(HOUR_TYPE_12.label),
  });

  useEffect(() => {
    UserServices.getSupervisors().then((result) => {
      if (result) {
        if (uuid) {
          TurnServices.getTurn(uuid).then((resultTurn) => {
            if (resultTurn) {
              const userFind = result.find(
                (user) => user.uuid === resultTurn.userUuid
              );
              if (userFind) {
                setUserSelected({
                  value: userFind.uuid,
                  label: userFind.email,
                });
              }
              setDataTurn(resultTurn);
            }
          });
        }
        setUsers(result);
      }
    });
  }, []);

  const handleSubmit = async () => {
    try {
      let dates = [];
      dates = turns;
      if (!userSelected || userSelected.value === "") {
        showDialog(
          t("forms.turnHistory.message.turns.title"),
          t("forms.turnHistory.message.turns.errorUser"),
          "error",
          false,
          undefined,
          false
        );
        return false;
      }
      if (uuid) {
        const result = await TurnServices.editTurn(userSelected, uuid);
        if (result) {
          showDialog(
            t("forms.turnHistory.message.turns.title"),
            t("forms.turnHistory.message.turns.success"),
            "success",
            false,
            undefined,
            false
          );
          onSuccess();
        }
      } else {
        if (dates && dates.length > 0) {
          const result = await TurnServices.sendTurns(
            dates,
            userSelected,
            hourType
          );
          if (result) {
            showDialog(
              t("forms.turnHistory.message.turns.title"),
              t("forms.turnHistory.message.turns.success"),
              "success",
              false,
              undefined,
              false
            );
            onSuccess();
          }
        } else {
          showDialog(
            t("forms.turnHistory.message.turns.title"),
            t("forms.turnHistory.message.turns.errorTurnsEmpty"),
            "error",
            false,
            undefined,
            false
          );
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleAddTurn = async (value) => {
    const newTurn = turns ? [...turns] : [];
    const id = moment(new Date(value)).format("DDMMyyyy");
    const index = newTurn.find((h) => h.id === id);
    if (!index) {
      newTurn.push({
        id: id,
        date: new Date(value),
      });
      setTurns(newTurn);
    } else {
      showDialog(
        t("forms.turnHistory.message.turns.title"),
        t("forms.turnHistory.message.turns.errorDuplicated"),
        "error",
        false,
        undefined,
        false
      );
    }
  };

  const handleRemoveTurn = async (value) => {
    const newTurn = [...turns];
    const index = newTurn.findIndex((h) => h.id === value);
    newTurn.splice(index, 1);
    setTurns(newTurn);
  };

  const onCancelAction = () => {
    setTurns(null);
    onCancel();
  };

  const handleOnSelectHourType = async (value) => {
    setHourType(value);
  };

  const handleOnSelectUser = async (value) => {
    setUserSelected(value);
  };

  return (
    <>
      {uuid ? (
        <EditTurnForm
          onSubmit={handleSubmit}
          cancelAction={onCancelAction}
          users={users}
          userSelected={userSelected}
          onSelectUser={handleOnSelectUser}
        />
      ) : (
        <TurnForm
          turns={turns}
          onSubmit={handleSubmit}
          addTurn={handleAddTurn}
          removeTurn={handleRemoveTurn}
          cancelAction={onCancelAction}
          onSelectHourType={handleOnSelectHourType}
          hourType={hourType}
          users={users}
          userSelected={userSelected}
          onSelectUser={handleOnSelectUser}
        />
      )}
    </>
  );
};

TurnFormContainer.propTypes = {};

export default TurnFormContainer;
