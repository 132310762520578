import React from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./changeSilo.data.form";
import { ORIGINS } from "../../../../constants/index";
import InputSelect from "../../../utils/InputSelect";

const ChangeSilo = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, cancelAction } = props;

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <h3 className="title">{t("forms.order.dialog.block.titleSilo")}</h3>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12 mt-2">
                <Field
                  id="origin"
                  name="origin"
                  labelField={t("forms.order.labels.originSilo")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={ORIGINS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center mt-5 mb-5">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t("forms.order.dialog.block.buttonNoSilo")}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.order.dialog.block.buttonOkSilo2")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

ChangeSilo.propTypes = {
  handleSubmit: PropTypes.func,
  cancelAction: PropTypes.func,
};

const ChangeSiloF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "ChangeSilo",
})(ChangeSilo);

export default ChangeSiloF;
