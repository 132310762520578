import styled from "styled-components";

export const ButtonClose = styled.button`
  font-size: 1rem;
  border: 0px;
  background: transparent;
`;

export const ResumeContainer = styled.div`
  border: 1px solid #203b34;
  border-radius: 10px;
  padding: 15px 20px;
`;

export const Resume = styled.div`
  color: #f7be4c;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: underline;
`;

export const MinutesContainer = styled.div`
  width: 90px;
  display: flex;
  justify-content: end;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f7be4c;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const NpContainer = styled.div`
  color: #fff;
  background-color: #f0643a;
`;

export const CaContainer = styled.div`
  color: #fff;
  background-color: #f7be4c;
`;

export const PContainer = styled.div`
  color: #fff;
  background-color: #93599b;
`;
