import React from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./notificationErrorForm.data.form";
import InputTextarea from "../../../utils/InputTextarea";

const NotificationForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, cancelAction } = props;

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <h3 className="title">{t(`forms.moveFlow.titleNotError`)}</h3>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11 marg-btm-1rem">
          <p>{t(`forms.moveFlow.textError`)}</p>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12 mt-2">
                <Field
                  id="comment"
                  className="form-control"
                  labelField={t("forms.stopHistory.labels.comment")}
                  name="comment"
                  component={InputTextarea}
                  fieldType="text"
                  placeholder=""
                  mandatory
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center mt-5 mb-5">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t(`forms.stopHistory.dialog.pause.buttonNo`)}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t(`forms.moveFlow.buttons.send`)}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

NotificationForm.propTypes = {
  handleSubmit: PropTypes.func,
  cancelAction: PropTypes.func,
};

const NotificationFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "NotificationForm",
})(NotificationForm);

export default NotificationFormF;
