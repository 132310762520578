import { store } from "../store/index";
import {
  getLocationByCode,
  getLocations,
} from "../store/actions/LocationAction";

/**
 * List order
 */
export const checkLocationAndPartido = async (code) => {
  const result = await store.dispatch(getLocationByCode(code));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List order
 */
export const getAllLocations = async () => {
  const result = await store.dispatch(getLocations());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
