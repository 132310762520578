import React, { useState, useContext } from "react";
import List from "../moveFlowsList";
import ListHistoryError from "../moveFlowsHistoryErrorList";
import Filter from "../moveFlowsFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { Button } from "./styled";

const queryClient = new QueryClient();
const queryClientHistory = new QueryClient();

const MoveFlowsFilterListContainer = (props) => {
  const { t } = useTranslation();
  const context = useContext(AbilityContext);
  const [history, setHistory] = useState(false);
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.moveFlow.titleError") },
  ];
  const [filters, setFilters] = useState({ hasError: true });

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters({ hasError: true });
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const changeHistory = (data) => {
    setHistory(data);
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.moveFlow.titleError")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      {/* <Can do={USER_PERMISSIONS.FILTER_MOVE}>
        
      </Can> */}
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 mb-4">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Button
                type="button"
                className={`nav-link ${!history && "active"}`}
                aria-current="page"
                onClick={() => changeHistory(false)}
              >
                {t("pending")}
              </Button>
            </li>
            <li className="nav-item">
              <Button
                className={`nav-link ${history && "active"}`}
                onClick={() => changeHistory(true)}
              >
                {t("history")}
              </Button>
            </li>
          </ul>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div>
      {history ? (
        <div className="row justify-content-center">
          <div className="col-12 col-md-11">
            <QueryClientProvider client={queryClientHistory}>
              <ListHistoryError
                className="marg-top-1rem"
                filters={filters}
                errors
              />
            </QueryClientProvider>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-12 col-md-11">
            <QueryClientProvider client={queryClient}>
              <List className="marg-top-1rem" filters={filters} errors />
            </QueryClientProvider>
          </div>
        </div>
      )}
    </>
  );
};

MoveFlowsFilterListContainer.propTypes = {};

export default MoveFlowsFilterListContainer;
