import styled from "styled-components";

export const ButtonAction = styled.button`
  margin-right: 0px;
  color: #f9ae17;
  font-size: 1.1rem;
  border: 0px;
  background: transparent;
  width: max-content;

  &:hover {
    color: #ff9800;
    font-size: 1.1rem;
  }
`;

export const Icon = styled.i`
  margin-right: 0px;
`;

export const ButtonClose = styled.button`
  font-size: 1rem;
  border: 0px;
  background: transparent;
`;
