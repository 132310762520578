import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./orderForm.data.form";
import InputText from "../../../utils/InputText";
import InputSelect from "../../../utils/InputSelect";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { ORIGINS, DESTINIES } from "../../../../constants/index";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const NewOrderForm = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.order.title"), path: PATHS.LIST_ORDERS },
    { label: t("forms.order.new") },
  ];

  const {
    handleSubmit,
    dataProcess,
    onChangeVariety,
    calibres,
    varieties,
    onOutSelected,
    calSelected,
    outSelected,
  } = props;

  const harvestYears = [];
  const currentYear = new Date().getFullYear();

  const optionsCalibres = [];
  for (const calibre of calibres) {
    const index = calSelected.indexOf(calibre.code);
    if (index === -1) {
      optionsCalibres.push({
        value: calibre.code,
        label: t(calibre.label),
      });
    }
  }

  for (let index = 0; index < 5; index++) {
    harvestYears.push({
      code: currentYear - index,
      label: currentYear - index,
    });
  }
  const cancelAction = () => {
    navigate(PATHS.LIST_ORDERS);
  };

  const renderOutPuts = () => {
    return dataProcess.outputs.map((out) => {
      let defaultValue = { value: "", label: "" };
      if (outSelected && outSelected[out.uuid]) {
        const dat = optionsCalibres.find(
          (cal) => cal.value === outSelected[out.uuid]
        );
        defaultValue = dat;
      }
      return (
        <div key={out.uuid} className="col-12 col-lg mt-3">
          <div>
            <label
              id={`calibre_${out.uuid}_labelField`}
              className={"control-label"}
              htmlFor={`calibre_${out.uuid}`}
            >
              {t("forms.order.labels.calibre", { cal: t(out.name) })}
            </label>
          </div>
          <div>
            <Select
              id={`calibre_${out.uuid}`}
              name={`calibre_${out.uuid}`}
              value={defaultValue}
              onChange={(value) => onOutSelected(value, out)}
              options={optionsCalibres}
              classNamePrefix="dark"
              placeholder=""
              // className="form-control"
            />
          </div>
          {/* <select
            id={`calibre_${out.uuid}`}
            name={`calibre_${out.uuid}`}
            labelField={t("forms.order.labels.calibre", { cal: t(out.name) })}
            // className="form-control"
            component={InputSelect}
            placeholder=""
            optionLabel="label"
            optionValue="code"
            onChangeValue={(value) => onOutSelected(value, out)}
            options={calibres.map((item) => ({
              ...item,
              label: t(item.label),
            }))}
            classNamePrefix="dark"
          /> */}
        </div>
      );
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.order.new")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12 col-md-3 col-lg-4 mt-2">
                <Field
                  id="harvestYear"
                  name="harvestYear"
                  labelField={t("forms.order.labels.harvestYear")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={harvestYears.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
              <div className="col-12 col-md-5 col-lg-4 mt-2">
                <Field
                  id="varietyCode"
                  name="varietyCode"
                  labelField={t("forms.order.labels.variety")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={varieties.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  onChangeValue={onChangeVariety}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
              <div className="col-12 col-md-4 col-lg-4 mt-2">
                <Field
                  id="batch"
                  className="form-control"
                  labelField={t("forms.order.labels.batch")}
                  name="batch"
                  component={InputText}
                  fieldType="text"
                  placeholder=""
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mt-2">
                <Field
                  id="origin"
                  name="origin"
                  labelField={t("forms.order.labels.origin")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={ORIGINS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 mt-2">
                <Field
                  id="destination"
                  name="destination"
                  labelField={t("forms.order.labels.destination")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={DESTINIES.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
            </div>

            {dataProcess ? (
              <>
                <div className="row mt-5">
                  <div className="col-12 subtitle">
                    {t("forms.order.labels.outputs")}
                  </div>
                </div>
                <div className="row">{renderOutPuts()}</div>
              </>
            ) : (
              ""
            )}

            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center mt-5 mb-5">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t("forms.order.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.order.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

NewOrderForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const NewOrderFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "NewOrderForm",
})(NewOrderForm);

export default NewOrderFormF;
