import { store } from "../store/index";
import {
  filterExtControl,
  registerData,
  getExtControl,
  updateExt,
  listExtToExport,
} from "../store/actions/ExtControlActions";
/**
 * List order
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterExtControl(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List order
 */
export const listToExport = async (filter) => {
  const result = await store.dispatch(listExtToExport(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const register = async (values, custom) => {
  const obj = {
    ...values,
    custom: custom,
  };
  const result = await store.dispatch(registerData(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const update = async (values, uuid) => {
  const obj = {
    ...values,
    custom: true,
  };
  const result = await store.dispatch(updateExt(obj, [uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * Get ext control
 */
export const getExt = async (uuid) => {
  const result = await store.dispatch(getExtControl([uuid]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
