import { createAction } from "redux-actions";
import { PAGE_SIZE_10 } from "../../constants/index";
import { apiPost, apiGet, apiPut, apiDelete } from "../../api/index";
import {
  urlStopHistoryPauseSecured,
  urlStopHistoryLastSecured,
  urlPlayHistoryPauseSecured,
  urlHistoryPauseSecured,
  urlStopHistoryPauseMachineSecured,
  urlStopHistoryEventSecured,
  urlHolidaysSecured,
  urlExportStopSecured,
} from "../../api/urls";
import moment from "moment-timezone";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.type !== undefined) filter += "&type=" + filters.type;
    if (filters.cause !== undefined) filter += "&cause=" + filters.cause;
    if (filters.machine !== undefined) filter += "&machine=" + filters.machine;
    if (filters.category !== undefined)
      filter += "&category=" + filters.category;
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      filter += "&dateInit=" + dateInit.valueOf();
    }
    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      filter += "&dateEnd=" + dateEnd.valueOf();
    }
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

const generateEventFilterQuery = (filters, isToClose) => {
  let filter = "?";

  if (filters) {
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      if (!isToClose) {
        dateInit.startOf("day");
      }
      filter += "dateInit=" + dateInit.valueOf();
    }
    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      if (!isToClose) {
        dateEnd.endOf("day");
      }
      filter += "&dateEnd=" + dateEnd.valueOf();
    }
  }

  return filter;
};

/**
 * Filter list orders.
 */
export const filterStops = createAction("FILTER_STOPS", (filter, param) =>
  apiGet(urlHistoryPauseSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const listStopToExport = createAction(
  "EXPORT_FILTER_STOPS",
  (filter, param) =>
    apiGet(urlExportStopSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Pause process.
 */
export const pause = createAction("PAUSE_ORDER", (obj) =>
  apiPost(urlStopHistoryPauseSecured, obj, undefined, undefined)()
);

/**
 * Pause machine.
 */
export const pauseMachine = createAction("PAUSE_MACHINE", (obj) =>
  apiPost(urlStopHistoryPauseMachineSecured, obj, undefined, undefined)()
);

/**
 * Get last process.
 */
export const getLast = createAction("GET_LAST_STOP", () =>
  apiGet(urlStopHistoryLastSecured, undefined, undefined)()
);

/**
 * Pause process.
 */
export const play = createAction("PLAY_ORDER", (obj, param) =>
  apiPost(urlPlayHistoryPauseSecured, obj, param, undefined)()
);

/**
 * Filter list orders.
 */
export const filterEvent = createAction("FILTER_EVENT", (filter, isToClose) =>
  apiGet(
    urlStopHistoryEventSecured + generateEventFilterQuery(filter, isToClose),
    undefined,
    true
  )()
);

/**
 * Send Holidays.
 */
export const createHolidays = createAction("CREATE_HOLIDAYS", (obj) =>
  apiPost(urlHolidaysSecured, obj, undefined, true)()
);

/**
 * Pause process.
 */
export const updateStop = createAction("UPDATE_STOP", (obj, param) =>
  apiPut(urlHistoryPauseSecured, obj, param, true)()
);

/**
 * Delete error.
 */
export const deleteStopFlow = createAction("DELETE_STOP", (param) =>
  apiDelete(urlHistoryPauseSecured, param, false)()
);
