import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const HolidaysForm = (props) => {
  const { t } = useTranslation();
  const { onSubmit, cancelAction, users, userSelected, onSelectUser } = props;
  let defaultValue = { value: "", label: "" };

  const finalUsers = [];
  if (users) {
    for (const user of users) {
      finalUsers.push({
        value: user.uuid,
        label: t(user.email),
      });
    }
  }

  const submitForm = () => {
    onSubmit();
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <h3 className="title">{t(`forms.turnHistory.editTurnForm`)}</h3>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11">
          <div className="row">
            <div className="col-12 mt-2 d-flex">
              <label className="control-label mandatory">
                {t("forms.turnHistory.labels.userTurn")}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <Select
                id="user"
                name="user"
                value={userSelected || defaultValue}
                onChange={(value) => {
                  onSelectUser(value);
                }}
                options={finalUsers}
                classNamePrefix="dark"
                placeholder=""
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-sm-11 text-center mt-5 mb-5">
              <button
                type="button"
                onClick={() => cancelAction()}
                className="btn btn-lg btn-secondary me-2"
              >
                {t(`forms.turnHistory.buttons.cancel`)}
              </button>
              <button
                type="button"
                onClick={() => submitForm()}
                className="btn btn-lg btn-primary"
              >
                {t(`forms.turnHistory.buttons.save`)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

HolidaysForm.propTypes = {
  holidays: PropTypes.array,
  onSubmit: PropTypes.func,
  addTurn: PropTypes.func,
  cancelAction: PropTypes.func,
};

export default HolidaysForm;
