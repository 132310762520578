import { useState, useEffect } from "react";
import * as StopServices from "../../../../services/StopServices";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import { ButtonClose } from "./style";
import StopForm from "../stopForm";

export const useStopAndPlay = (refresh) => {
  const dispatch = useDispatch();
  const [lastStop, setLastStop] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    StopServices.getLastStop().then((result) => {
      setLastStop(result);
    });
  }, []);

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const actionStop = async (values, isStopMachine = false, custom = false) => {
    const result = await StopServices.pauseOrder(values, isStopMachine, custom);
    if (result) {
      handlerClosePopup("ModalForm");
      if (!isStopMachine) {
        setLastStop(result);
      }
      if (refresh) {
        refresh();
      }
      showDialog(
        t(
          `forms.stopHistory.message.pause.${
            isStopMachine ? "titleMachine" : "title"
          }`
        ),
        t("forms.stopHistory.message.pause.text"),
        "success",
        false,
        t("forms.stopHistory.message.pause.buttonOk"),
        false
      );
    }
  };

  const actionPlay = async (values, uuid = null) => {
    if (lastStop || uuid) {
      delete values.category;
      const result = await StopServices.playOrder(
        values,
        uuid || lastStop.uuid
      );
      if (result) {
        setLastStop(result);
        if (refresh) {
          refresh();
        }
        showDialog(
          t("forms.stopHistory.message.play.title"),
          t("forms.stopHistory.message.play.text"),
          "success",
          false,
          t("forms.stopHistory.message.play.buttonOk"),
          false
        );
      }
    }

    handlerClosePopup("ModalForm");
  };

  const stopLine = async () => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <StopForm
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={(values) => actionStop(values)}
                ></StopForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const stopMachine = async () => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <StopForm
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={(values) => actionStop(values, true)}
                  machine
                ></StopForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const playLine = async (uuid) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <StopForm
                  play
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={(values) => actionPlay(values, uuid)}
                ></StopForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const customStop = async () => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <StopForm
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={(values) => actionStop(values, false, true)}
                  custom
                ></StopForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const updateStop = async (values, stop) => {
    const result = await StopServices.editStop(values, stop.uuid);
    if (result) {
      handlerClosePopup("ModalForm");
      if (refresh) {
        refresh();
      }
      showDialog(
        t(`forms.stopHistory.message.edit.title`),
        t("forms.stopHistory.message.edit.text"),
        "success",
        false,
        t("forms.stopHistory.message.edit.buttonOk"),
        false
      );
    }
  };

  const deleteStop = (uuid) => {
    const okAction = () => {
      StopServices.deleteStop(uuid).then((result) => {
        if (refresh) {
          refresh();
        }
      });
    };

    const cancelAction = () => {};

    showDialog(
      t("forms.stopHistory.dialog.delete.title"),
      t("forms.stopHistory.dialog.delete.text"),
      "question",
      true,
      t("forms.stopHistory.dialog.delete.buttonOk"),
      false,
      okAction,
      t("forms.stopHistory.dialog.delete.buttonNo"),
      cancelAction
    );
  };

  const editStop = async (stop) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <StopForm
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={(values) => updateStop(values, stop)}
                  toEdit={stop}
                  custom
                ></StopForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  return {
    lastStop,
    stopLine,
    playLine,
    stopMachine,
    customStop,
    editStop,
    deleteStop,
  };
};
