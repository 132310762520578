import React, { useState } from "react";
import * as OrderService from "../../../../services/OrderServices";
import PaginateList from "../../../utils/PaginateList";
import moment from "moment-timezone";
import { STATUS } from "../../../../constants/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";

const OutProcessListContainer = ({ filters, sortBy }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      header: t("forms.out.table.head.date"),
      accessorKey: "date",
      cell: (info) => moment(info.getValue()).format("DD-MM-yyyy HH:mm"),
      size: 200,
    },
    {
      header: t("forms.out.table.head.out"),
      accessorKey: "locationUuid",
      cell: (info) => {
        let infoData;
        if (info.row.original.location) {
          infoData = `${t(info.row.original.location.name)}`;
        } else {
          infoData = info.getValue();
        }

        return infoData;
      },
    },
    {
      header: t("forms.out.table.head.gtin"),
      accessorKey: "itemCode",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.out.table.head.batch"),
      accessorKey: "batch",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.out.table.head.weight"),
      accessorKey: "outWeight",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.out.table.head.batchBB"),
      accessorKey: "bb",
      cell: (info) => {
        if (info.row.original.materialBB) {
          return info.row.original.materialBB.batch;
        }

        return "-";
      },
    },
    {
      header: t("forms.out.table.head.batchTag"),
      accessorKey: "tag",
      cell: (info) => {
        if (info.row.original.materialTag) {
          return info.row.original.materialTag.batch;
        }

        return "-";
      },
    },
  ];

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-date";
    }
    const result = await OrderService.listFilterOutProcess(filter);

    return result;
  };

  const hide = {};
  let resize = null;

  if (deviceSize < SIZES.md) {
    hide.itemCode = false;
    resize = false;
  } else if (deviceSize === SIZES.md) {
    hide.itemCode = false;
  }
  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hide}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default OutProcessListContainer;
