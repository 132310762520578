import * as Yup from "yup";

export const dataModel = {
  dateInit: "",
  dateEnd: "",
  batch: "",
  type: "",
  itemType: "",
  workPartidora: "",
  itemCode: "",
  locationUuid: "",
  itemVariety: "",
  itemCalibre: "",
  userUuid: "",
};

export const validationDataModel = Yup.object().shape({
  dateInit: Yup.date().optional("forms.validations.required"),
  dateEnd: Yup.date().optional("forms.validations.required"),
  batch: Yup.string().optional(""),
  itemCode: Yup.string().optional(""),
  type: Yup.number().optional(""),
  itemType: Yup.number().optional(""),
  workPartidora: Yup.boolean().optional(""),
  locationUuid: Yup.string().optional(""),
  itemVariety: Yup.string().optional(""),
  itemCalibre: Yup.string().optional(""),
  userUuid: Yup.string().optional(""),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
