import { store } from "../store/index";
import {
  pause,
  getLast,
  play,
  filterStops,
  pauseMachine,
  filterEvent,
  createHolidays,
  listStopToExport,
  updateStop,
  deleteStopFlow,
} from "../store/actions/StopActions";
/**
 * List order
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterStops(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List order
 */
export const listToExport = async (filter) => {
  const result = await store.dispatch(listStopToExport(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const getLastStop = async () => {
  const result = await store.dispatch(getLast());
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const pauseOrder = async (values, isStopMachine, custom) => {
  let result;
  if (isStopMachine) {
    result = await store.dispatch(pauseMachine(values));
  } else {
    if (!custom) {
      delete values.initDate;
      delete values.endDate;
    }
    result = await store.dispatch(pause(values));
  }
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const playOrder = async (values, uuid) => {
  const result = await store.dispatch(play(values, [uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * List turns
 */
export const listEvent = async (filter, isToClose = false) => {
  const result = await store.dispatch(filterEvent(filter, isToClose));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Send holidays
 * @returns
 */
export const sendHolidays = async (dates, isPeriod) => {
  const obj = {
    dates: dates,
    isPeriod,
  };
  const result = await store.dispatch(createHolidays(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const editStop = async (values, uuid) => {
  let result;
  result = await store.dispatch(updateStop(values, [uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const deleteStop = async (uuid) => {
  const result = await store.dispatch(deleteStopFlow([uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};
