import React, { useState, useEffect, useContext } from "react";
import List from "../turnHistoryListCalendar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useTurn } from "../hook/useTurn";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import { ButtonClose } from "./styled";
import HolidaysForm from "../holidaysForm";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";

const queryClient = new QueryClient();

const TurnViewCalendarContainer = (props) => {
  const { t } = useTranslation();
  const context = useContext(AbilityContext);

  const [refresh, setRefresh] = useState(false);
  const { lastTurn, registerTurn } = useTurn();
  const dispatch = useDispatch();

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const addHolidays = async () => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <HolidaysForm
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={() => {
                    handlerClosePopup("ModalForm");
                    setRefresh(true);
                  }}
                ></HolidaysForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  return (
    <>
      <Can do={USER_PERMISSIONS.ADD_HOLIDAYS}>
        <div className="row justify-content-center">
          <div className="col-12 col-md-11 text-end">
            {/* <button
            className="btn btn-secondary btn-md"
            type="button"
            onClick={() => registerTurn("checkTurn")}
          >
            {lastTurn && lastTurn.status === 1 ? (
              <span>{t("actions.checkOut")}</span>
            ) : (
              <span>{t("actions.checkIn")}</span>
            )}
          </button> */}
            <button
              className="btn btn-primary btn-md ms-3"
              type="button"
              onClick={() => addHolidays()}
            >
              <span>{t("forms.turnHistory.buttons.holiday")}</span>
            </button>
          </div>
        </div>
      </Can>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List
              className="marg-top-1rem"
              filters={null}
              refresh={refresh}
              showSelectType
              allowAll={context.can(USER_PERMISSIONS.VIEW_ALL_CALENDAR)}
            />
          </QueryClientProvider>
        </div>
      </div>
    </>
  );
};

TurnViewCalendarContainer.propTypes = {};

export default TurnViewCalendarContainer;
