import React, { useEffect, useState, useCallback } from "react";
import OrderCloseForm from "./blockOrder.view";
import SiloChangeForm from "./changeSilo.view";
import * as OrderServices from "../../../../services/OrderServices";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const BlockOrderFormContainer = ({
  orderUuid,
  onCancel,
  onSuccess,
  changeSilo,
}) => {
  const { t } = useTranslation();
  const [showSiloView, setShowSiloView] = useState(false);
  const [dataWeight, setDataWeight] = useState(null);

  const closeOrder = async (values, addCopy) => {
    let title = "";
    let text = "";
    let icon = "";

    const okAction = () => {
      onSuccess();
      onCancel();
    };

    try {
      const result = await OrderServices.blockOrder(values, orderUuid, addCopy);
      if (result) {
        title = t(
          `forms.order.messages.${changeSilo ? "changeSilo" : "block"}.title`
        );
        text = t(
          `forms.order.messages.${changeSilo ? "changeSilo" : "block"}.success`
        );
        icon = "success";
      }
      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      onCancel();
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (changeSilo) {
        setShowSiloView(true);
        setDataWeight(values);
      } else {
        const okCopyAction = () => {
          closeOrder(values, true);
        };

        const noCopyAction = () => {
          closeOrder(values, false);
        };

        showDialog(
          t("forms.order.dialog.addCopy.title"),
          t("forms.order.dialog.addCopy.text"),
          "warning",
          true,
          t("forms.order.dialog.addCopy.buttonOk"),
          false,
          okCopyAction,
          t("forms.order.dialog.addCopy.buttonNo"),
          noCopyAction
        );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleSubmit2 = async (values) => {
    try {
      const val = { ...dataWeight };
      val.origin = values.origin;

      closeOrder(val, true);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      {changeSilo && showSiloView ? (
        <SiloChangeForm onSubmit={handleSubmit2} cancelAction={onCancel} />
      ) : (
        <OrderCloseForm
          onSubmit={handleSubmit}
          cancelAction={onCancel}
          changeSilo={changeSilo}
        />
      )}
    </>
  );
};

BlockOrderFormContainer.propTypes = {};

export default BlockOrderFormContainer;
