import React, { useState, useCallback } from "react";
// import NewReProcess02 from "./newReProcess02.view";
import AuxItemsNew from "./auxItemsNew.view";
// import NewReProcess03 from "./newReProcess03.view";

// import * as OrderServices from "../../../services/OrderServices";
import * as AuxItemService from "../../../../services/AuxItemService";
import { useDispatch } from "react-redux";
// import { show, hide } from "redux-modal";
// import BarCodeScanner from "../../utils/Scanner";
import { useTranslation } from "react-i18next";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import PATHS from "../../../../constants/paths";
import { AUX_BB, AUX_TAG } from "../../../../constants/index";
import { useNavigate } from "react-router-dom";
// import { ButtonClose } from "./auxItemsNew.styled";
// import { BarcodeFormat } from "@zxing/library";

const NewAuxItemsFormContainer = () => {
  const { t } = useTranslation();
  const [itemType, setItemType] = useState(null);
  const [infoTag, setInfoTag] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    let title = "";
    let text = "";
    let icon = "";
    let okAction = null;

    try {
      let result;

      if (itemType && infoTag) {
        result = await AuxItemService.registerItem(itemType, infoTag);
      } else {
        title = t("forms.auxItems.messages.register.title");
        text = t("forms.auxItems.messages.register.errorData");
        icon = "error";
      }

      if (result) {
        title = t("forms.auxItems.messages.register.title");
        text = t("forms.auxItems.messages.register.success");
        icon = "success";
        okAction = () => {
          navigate(PATHS.AUX_ITEMS_LIST);
        };
      }

      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      //console.log('Error', error);
    }
  };

  const manageCodeBB = async (code) => {
    // const tt = "AD2200122%A23MAN4%Q200";
    const datasCode = code.split("%");

    const batch = datasCode[0];
    const itemCode = datasCode[1].substring(1);
    const amount = Number(datasCode[2].substring(1));

    setInfoTag({ batch, itemCode, amount });
  };

  const manageCodeTAG = async (code) => {
    // const tt = "000000000000002356171023242G5819";
    const datasCode = code.split("%");
    const batch = datasCode[0];
    const itemCode = datasCode[1];
    const amount = Number(datasCode[2]);

    setInfoTag({ batch, itemCode, amount });
    // setInfoTag({ batch, itemCode, amount });
    // }
  };

  const onDetectedTag = async (data, type) => {
    if (data && type) {
      switch (type.value) {
        case AUX_BB.code:
          manageCodeBB(data);
          break;
        case AUX_TAG.code:
          manageCodeTAG(data);
          break;
        default:
          break;
      }
    } else if (data && !type) {
      showDialog(
        t("forms.auxItems.messages.type.title"),
        t("forms.auxItems.messages.type.text"),
        "error",
        false
      );
    }
  };

  const handleScanTag = useCallback(async (value, type) => {
    await onDetectedTag(value, type);
  }, []);

  // const handleNextToProductReader = async ({ type, processData }) => {
  //   if (
  //     type === REPROCESS_TYPE &&
  //     processData &&
  //     processData.workAlone === false &&
  //     processData.orderOpen
  //   ) {
  //     const okAction = () => {
  //       setWorkPartidora(true);
  //       nextStep();
  //     };

  //     const cancelAction = () => {
  //       setWorkPartidora(false);
  //       nextStep();
  //     };

  //     showDialog(
  //       t("forms.process.dialog.workAlone.title"),
  //       t("forms.process.dialog.workAlone.text"),
  //       "warning",
  //       true,
  //       t("forms.process.dialog.workAlone.buttonOk"),
  //       false,
  //       okAction,
  //       t("forms.process.dialog.workAlone.buttonNo"),
  //       cancelAction
  //     );
  //   } else if (
  //     type === REPROCESS_TYPE &&
  //     processData &&
  //     processData.workAlone === false &&
  //     !processData.orderOpen
  //   ) {
  //     setWorkPartidora(false);
  //     nextStep();
  //   } else {
  //     if (processData && processData.isPrincipalMachine === true) {
  //       const okAction = () => {
  //         setIsPrincipal(true);
  //         nextStep();
  //       };

  //       const cancelAction = () => {
  //         setIsPrincipal(false);
  //         nextStep();
  //       };

  //       showDialog(
  //         t("forms.process.dialog.isPrincipal.title"),
  //         t("forms.process.dialog.isPrincipal.text"),
  //         "warning",
  //         true,
  //         t("forms.process.dialog.isPrincipal.buttonOk"),
  //         false,
  //         okAction,
  //         t("forms.process.dialog.isPrincipal.buttonNo"),
  //         cancelAction
  //       );
  //     } else {
  //       nextStep();
  //     }
  //   }
  // };

  const handleOnCleanTag = async () => {
    setInfoTag(null);
  };

  const handleOnSelectType = async (value) => {
    setItemType(value);
  };

  const renderProcessStep = () => {
    // if (step === 1) {
    return (
      <AuxItemsNew
        // processData={processData}
        onSubmit={handleSubmit}
        onScan1={handleScanTag}
        onClean={handleOnCleanTag}
        onSelectType={handleOnSelectType}
        typeSelected={itemType}
        infoTag={infoTag}
      />
    );
    // }
    // if (step === 2) {
    //   return (
    //     <NewReProcess02
    //       initialValues={initial}
    //       processData={processData}
    //       onSubmit={handleNextReprocess}
    //       onScan1={handleScanBarCode01}
    //       onScan2={handleScanBarCode02}
    //       goBack={prevStep}
    //     />
    //   );
    // }
    // if (step === 3) {
    //   return (
    //     <NewReProcess03
    //       item={item}
    //       batchData={initial}
    //       processData={processData}
    //       workPartidora={workPartidora}
    //       goBack={prevStep}
    //       onSubmit={handleSubmit}
    //     />
    //   );
    // }
  };

  return <>{renderProcessStep()}</>;
};

NewAuxItemsFormContainer.propTypes = {};

export default NewAuxItemsFormContainer;
