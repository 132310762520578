import { handleActions } from "redux-actions";

import {
  SET_LANGUAGE,
  CLEAN_NAV,
  SET_TURN_VIEW_TYPE,
} from "../../constants/index";

export const nav = handleActions(
  {
    [CLEAN_NAV]: () => ({}),
    [SET_LANGUAGE]: (state, action) => ({
      ...state,
      language: action.payload,
    }),
    [SET_TURN_VIEW_TYPE]: (state, action) => ({
      ...state,
      turnView: action.payload,
    }),
  },
  {}
);
