import React, { useState, useContext } from "react";
import * as StopServices from "../../../../services/StopServices";
import PaginateList from "../../../utils/PaginateList";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";
import { converToTime } from "../../../../utils/formats/index";
import { useStopAndPlay } from "../hook/useStopAndPlay";
import {
  ButtonAction,
  ButtonClose,
  TitleComment,
  Value,
} from "./stopHistoryList.styled";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import {
  LIST_CATEGORIES,
  CHANGES_CAUSES,
  CATEGORY_CLEAN,
  CATEGORY_CHANGES,
  CATEGORY_OTHERS,
  OTHER_CAUSES,
  CLEAN_CAUSES,
  MACHINES,
} from "../../../../constants/index";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";

const StopHistoryListContainer = ({ filters, sortBy }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AbilityContext);
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);
  const refresh = () => {
    setForceRefresh(true);
  };
  const { lastStop, stopLine, playLine, customStop, editStop, deleteStop } =
    useStopAndPlay(refresh);

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const columns = [
    {
      header: t("forms.stopHistory.table.head.stop"),
      accessorKey: "stopDate",
      cell: (info) => moment(info.getValue()).format("DD-MM-yyyy HH:mm"),
      size: 200,
    },
    {
      header: t("forms.stopHistory.table.head.init"),
      accessorKey: "initDate",
      cell: (info) =>
        info.getValue()
          ? moment(info.getValue()).format("DD-MM-yyyy HH:mm")
          : "-",
      size: 200,
    },
    {
      header: t("forms.stopHistory.table.head.type"),
      accessorKey: "category",
      cell: (info) => {
        if (info.row.original.category) {
          const cat = LIST_CATEGORIES.find(
            (c) => c.code === info.row.original.category
          );
          return t(cat.label);
        }
        return "";
      },
    },
    {
      header: t("forms.stopHistory.table.head.userStop"),
      accessorKey: "userStop.email",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.stopHistory.table.head.userInit"),
      accessorKey: "userInit.email",
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    },
    {
      header: t("forms.stopHistory.table.head.time"),
      accessorKey: "time",
      cell: (info) => (info.getValue() ? converToTime(info.getValue()) : "-"),
    },
    {
      header: t("forms.order.table.head.actions"),
      accessorKey: "uuid",
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const renderAction = (data) => {
    return (
      <>
        <div className="text-center">
          {data.type === 2 && data.status === 1 ? (
            <ButtonAction
              onClick={() => playMachine(data)}
              title={t("forms.stopHistory.initMachine")}
            >
              <i className="fa-regular fa-circle-play"></i>
            </ButtonAction>
          ) : (
            ""
          )}
          <ButtonAction
            onClick={() => showComments(data)}
            title={t("forms.order.labels.detail")}
          >
            <i className="fa-solid fa-circle-info"></i>
          </ButtonAction>
          <Can do={USER_PERMISSIONS.CUSTOM_STOP}>
            <ButtonAction
              onClick={() => editStop(data)}
              title={t("forms.order.labels.edit")}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </ButtonAction>
            <ButtonAction
              onClick={() => deleteStop(data.uuid)}
              title="Eliminar"
            >
              <i className="fa-solid fa-trash-can"></i>
            </ButtonAction>
          </Can>
        </div>
      </>
    );
  };

  const playMachine = async (data) => {
    playLine(data.uuid);
    refresh();
  };

  const showComments = (data) => {
    let cause;
    let machine;
    if (data.cause) {
      switch (data.category) {
        case CATEGORY_CLEAN.code:
          cause = CLEAN_CAUSES.find((c) => c.code === data.cause);
          break;
        case CATEGORY_CHANGES.code:
          cause = CHANGES_CAUSES.find((c) => c.code === data.cause);
          break;
        case CATEGORY_OTHERS.code:
          cause = OTHER_CAUSES.find((c) => c.code === data.cause);
          break;

        default:
          break;
      }
    }
    if (data.machine) {
      machine = MACHINES.find((c) => c.code === data.machine);
    }
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            {cause && (
              <div className="row ms-4">
                <div className="col-12 d-flex">
                  <div>{`${t("forms.stopHistory.labels.cause")}: `}</div>
                  <Value>
                    <b>{`${t(cause.label)}`}</b>
                  </Value>
                </div>
              </div>
            )}
            {machine && (
              <div className="row ms-4">
                <div className="col-12 d-flex">
                  <div>{`${t("forms.stopHistory.labels.machine")}: `}</div>
                  <Value>
                    <b>{`${t(machine.label)}`}</b>
                  </Value>
                </div>
              </div>
            )}
            <div className="row ms-4 mt-3">
              <TitleComment className="col-12">
                {t("forms.stopHistory.commentStop")}
              </TitleComment>
            </div>
            <div className="row ms-4">
              <div className="col-12">
                <p>
                  <b>{data.commentsIni || t("noComment")}</b>
                </p>
              </div>
            </div>
            <div className="row mt-5 ms-4">
              <TitleComment className="col-12">
                {t("forms.stopHistory.commentInit")}
              </TitleComment>
            </div>
            <div className="row mb-5 ms-4">
              <div className="col-12">
                <p>
                  <b>{data.commentsEnd || t("noComment")}</b>
                </p>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-stopDate";
    }
    const result = await StopServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  if (deviceSize < SIZES.md) {
    hideColumn.itemCode = false;
    resize = false;
  } else if (deviceSize === SIZES.md) {
    hideColumn.itemCode = false;
  }

  const actionStop = async (type) => {
    if (type === 1) {
      playLine();
    } else {
      stopLine();
    }
  };

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
      <div className="row justify-content-center mt-4 mb-5">
        <div className="col-12 col-md-11 text-center">
          <Can do={USER_PERMISSIONS.EXECUTE_STOP}>
            {lastStop && lastStop.status === 1 ? (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => actionStop(1)}
              >
                <span>{t("actions.play")}</span>
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => actionStop(0)}
              >
                <span>{t("actions.stop")}</span>
              </button>
            )}
          </Can>
          <Can do={USER_PERMISSIONS.CUSTOM_STOP}>
            <button
              className="btn btn-primary btn-md ms-3"
              type="button"
              onClick={() => customStop()}
            >
              <span>{t("actions.newCustomstop")}</span>
            </button>
          </Can>
        </div>
      </div>
    </>
  );
};

export default StopHistoryListContainer;
