import React from "react";
import MoveFlowForm from "../../../components/user/moveFlows/moveFlowForm";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const MoveFlowFormPage = () => {
  let { uuid } = useParams();
  return (
    <Layout
      body={
        <>
          <MoveFlowForm uuid={uuid} />
        </>
      }
    />
  );
};

MoveFlowFormPage.propTypes = {};

export default MoveFlowFormPage;
