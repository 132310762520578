import styled from "styled-components";

export const ButtonClose = styled.button`
  font-size: 1rem;
  border: 0px;
  background: transparent;
`;

export const IconButton = styled.i`
  font-size: 1rem;
  margin-right: 10px;
`;

export const Title = styled.h3`
  /* font-size: 1rem; */
  margin-right: 10px;
`;

export const ContainerData = styled.div`
  border: 1px solid #f9ae17;
  padding: 10px 15px;
  border-radius: 10px;
`;

export const LabelData = styled.span`
  font-weight: bold;
  color: #203b34;
  margin-right: 10px;
`;
