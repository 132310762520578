import { createAction } from "redux-actions";
import { apiGet } from "../../api/index";
import { urlProdProcessSecured } from "../../api/urls";

/**
 * Filter list orders.
 */
export const getProductionProcess = createAction("GET_PROCESS", (param) =>
  apiGet(urlProdProcessSecured, param, true)()
);
