import React, { useState, useContext } from "react";
import * as OrderService from "../../../../services/OrderServices";
import PaginateList from "../../../../components/utils/PaginateList";
import moment from "moment-timezone";
import {
  ORDER_TYPE,
  STATUS_OPEN,
  STATUS_BLOCKED,
} from "../../../../constants/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ButtonAction,
  ButtonClose,
  Icon,
  Option,
  ListOptions,
} from "./orderList.styled";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import BlockOrder from "../blockOrder";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";

const OrderListContainer = ({ filters }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);
  const context = useContext(AbilityContext);

  const columns = [
    {
      header: t("forms.order.table.head.init"),
      accessorKey: "dateInit",
      cell: (info) => moment(info.getValue()).format("DD-MM-yyyy HH:mm"),
      size: 200,
    },
    {
      header: t("forms.order.table.head.block"),
      accessorKey: "dateBlocked",
      cell: (info) =>
        info.getValue()
          ? moment(info.getValue()).format("DD-MM-yyyy HH:mm")
          : "-",
      size: 200,
    },
    {
      header: t("forms.order.table.head.end"),
      accessorKey: "dateEnd",
      cell: (info) =>
        info.getValue()
          ? moment(info.getValue()).format("DD-MM-yyyy HH:mm")
          : "-",
      size: 200,
    },
    {
      header: t("forms.out.table.head.out"),
      accessorKey: "location",
      cell: (info) => {
        let infoData;
        if (info.row.original.location) {
          infoData = `${t(info.row.original.location.name)}`;
        } else {
          infoData = info.getValue();
        }

        return infoData;
      },
    },
    {
      header: t("forms.order.table.head.product"),
      accessorKey: "variety",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.order.table.head.origin"),
      accessorKey: "origin",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.order.table.head.type"),
      accessorKey: "type",
      cell: (info) => {
        const type = ORDER_TYPE.find((st) => st.code === info.getValue());
        return type ? t(type.label) : info.getValue();
      },
    },
    {
      header: t("forms.order.table.head.status"),
      accessorKey: "status",
      cell: (info) => {
        // const status = STATUS.find((st) => st.code === info.getValue());
        // return status ? t(status.label) : info.getValue();
        return info.getValue();
      },
    },
    {
      header: t("forms.order.table.head.totalWeight"),
      accessorKey: "totalWeight",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.order.table.head.batch"),
      accessorKey: "batch",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.order.table.head.actions"),
      accessorKey: "uuid",
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const goToDetail = (uuid) => {
    navigate(`/factory/order/detail/${uuid}`);
  };

  const goToEdit = (uuid) => {
    navigate(`/factory/order/update/${uuid}`);
  };

  const showDialogOnInitClose = (uuid, changeSilo = false) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <BlockOrder
                  changeSilo={changeSilo}
                  orderUuid={uuid}
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={() => setForceRefresh(true)}
                ></BlockOrder>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const showDialogOnClose = (uuid) => {
    const okAction = () => {
      OrderService.closeOrder(uuid).then((result) => {
        setForceRefresh(true);
      });
    };

    const cancelAction = () => {};

    showDialog(
      t("forms.order.dialog.close.title"),
      t("forms.order.dialog.close.text"),
      "question",
      true,
      t("forms.order.dialog.close.buttonOk"),
      false,
      okAction,
      t("forms.order.dialog.close.buttonNo"),
      cancelAction
    );
  };

  // const showDialogOnPausePlay = (uuid, isPause) => {
  //   const okAction = () => {
  //     OrderService.pauseOrder(uuid).then((result) => {
  //       setForceRefresh(true);
  //     });
  //   };

  //   const cancelAction = () => {};

  //   showDialog(
  //     t("forms.order.dialog.pause.title"),
  //     t("forms.order.dialog.pause.text"),
  //     "question",
  //     true,
  //     t("forms.order.dialog.pause.buttonOk"),
  //     false,
  //     okAction,
  //     t("forms.order.dialog.pause.buttonNo"),
  //     cancelAction
  //   );
  // };

  const renderAction = (data) => {
    return (
      <>
        {data.type === 0 ? (
          <div className="dropdown text-center">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("forms.order.table.head.actions")}
            </button>
            <ListOptions className="dropdown-menu dropdown-menu-lg-end">
              <Option>
                <ButtonAction
                  onClick={() => goToDetail(data.uuid)}
                  title={t("forms.order.labels.detail")}
                >
                  <Icon className="fa-solid fa-list"></Icon>
                  {t("forms.order.labels.detail")}
                </ButtonAction>
              </Option>
              {data.status !== STATUS_OPEN.code && (
                <Can do={USER_PERMISSIONS.EDIT_POST}>
                  <Option>
                    <ButtonAction
                      onClick={() => goToEdit(data.uuid)}
                      title={t("forms.order.labels.edit")}
                    >
                      <Icon className="fa-regular fa-pen-to-square"></Icon>
                      {t("forms.order.labels.edit")}
                    </ButtonAction>
                  </Option>
                </Can>
              )}
              {data.status === STATUS_OPEN.code ? (
                <>
                  <Option>
                    <ButtonAction
                      onClick={() => goToEdit(data.uuid)}
                      title={t("forms.order.labels.edit")}
                    >
                      <Icon className="fa-regular fa-pen-to-square"></Icon>
                      {t("forms.order.labels.edit")}
                    </ButtonAction>
                  </Option>
                  <Option>
                    <ButtonAction
                      onClick={() => showDialogOnInitClose(data.uuid)}
                      title={t("forms.order.labels.endPart")}
                    >
                      <Icon className="fa-solid fa-lock"></Icon>
                      {t("forms.order.labels.endPart")}
                    </ButtonAction>
                  </Option>
                  <Option>
                    <ButtonAction
                      onClick={() => showDialogOnInitClose(data.uuid, true)}
                      title={t("forms.order.labels.siloChange")}
                    >
                      <Icon className="fa-solid fa-repeat"></Icon>
                      {t("forms.order.labels.siloChange")}
                    </ButtonAction>
                  </Option>
                </>
              ) : (
                ""
              )}
              {data.status === STATUS_BLOCKED.code ? (
                <>
                  <Option>
                    <ButtonAction
                      onClick={() => showDialogOnClose(data.uuid)}
                      title={t("forms.order.labels.endPart2")}
                    >
                      {/* <i className="fa-solid fa-lock"></i> */}
                      <Icon className="fa-solid fa-rectangle-xmark"></Icon>
                      {t("forms.order.labels.endPart2")}
                    </ButtonAction>
                  </Option>
                </>
              ) : (
                ""
              )}
            </ListOptions>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const listData = async (filter) => {
    const result = await OrderService.listFilterOrders(filter);
    return result;
  };

  const hiden = {};
  let resize = null;

  if (deviceSize < SIZES.md) {
    // hiden.dateInit = false;
    hiden.dateEnd = false;
    hiden.variety = false;
    hiden.origin = false;
    hiden.status = false;
    hiden.totalWeight = false;

    resize = false;
  } else if (deviceSize === SIZES.md) {
    hiden.variety = false;
    hiden.origin = false;
    hiden.location = false;
    // hiden.status = false;
    // hiden.totalWeight = false;
    resize = false;
  }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hiden}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default OrderListContainer;
