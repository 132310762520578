import * as Yup from "yup";

export const dataModel = {
  date: "",
  variety: "",
  batch: "",
  silo: "",
  humFactoryIn: 0,
  humPartidoraIn: 0,
  humSmartOut: 0,
  humSecOut: 0,
  comments: "",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    date: Yup.date().required("forms.validations.required"),
    variety: Yup.string().required("forms.validations.required"),
    batch: Yup.string().required("forms.validations.required"),
    silo: Yup.string().required("forms.validations.required"),
    humFactoryIn: Yup.number().optional("forms.validations.required"),
    humPartidoraIn: Yup.number().optional("forms.validations.required"),
    humSmartOut: Yup.number().optional("forms.validations.required"),
    humSecOut: Yup.number().optional("forms.validations.required"),
    comments: Yup.string().optional("forms.validations.required"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
