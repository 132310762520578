import React from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./moveFlowForm.data.form";
import InputText from "../../../utils/InputText";
import InputSelect from "../../../utils/InputSelect";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { useNavigate } from "react-router-dom";
import InputDate from "../../../utils/InputDate";

const MoveFlowForm = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.moveFlow.title"), path: PATHS.MOVE_LIST },
    { label: t("forms.moveFlow.update") },
  ];

  const { handleSubmit, locations, items } = props;

  const harvestYears = [];
  const partiendo = [
    {
      code: "true",
      label: "SI",
    },
    {
      code: "false",
      label: "NO",
    },
  ];
  const currentYear = new Date().getFullYear();

  for (let index = 0; index < 5; index++) {
    harvestYears.push({
      code: currentYear - index,
      label: currentYear - index,
    });
  }
  const cancelAction = () => {
    navigate(PATHS.MOVE_LIST);
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.moveFlow.update")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12 col-md-3 col-lg-3 mt-2">
                <Field
                  id="date"
                  labelField={t("forms.extControl.labels.date")}
                  className="form-control"
                  name="date"
                  component={InputDate}
                  language="es"
                  dateFormat="dd/MM/yyyy HH:mm"
                  mandatory
                  showTimeSelect
                />
              </div>
              <div className="col-12 col-md-5 col-lg-3 mt-2">
                <Field
                  id="batch"
                  className="form-control"
                  labelField={t("forms.order.labels.batch")}
                  name="batch"
                  component={InputText}
                  fieldType="text"
                  placeholder=""
                  mandatory
                />
              </div>
              <div className="col-12 col-md-4 col-lg-4 mt-2">
                <Field
                  id="locationUuid"
                  name="locationUuid"
                  labelField={t("forms.moveFlow.labels.location")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="uuid"
                  options={locations.map((item) => ({
                    ...item,
                    label: t(item.name),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
              <div className="col-12 col-md-4 col-lg-2 mt-2">
                <Field
                  id="totalWeight"
                  className="form-control"
                  labelField={t("forms.moveFlow.labels.totalWeight")}
                  name="totalWeight"
                  component={InputText}
                  fieldType="text"
                  placeholder=""
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-8 mt-2">
                <Field
                  id="itemCode"
                  name="itemCode"
                  labelField={t("forms.moveFlow.labels.itemCode")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={items.map((item) => ({
                    ...item,
                    label: `${item.code} - ${item.variety} - ${item.name}`,
                  }))}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
              <div className="col-12 col-md-4 col-lg-2 mt-2">
                <Field
                  id="workPartidora"
                  name="workPartidora"
                  labelField={t("forms.moveFlow.labels.workPartidora")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={partiendo.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2 mt-2">
                <Field
                  id="harvestYear"
                  name="harvestYear"
                  labelField={t("forms.order.labels.harvestYear")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={harvestYears.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center mt-5 mb-5">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t("forms.order.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.order.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

MoveFlowForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const MoveFlowFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "MoveFlowForm",
})(MoveFlowForm);

export default MoveFlowFormF;
