import React, { useEffect, useState } from "react";
import {
  BrowserMultiFormatReader,
  BarcodeFormat,
  DecodeHintType,
} from "@zxing/library";
import Webcam from "react-webcam";

const BarcodeScannerComponent = ({
  onUpdate,
  onError,
  width = "100%",
  height = "100%",
  facingMode = "environment",
  // torch,
  delay = 100,
  imageReSize,
  videoConstraints,
  stopStream,
  readFormats,
}) => {
  const webcamRef = React.useRef(null);
  //   const [imageData, setImageData] = useState(null);
  // navigator.mediaDevices.getUserMedia({ video: true });

  const capture = React.useCallback(() => {
    const hints = new Map();
    const formats = readFormats
      ? readFormats
      : [
          BarcodeFormat.AZTEC,
          BarcodeFormat.CODABAR,
          BarcodeFormat.CODE_128,
          BarcodeFormat.CODE_39,
          BarcodeFormat.CODE_93,
          BarcodeFormat.DATA_MATRIX,
          BarcodeFormat.EAN_13,
          BarcodeFormat.EAN_8,
          BarcodeFormat.ITF,
          BarcodeFormat.MAXICODE,
          BarcodeFormat.PDF_417,
          BarcodeFormat.QR_CODE,
          BarcodeFormat.RSS_14,
          BarcodeFormat.RSS_EXPANDED,
          BarcodeFormat.UPC_A,
          BarcodeFormat.UPC_E,
          BarcodeFormat.UPC_EAN_EXTENSION,
        ];

    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    const codeReader = new BrowserMultiFormatReader(hints);
    const imageSrc = webcamRef?.current?.getScreenshot(
      imageReSize
        ? imageReSize
        : {
            width: 1500,
            height: 1000,
          }
    );
    if (imageSrc) {
      // setImage(imageSrc);
      codeReader
        .decodeFromImage(undefined, imageSrc)
        .then((result) => {
          onUpdate(null, result);
        })
        .catch((err) => {
          onUpdate(err);
        });
    }
  }, [onUpdate]);

  // React.useEffect(() => {
  //   // Turn on the flashlight if prop is defined and device has the capability
  //   if (
  //     typeof torch === 'boolean' &&
  //     navigator?.mediaDevices?.getSupportedConstraints().
  //   ) {
  //     const stream = webcamRef?.current?.video.srcObject
  //     const track = stream?.getVideoTracks()[0] // get the active track of the stream
  //     if (
  //       track &&
  //       track.getCapabilities().torch &&
  //       !track.getConstraints().torch
  //     ) {
  //       track
  //         .applyConstraints({
  //           advanced: [{ torch }]
  //         })
  //         .catch((err: unknown) => onUpdate(err))
  //     }
  //   }
  // }, [torch, onUpdate])

  useEffect(() => {
    if (stopStream) {
      let stream = webcamRef?.current?.video.srcObject;
      if (stream) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        stream.getTracks().forEach((track) => {
          stream.removeTrack(track);
          track.stop();
        });
        stream = null;
      }
    }
  }, [stopStream]);

  useEffect(() => {
    const interval = setInterval(capture, delay);
    return () => {
      clearInterval(interval);
    };
  }, []);

  //   const videoConstraints2 = {
  //     width: 2000,
  //     height: 2000,
  //     facingMode: { exact: "environment" },
  //   };

  return (
    <>
      <Webcam
        width={width}
        height={height}
        ref={webcamRef}
        screenshotQuality={1}
        screenshotFormat="image/jpeg"
        videoConstraints={
          videoConstraints || {
            facingMode,
          }
        }
        audio={false}
        onUserMediaError={onError}
        //   style={{ width: "100%", objectFit: "cover" }}
      />
      {/* <button onClick={capture}>Capture photo</button> */}
    </>
  );
};

export default BarcodeScannerComponent;
