import React, { useEffect, useState } from "react";
import * as JunctionService from "../../../../services/JunctionService";
import Resume from "./junctionResume.view";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import ReadBB from "./readBB.view";
import { useTranslation } from "react-i18next";
import showDialog from "../../../utils/ShowDialog/ShowDialog";

const JunctionResumeContainer = (props) => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const [initial, setInitial] = useState({});
  const { t } = useTranslation();
  const [type, setType] = useState(null);
  const [showPoint, setShowPoint] = useState(false);
  const [showResume, setShowResume] = useState(true);
  const [action, setAction] = useState("");

  const handlerClosePopup = (resetForm) => {
    if (resetForm) {
      resetForm();
    }
    setInitial({});
    setShowPoint(false);
    setShowResume(true);
    setType(null);
  };

  useEffect(() => {
    JunctionService.getJunction().then((result) => {
      if (result) {
        setData(result);
      }
    });
  }, []);

  const createJunction = async (values) => {
    try {
      const result = await JunctionService.createJunction(values);
      if (result) {
        setData(result);
        handlerClosePopup();

        showDialog(
          t("forms.junction.message.create.title"),
          t("forms.junction.message.create.text"),
          "success",
          false,
          "Ok"
        );
      }
    } catch (error) {}
  };

  function obtenerDiaJuliano(fecha) {
    var inicioAño = new Date(fecha.getFullYear(), 0, 0);
    var milisegundosEnUnDia = 86400000; // 24 horas * 60 minutos * 60 segundos * 1000 milisegundos
    var diferencia = fecha - inicioAño;
    var diaJuliano = Math.floor(diferencia / milisegundosEnUnDia);

    return diaJuliano;
  }

  const handleScanBarCode01 = async (codeInit) => {
    // const tt = "02000000710001011123083017241129371000";
    const code = `${codeInit}`;

    // const initCode01 = tt.substring(0, 2);
    const idItem = code.substring(2, 16); //itemCode
    // const initCode02 = tt.substring(16, 18);
    const productionDate = code.substring(18, 24);
    // const initCode03 = tt.substring(24, 26);
    const validateDate = code.substring(26, 32);
    // const initCode04 = tt.substring(32, 34);
    const weight = code.substring(34); //outWeight

    const yearP = productionDate.substring(0, 2);
    const monthP = productionDate.substring(2, 4);
    const dayP = productionDate.substring(4);

    const yearV = validateDate.substring(0, 2);
    const monthV = validateDate.substring(2, 4);
    const dayV = validateDate.substring(4);

    const newInitial = { ...initial };
    newInitial.itemCode = `${Number(idItem)}`;
    newInitial.productionDate = new Date(`20${yearP}-${monthP}-${dayP}`);
    // newInitial.harvestYear = Number(`20${yearP}`);
    newInitial.validateDate = new Date(`20${yearV}-${monthV}-${dayV}`);
    newInitial.outWeight = Number(weight);

    setInitial(newInitial);
  };

  const handleScanBarCode02 = async (codeInit) => {
    // const tt = "000000000000002356171023242G5819";
    const code = `${codeInit}`;
    // if (code.length === 34 || code.length === 35) {
    // const initCode01 = code.substring(0, 2);
    const sscc = code.substring(2, 20); //sscc
    // const initCode02 = code.substring(18, 20);
    const batchCode = code.substring(22); //outBatch

    const newinitial = { ...initial };
    newinitial.outBatch = batchCode;
    newinitial.sscc = sscc;

    newinitial.harvestYear = Number(`20${batchCode.substring(0, 2)}`);

    let juliano = "";
    if (batchCode.length >= 12) {
      juliano = batchCode.substring(4, 7);
    } else {
      juliano = batchCode.substring(2, 5);
    }

    const currentJuliano = obtenerDiaJuliano(new Date()) + "";
    if (juliano && currentJuliano !== juliano) {
      const okAction = () => {
        setInitial(newinitial);
      };

      const cancelAction = () => {
        // setWorkPartidora(false);
        // nextStep();
      };

      showDialog(
        t("forms.process.dialog.juliano.title"),
        t("forms.process.dialog.juliano.text"),
        "warning",
        true,
        t("forms.process.dialog.juliano.buttonOk"),
        false,
        okAction,
        t("forms.process.dialog.juliano.buttonNo"),
        cancelAction
      );
    } else {
      setInitial(newinitial);
    }
  };

  const handlerOpenJunction = () => {
    setType(null);
    setShowPoint(true);
    setShowResume(false);
    setAction("open");
  };

  const handlerRegister = (actionType) => {
    setType(actionType);
    setShowPoint(true);
    setShowResume(false);
    setAction(`register${actionType}`);
  };

  const registerBB = async (values) => {
    try {
      if (type) {
        const result = await JunctionService.registerBB(values, type);
        if (result) {
          setData(result);
          handlerClosePopup();
          showDialog(
            t("forms.junction.message.registerBB.title"),
            t("forms.junction.message.registerBB.text"),
            "success",
            false,
            "Ok"
          );
        }
      }
    } catch (error) {}
  };

  const executeFinish = async () => {
    try {
      const result = await JunctionService.finishJunction();
      if (result && result === "OK") {
        setData(null);
        handlerClosePopup();
        showDialog(
          t("forms.junction.message.finish.title"),
          t("forms.junction.message.finish.text"),
          "success",
          false,
          "Ok"
        );
      }
    } catch (error) {}
  };

  const handlerFinish = async (totalIn, totalOut) => {
    try {
      if (totalOut <= 0 || totalIn <= 0) {
        showDialog(
          t("forms.junction.message.finish.title"),
          t("forms.junction.message.finish.error01"),
          "error",
          false,
          "Ok"
        );
        return false;
      }

      if (totalIn !== totalOut) {
        const okAction = () => {
          executeFinish();
        };

        const cancelAction = () => {};

        showDialog(
          t("forms.junction.dialog.finish.title"),
          t("forms.junction.dialog.finish.text"),
          "warning",
          true,
          t("forms.junction.dialog.finish.buttonOk"),
          false,
          okAction,
          t("forms.junction.dialog.finish.buttonNo"),
          cancelAction
        );
      } else {
        executeFinish();
      }
    } catch (error) {}
  };

  const executeRemove = async (bb) => {
    try {
      const result = await JunctionService.removeItem(bb.uuid);
      if (result) {
        setData(result);
        handlerClosePopup();
        showDialog(
          t("forms.junction.message.remove.title"),
          t("forms.junction.message.remove.text"),
          "success",
          false,
          "Ok"
        );
      }
    } catch (error) {}
  };

  const handlerRemove = async (bb) => {
    try {
      const okAction = () => {
        executeRemove(bb);
      };

      const cancelAction = () => {};

      showDialog(
        t("forms.junction.dialog.remove.title"),
        t("forms.junction.dialog.remove.text"),
        "warning",
        true,
        t("forms.junction.dialog.remove.buttonOk"),
        false,
        okAction,
        t("forms.junction.dialog.remove.buttonNo"),
        cancelAction
      );
    } catch (error) {}
  };

  return (
    <>
      {showPoint && (
        <ReadBB
          action={action}
          initialValues={initial}
          onSubmit={type ? registerBB : createJunction}
          onScan1={handleScanBarCode01}
          onScan2={handleScanBarCode02}
          goBack={(resetForm) => handlerClosePopup(resetForm)}
        />
      )}
      {showResume && (
        <Resume
          data={data}
          openJunction={handlerOpenJunction}
          registerBB={handlerRegister}
          finish={handlerFinish}
          removeItem={handlerRemove}
        />
      )}
    </>
  );
};

JunctionResumeContainer.propTypes = {};

export default JunctionResumeContainer;
