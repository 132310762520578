import React, { useState, useContext } from "react";
import * as MoveFlowServices from "../../../../services/MoveFlowServices";
import PaginateList from "../../../utils/PaginateList";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";
import { ALMOND_TYPE } from "../../../../constants/index";
import { ButtonAction, Icon, ButtonClose } from "./moveFlowsList.styled";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import NotificationErrorForm from "../notificationErrorForm";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";

const MoveFlowsListContainer = ({ filters, sortBy, errors, allowAll }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);
  const dispatch = useDispatch();
  const context = useContext(AbilityContext);

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const columns = [
    {
      header: t("forms.moveFlow.table.head.date"),
      accessorKey: "date",
      cell: (info) => moment(info.getValue()).format("DD-MM-yyyy HH:mm"),
      size: 200,
    },
    {
      header: t("forms.moveFlow.table.head.harvestYear"),
      accessorKey: "harvestYear",
      cell: (info) => info.getValue() || " ",
    },
    {
      header: t("forms.moveFlow.table.head.batch"),
      accessorKey: "batch",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.moveFlow.table.head.point"),
      accessorKey: "locationUuid",
      cell: (info) => {
        if (info.row.original.location) {
          return `${t(info.row.original.location.name)}`;
        }
        return info.getValue();
      },
    },
    {
      header: t("forms.moveFlow.table.head.weight"),
      accessorKey: "totalWeight",
      cell: (info) => {
        if (info.row.original.type === 1) {
          return (
            <span className="produccion">{`${info.row.original.totalWeight}`}</span>
          );
        } else {
          return (
            <span className="consumo">{`-${info.row.original.totalWeight}`}</span>
          );
        }
      },
    },
    {
      header: t("forms.moveFlow.table.head.item"),
      accessorKey: "itemCode",
      cell: (info) => {
        if (info.row.original.item) {
          return info.row.original.item.code;
        }
        return "-";
      },
    },
    {
      header: t("forms.moveFlow.table.head.type"),
      accessorKey: "itemType",
      cell: (info) => {
        if (info.row.original.item) {
          const type = ALMOND_TYPE.find(
            (st) => st.code === info.row.original.item.type
          );
          return type ? t(type.label) : info.getValue();
        }
        return "-";
      },
    },
    {
      header: t("forms.moveFlow.table.head.variety"),
      accessorKey: "variety",
      cell: (info) => {
        if (info.row.original.item) {
          return info.row.original.item.variety.replace("PODRIDO", "");
        }
        return info.row.original.itemCode;
      },
    },
    {
      header: t("forms.moveFlow.table.head.calibre"),
      accessorKey: "calibre",
      cell: (info) => {
        if (info.row.original.item) {
          return info.row.original.item.calibre === "CAS" ||
            info.row.original.item.type === 4
            ? "-"
            : info.row.original.item.calibre;
        }
        return "-";
      },
    },
    {
      header: t("forms.moveFlow.table.head.partidora"),
      accessorKey: "workPartidora",
      cell: (info) => (info.getValue() ? "SI" : "NO"),
    },
    {
      header: t("forms.moveFlow.table.head.employee"),
      accessorKey: "userUuid",
      cell: (info) => {
        if (info.row.original.user) {
          return info.row.original.user.email;
        }
        return info.getValue();
      },
    },
    {
      header: "",
      accessorKey: "ac",
      size: 250,
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const goToEdit = (uuid) => {
    navigate(`/factory/moveFlow/update/${uuid}`);
  };

  const renderAction = (data) => {
    return (
      <div className="text-center">
        <Can do={USER_PERMISSIONS.EDIT_POST}>
          <ButtonAction
            onClick={() => goToEdit(data.uuid)}
            title={t("forms.order.labels.edit")}
          >
            <Icon className="fa-regular fa-pen-to-square"></Icon>
          </ButtonAction>
        </Can>
        {!data.hasError && (
          <ButtonAction
            onClick={() => notificationError(data)}
            title={t("forms.order.labels.error")}
          >
            <Icon className="fa-solid fa-triangle-exclamation"></Icon>
          </ButtonAction>
        )}
        {data.hasError && errors && (
          <div className=" text-center">
            <ButtonAction
              onClick={() => showError(data)}
              title={t("forms.moveFlow.showError")}
            >
              <Icon className="fa-solid fa-magnifying-glass"></Icon>
            </ButtonAction>
            <ButtonAction
              onClick={() => showDialogOnDelete(data.uuid)}
              title={t("forms.moveFlow.delete")}
            >
              <Icon className="fa-solid fa-trash-can"></Icon>
            </ButtonAction>
          </div>
        )}
      </div>
    );
  };

  const showDialogOnDelete = (uuid) => {
    const okAction = () => {
      MoveFlowServices.deleteMove(uuid).then((result) => {
        setForceRefresh(true);
      });
    };

    const cancelAction = () => {};

    showDialog(
      t("forms.moveFlow.dialog.delete.title"),
      t("forms.moveFlow.dialog.delete.text"),
      "question",
      true,
      t("forms.moveFlow.dialog.delete.buttonOk"),
      false,
      okAction,
      t("forms.moveFlow.dialog.delete.buttonNo"),
      cancelAction
    );
  };

  const showError = async (moveFlow) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              <div className="col-11">
                <h3 className="title">{t("forms.moveFlow.titleNotError")}</h3>
              </div>
            </div>
            <div className="row justify-content-center mb-2">
              <div className="col-11">Error:</div>
            </div>
            <div className="row justify-content-center mb-5">
              <div className="col-11">
                <b>{moveFlow.errorComment}</b>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 text-end">
                {`${t("forms.moveFlow.notificationBy")}`}
                <b>{` ${moveFlow.errorUser.email}`}</b>
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              <div className="col-11 text-end">
                <b>{` ${moment(moveFlow.dateError).format(
                  "DD-MM-yyyy HH:mm"
                )}`}</b>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const notificationError = async (moveFlow) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NotificationErrorForm
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={(values) => sendNotification(values, moveFlow)}
                ></NotificationErrorForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const sendNotification = async (values, moveFlow) => {
    if (values) {
      try {
        const result = await MoveFlowServices.sendError(values, moveFlow.uuid);
        if (result) {
          handlerClosePopup("ModalForm");
          setForceRefresh(true);
          showDialog(
            t("forms.moveFlow.messages.error.title"),
            t("forms.moveFlow.messages.error.success"),
            "success",
            false,
            undefined,
            false
          );
        }
      } catch (error) {}
    }
  };

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-date";
    }
    const result = await MoveFlowServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  if (deviceSize < SIZES.md) {
    hideColumn.itemCode = false;
    resize = false;
  } else if (deviceSize === SIZES.md) {
    hideColumn.itemCode = false;
  }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default MoveFlowsListContainer;
