import React from "react";
import FilterList from "../../../components/user/moveFlows/errorMoveFlowsFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const ErrorMoveListPage = (props) => {
  return <Layout body={<FilterList />} />;
};

ErrorMoveListPage.propTypes = {};

export default ErrorMoveListPage;
