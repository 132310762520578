import { createAction } from "redux-actions";
import { PAGE_SIZE_10 } from "../../constants/index";
import { apiPost, apiGet, apiDelete, apiPut } from "../../api/index";
import {
  urlOrderRegisterSecured,
  urlOrderSecured,
  urlProcessRegisterSecured,
  urlProcessSecured,
  urlOrderRegisterReproSecured,
  urlCheckItemOrderSecured,
  urlOrderCloseReproSecured,
  urlOrderBlockReproSecured,
  urlOrderOpenSecured,
  urlProcessRegisterChangeSecured,
  urlExportOutsSecured,
} from "../../api/urls";
import moment from "moment-timezone";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.type !== undefined) filter += "&type=" + filters.type;
    if (filters.itemUuid !== undefined)
      filter += "&itemUuid=" + filters.itemUuid;
    if (filters.origin !== undefined) filter += "&origin=" + filters.origin;
    if (filters.batch !== undefined) filter += "&batch=" + filters.batch;
    if (filters.destination !== undefined)
      filter += "&destination=" + filters.destination;
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      filter += "&dateInit=" + dateInit.valueOf();
    }
    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      filter += "&dateEnd=" + dateEnd.valueOf();
    }
  }
  if (sort) filter += "&sort=" + sort;

  return filter;
};

const generateOutFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.orderUuid) filter += "&orderUuid=" + filters.orderUuid;
    if (filters.itemCode) filter += "&itemCode=" + filters.itemCode;
    if (filters.batch) filter += "&batch=" + filters.batch;
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      filter += "&dateInit=" + dateInit.valueOf();
    }
    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      filter += "&dateEnd=" + dateEnd.valueOf();
    }
  }

  if (sort) filter += "&sort=" + sort;

  return filter;
};

/**
 * Filter list orders.
 */
export const filterOrder = createAction("FILTER_ORDER", (filter, param) =>
  apiGet(urlOrderSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const getOrder = createAction("GET_ORDER", (param) =>
  apiGet(urlOrderSecured, param, true)()
);

/**
 * Filter list orders.
 */
export const getOrderOpen = createAction("GET_ORDER_OPEN", () =>
  apiGet(urlOrderOpenSecured, undefined, true)()
);

/**
 * Register process.
 */
export const register = createAction("ENTRY_REGISTER", (obj) =>
  apiPost(urlOrderRegisterSecured, obj, undefined)()
);

/**
 * Register process.
 */
export const registerProc = createAction("PROCESS_REGISTER", (obj) =>
  apiPost(urlProcessRegisterSecured, obj, undefined)()
);

/**
 * Filter list orders.
 */
export const filterOutProcess = createAction(
  "FILTER_PROCESS",
  (filter, param) =>
    apiGet(urlProcessSecured + generateOutFilterQuery(filter), param, true)()
);

/**
 * Register reprocess.
 */
export const registerRepro = createAction("ENTRY_REGISTER_REPRO", (obj) =>
  apiPost(urlOrderRegisterReproSecured, obj, undefined)()
);

/**
 * Check Item with order.
 */
export const checkItemOut = createAction("CHECK_ITEM_OUT", (param) =>
  apiGet(urlCheckItemOrderSecured, param, undefined)()
);

/**
 * Block order.
 */
export const blockOrderProd = createAction("BLOCK_PROD_ORDER", (obj) =>
  apiPost(urlOrderBlockReproSecured, obj, undefined)()
);

/**
 * Block order.
 */
export const closeOrderProd = createAction("CLOSE_PROD_ORDER", (param) =>
  apiDelete(urlOrderCloseReproSecured, param, undefined)()
);

/**
 * Update process.
 */
export const update = createAction("UPDATE_REGISTER", (obj, param) =>
  apiPut(urlOrderSecured, obj, param, undefined)()
);

/**
 * Register change.
 */
export const registerProcChange = createAction(
  "PROCESS_REGISTER_CHANGE",
  (obj) => apiPost(urlProcessRegisterChangeSecured, obj, undefined)()
);

/**
 * Filter list orders.
 */
export const listMovesToExport = createAction(
  "EXPORT_FILTER_OUTS",
  (filter, param) =>
    apiGet(urlExportOutsSecured + generateFilterQuery(filter), param, true)()
);
