import { createAction } from "redux-actions";
import { SET_LANGUAGE, SET_TURN_VIEW_TYPE } from "../../constants/index";
import { apiGet } from "../../api/index";
import { urlGetLoginItemTemp } from "../../api/urls";
/**
 * Set TimeZone.
 */
export const setLanguage = createAction(SET_LANGUAGE, (data) => data);

/**
 * Set TimeZone.
 */
export const setViewType = createAction(SET_TURN_VIEW_TYPE, (data) => data);

/**
 * Get item.
 */
export const getLoginItem = createAction("GET_ITEM", (token) =>
  apiGet(urlGetLoginItemTemp, [token], false)()
);
