import React, { useEffect, useState } from "react";
import StopForm from "./stopForm.view";
import StopFormPlay from "./stopFormPlay.view";

const StopFormContainer = ({
  onCancel,
  onSuccess,
  play,
  machine,
  custom,
  toEdit,
}) => {
  const [initValues, setInitValues] = useState({});

  const handleSubmit = async (values) => {
    try {
      onSuccess(values);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const setInitialValues = (stop) => {
    const data = {
      comment: stop.commentsEnd,
      category: stop.category,
      cause: stop.cause,
      machine: stop.machine || "",
      initDate: stop.stopDate,
      endDate: stop.initDate,
    };
    setInitValues(data);
  };

  useEffect(() => {
    if (toEdit) {
      setInitialValues(toEdit);
    }
  }, [toEdit]);

  return (
    <>
      {play ? (
        <StopFormPlay
          onSubmit={handleSubmit}
          cancelAction={onCancel}
          play={play}
          machine={machine}
        />
      ) : (
        <StopForm
          toEdit={toEdit ? true : false}
          onSubmit={handleSubmit}
          cancelAction={onCancel}
          play={play}
          machine={machine}
          custom={custom || null}
          initialValues={initValues}
        />
      )}
    </>
  );
};

StopFormContainer.propTypes = {};

export default StopFormContainer;
