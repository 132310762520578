import { createAction } from "redux-actions";
import { apiGet } from "../../api/index";
import {
  urlItemsSecured,
  urlItemsVarietiesSecured,
  urlItemsCalibresSecured,
  urlItemsVarietiesCascaraSecured,
  urlItemsAdmSecured,
  urlExportItemsSecured,
} from "../../api/urls";
import { PAGE_SIZE_10 } from "../../constants/index";

const generateFilterAllQuery = (filter) => {
  let filterData = "?";

  if (filter) {
    if (filter.varietyCode) filterData += "varietyCode=" + filter.varietyCode;
  }

  return filterData;
};

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.code !== undefined) filter += "&code=" + filters.code;
    if (filters.type !== undefined) filter += "&type=" + filters.type;
    if (filters.varietyCode !== undefined)
      filter += "&varietyCode=" + filters.varietyCode;
    if (filters.calibreCode !== undefined)
      filter += "&calibreCode=" + filters.calibreCode;
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list orders.
 */
export const filterItemsAdm = createAction("FILTER_ITEMS", (filter, param) =>
  apiGet(urlItemsAdmSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const filterAllItems = createAction("FILTER_ALL_ITEM", (filter) =>
  apiGet(urlItemsSecured + generateFilterAllQuery(filter), undefined, true)()
);

/**
 * Get all varieties.
 */
export const getAllVarietiesCascara = createAction(
  "GET_ALL_VARIETIES_CASCARA",
  () => apiGet(urlItemsVarietiesCascaraSecured, undefined, true)()
);

/**
 * Get all varieties.
 */
export const getAllVarieties = createAction("GET_ALL_VARIETIES", () =>
  apiGet(urlItemsVarietiesSecured, undefined, true)()
);

/**
 * Filter calibres.
 */
export const filterCalibresVariety = createAction(
  "FILTER_ALL_CALIBRES",
  (filter) =>
    apiGet(
      urlItemsCalibresSecured + generateFilterAllQuery(filter),
      undefined,
      true
    )()
);

/**
 * Filter list orders.
 */
export const listItemsToExport = createAction(
  "EXPORT_FILTER_ITEMS",
  (filter, param) =>
    apiGet(urlExportItemsSecured + generateFilterQuery(filter), param, true)()
);
