import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { CSSTransition } from 'react-transition-group';
// import * as AuthService from "../../../../services/AuthService";
import PATHS from "../../../../constants/paths";
// import { ROLE_SUPPORT } from "../../../../constants/index";
// import { Button } from '@smartups-sl/react-basic-components';
// import Menu, { Item as MenuItem } from 'rc-menu';
// import "rc-menu/assets/index.css";
import {
  IconStyled,
  ButtonOption,
  Logo,
  OffCanvasHeader,
  NavLink,
  ButtonOption2,
  NavLinkChild,
  IconArrow,
} from "./styled";
import { AuthContainer } from "../../../security/auth/auth.container";

// import { showDialog } from "@smartups-sl/react-utils-components";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";
import { checkBootstrapSizeBreak } from "../../../../utils/device/checkDevice";

// import { toast } from "react-toastify";

const Sidebar = (props) => {
  const auth = AuthContainer.useContainer();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offCanvas } = props;
  const context = useContext(AbilityContext);
  const deviceSize = checkBootstrapSizeBreak();

  const logout = async () => {
    auth.logout();
    openCenteredPopup(
      `https://login.microsoftonline.com/99dbb24d-723f-44f0-b082-24b8ce4784d4/oauth2/logout`,
      `logout azureAd`,
      150,
      150
    );
  };

  const handlerSelectOption = async (key) => {
    switch (key) {
      case "orders":
        navigate(PATHS.LIST_ORDERS);
        break;
      case "outputs":
        navigate(PATHS.OUT_LIST);
        break;
      case "stops":
        navigate(PATHS.STOP_LIST);
        break;
      case "home":
        navigate(PATHS.HOME);
        break;
      case "move":
        navigate(PATHS.MOVE_LIST);
        break;
      case "items":
        navigate(PATHS.ITEMS_LIST);
        break;
      case "auxItems":
        navigate(PATHS.AUX_ITEMS_LIST);
        break;
      case "quality":
        navigate(PATHS.EXT_CONTROL_LIST);
        break;
      case "errorMove":
        navigate(PATHS.MOVE_ERROR_LIST);
        break;
      case "junction":
        navigate(PATHS.JUNCTIONS);
        break;
      case "turn":
        navigate(PATHS.TURN_LIST);
        break;
      case "close_session":
        logout();
        break;

      default:
        break;
    }
  };

  return (
    <>
      <ul className="nav flex-column text-white w-100 verticalMenu">
        {offCanvas && (
          <OffCanvasHeader className="offcanvas-header">
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </OffCanvasHeader>
        )}
        <a
          href={`${process.env.REACT_APP_PROTOCOL}://${
            process.env.REACT_APP_HOST
          }${
            process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ""
          }/client/home`}
          className="nav-link-logo text-center mb-4 mt-3"
        >
          <Logo
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_ama.png`}
            alt="logo"
            className="logo"
            offCanvas={offCanvas}
          />
        </a>

        <NavLink href="#" className="selectable nav-link mt-2">
          {/* <span className="mx-2"> */}
          <ButtonOption
            $active={true}
            data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
            onClick={() => {
              handlerSelectOption("home");
            }}
          >
            <IconStyled className="fa-solid fa-gauge" />
            {t("home")}
          </ButtonOption>

          {/* </span> */}
        </NavLink>
        <Can do={USER_PERMISSIONS.VIEW_JUNCTIONS}>
          <NavLink href="#" className="selectable nav-link mt-2">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("junction");
              }}
            >
              <IconStyled className="fa-solid fa-arrow-down-up-across-line" />
              {t("junction")}
            </ButtonOption>

            {/* </span> */}
          </NavLink>
        </Can>
        <Can do={USER_PERMISSIONS.LIST_INPUTS}>
          <NavLink href="#" className="selectable nav-link">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("orders");
              }}
            >
              <IconStyled className="fa-solid fa-right-to-bracket" />
              {t("orders")}
            </ButtonOption>
            {/* </span> */}
          </NavLink>
        </Can>
        <Can do={USER_PERMISSIONS.LIST_OUTPUT}>
          <NavLink href="#" className="selectable nav-link">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("outputs");
              }}
            >
              <IconStyled className="fa-solid fa-right-from-bracket" />
              {t("outputs")}
            </ButtonOption>
            {/* </span> */}
          </NavLink>
        </Can>
        <Can do={USER_PERMISSIONS.LIST_STOP_HISTORY}>
          <NavLink href="#" className="selectable nav-link">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("stops");
              }}
            >
              <IconStyled className="fa-solid fa-ban" />
              {t("stops")}
            </ButtonOption>
            {/* </span> */}
          </NavLink>
        </Can>
        <Can do={USER_PERMISSIONS.LIST_MOVE}>
          <NavLink href="#" className="selectable nav-link">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("move");
              }}
            >
              <IconStyled className="fa-solid fa-list-check" />
              {t("move")}
            </ButtonOption>
            {/* </span> */}
          </NavLink>
        </Can>
        <Can do={USER_PERMISSIONS.ERROR_LIST}>
          <NavLink href="#" className="selectable nav-link">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("errorMove");
              }}
            >
              <IconStyled className="fa-solid fa-triangle-exclamation" />
              {t("errorMove")}
            </ButtonOption>
            {/* </span> */}
          </NavLink>
        </Can>
        <Can do={USER_PERMISSIONS.LIST_ITEMS}>
          <NavLink href="#" className="selectable nav-link">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("items");
              }}
            >
              <IconStyled className="fa-solid fa-cube" />
              {t("items")}
            </ButtonOption>
            {/* </span> */}
          </NavLink>
        </Can>
        <Can do={USER_PERMISSIONS.VIEW_AUX_ITEMS}>
          <NavLink href="#" className="selectable nav-link">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("auxItems");
              }}
            >
              <IconStyled className="fa-solid fa-boxes-packing" />
              {t("auxItems")}
            </ButtonOption>
            {/* </span> */}
          </NavLink>
        </Can>
        <Can do={USER_PERMISSIONS.LIST_QUALITY}>
          <NavLink href="#" className="selectable nav-link">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("quality");
              }}
            >
              <IconStyled className="fa-solid fa-vial-circle-check" />
              {t("quality")}
            </ButtonOption>
            {/* </span> */}
          </NavLink>
        </Can>
        <Can do={USER_PERMISSIONS.VIEW_TURNS}>
          <NavLink href="#" className="selectable nav-link">
            {/* <span className="mx-2"> */}
            <ButtonOption
              $active={true}
              data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
              onClick={() => {
                handlerSelectOption("turn");
              }}
            >
              <IconStyled className="fa-regular fa-calendar-days" />
              {t("turn")}
            </ButtonOption>
            {/* </span> */}
          </NavLink>
        </Can>
        {/* </Can> */}
        {/* <Can do={USER_PERMISSIONS.TREE_VIEW}> */}
        {/* <NavLink
            data-tip
            data-for={`${
              !this.context.can(USER_PERMISSIONS.TREE_VIEW) && "optionDisabled"
            }`}
            href="#"
            className={`${
              this.context.can(USER_PERMISSIONS.TREE_VIEW) && "selectable"
            } nav-link`}
          >
            <span className="mx-2">
              <ButtonOption
                active={this.context.can(USER_PERMISSIONS.TREE_VIEW)}
                onClick={() => {
                  if (this.context.can(USER_PERMISSIONS.TREE_VIEW)) {
                    this.handlerSelectOption("unilevelTree");
                  }
                }}
              >
                <IconStyled className="fas fa-sitemap" />
                {t("user_tree")}
              </ButtonOption>
            </span>
          </NavLink> */}
        {/* </Can> */}
        <NavLink href="#" className="selectable nav-link">
          {/* <span className="mx-2"> */}
          <ButtonOption
            $active={true}
            data-bs-dismiss={deviceSize < 3 ? "offcanvas" : ""}
            onClick={() => {
              handlerSelectOption("close_session");
            }}
          >
            <IconStyled className="fas fa-power-off" />
            {t("close_session")}
          </ButtonOption>
          {/* </span> */}
        </NavLink>
      </ul>
      {/* <span href="#" className="nav-link w-100 mb-2">
        <ButtonOption2
          $active={true}
          onClick={() => {
            handlerSelectOption("close_session");
          }}
        >
          <IconStyled className="fas fa-power-off" />
          {t("close_session")}
        </ButtonOption2>
      </span> */}
    </>
  );
};

// Sidebar.contextType = AbilityContext;

Sidebar.propTypes = {
  nickname: PropTypes.string,
  offCanvas: PropTypes.bool,
};

export default Sidebar;

export const openCenteredPopup = (url, title, w, h) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=no,
      toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      resizable=no,
      copyhistory=no,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  newWindow?.focus();

  setTimeout(() => {
    newWindow.close();
  }, 5000);
};
