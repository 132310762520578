import React from "react";
import { useTranslation } from "react-i18next";
import { ContainerData, LabelData } from "./newProcess.styled";
import Breadcrumb from "../../utils/breadCrumbs";
import PATHS from "../../../constants/paths";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

const NewReProcess03 = (props) => {
  const { t } = useTranslation();
  const { goBack, batchData, processData, workPartidora, onSubmit, item } =
    props;
  const navigate = useNavigate();

  const breads = [{ label: t("home"), path: PATHS.HOME }];
  breads.push({ label: t("forms.process.prodRegister") });
  breads.push({ label: t("forms.process.processStep03") });

  const cancel = () => {
    navigate(PATHS.HOME);
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11">
          <h3 className="title">{t(`forms.process.activity`)}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11 d-flex justify-content-center">
          <div id="crumbs">
            <ul>
              <li>
                <div className="done">
                  <i className="fa-solid fa-industry"></i>{" "}
                  {t(`forms.process.labels.point`)}
                </div>
              </li>
              <li>
                <div className="done">
                  <i className="fa-solid fa-box"></i>{" "}
                  {t(`forms.process.labels.product`)}
                </div>
              </li>
              <li>
                <div className="active">
                  <i className="fa-solid fa-magnifying-glass"></i>{" "}
                  {t("forms.process.labels.resume")}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11">
          <div className="row justify-content-center mb-3">
            <div className="col-12 subtitle">
              {t(`forms.process.labels.processData`)}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12 col-md-12 col-lg-6">
              <ContainerData>
                <div className="row">
                  <div className="col-12">
                    <LabelData>{t("forms.process.labels.outBatch")}:</LabelData>
                    {batchData.outBatch}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <LabelData>
                      {t("forms.process.labels.outWeight")}:
                    </LabelData>
                    {`${batchData.outWeight} Kg`}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <LabelData>
                      {t("forms.process.labels.harvestYear")}:
                    </LabelData>
                    {`${batchData.harvestYear}`}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <LabelData>{t("forms.process.labels.itemCode")}:</LabelData>
                    {batchData.itemCode}
                  </div>
                </div>
                {item ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <LabelData>
                          {t("forms.process.labels.product")}:
                        </LabelData>
                        {`${item.name}`}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-12">
                    <LabelData>
                      {t("forms.process.labels.productionDate")}:
                    </LabelData>
                    {`${moment(batchData.productionDate).format("DD-MM-yyyy")}`}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <LabelData>
                      {t("forms.process.labels.validateDate")}:
                    </LabelData>
                    {`${moment(batchData.validateDate).format("DD-MM-yyyy")}`}
                  </div>
                </div>
              </ContainerData>
            </div>
          </div>
          <div className="row justify-content-center mt-4 mb-3">
            <div className="col-12 subtitle">
              {t(`forms.process.labels.point`)}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12 col-md-8">
              <ContainerData>
                <div className="row">
                  <div className="col-12">
                    <LabelData>{t(`forms.process.labels.point`)}:</LabelData>
                    {t(processData.name, { code: processData.code })}
                  </div>
                </div>
                {processData.process ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <LabelData>
                          {t(`forms.process.labels.process`)}:
                        </LabelData>
                        {processData.process?.name}
                      </div>
                    </div>
                    {workPartidora !== null ? (
                      <div className="row">
                        <div className="col-12">
                          <LabelData>
                            {t(`forms.process.labels.partiendo`)}:
                          </LabelData>
                          {workPartidora ? "Sí" : "No"}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </ContainerData>
            </div>
          </div>
          <div className="row justify-content-center mb-5">
            <div className="col-10 col-sm-11 text-center mt-5">
              <button
                type="button"
                onClick={() => cancel()}
                className="btn btn-lg btn-cancel me-3"
              >
                <i className="fa-solid fa-ban iconButton"></i>
                {t("forms.process.buttons.cancel")}
              </button>
              {/* <button
                type="button"
                onClick={() => goBack()}
                className="btn btn-lg btn-secondary me-3"
              >
                <i className="fa-solid fa-angles-left iconButton"></i>
                {t("forms.process.buttons.back")}
              </button> */}
              <button
                type="button"
                onClick={() => onSubmit()}
                className="btn btn-lg btn-primary me-3"
              >
                {t("forms.process.buttons.finish")}
                <i className="fa-regular fa-floppy-disk iconButton"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

NewReProcess03.propTypes = {};

export default NewReProcess03;
