import React from "react";
import CheckInForm from "./checkIn.view";

const CheckInFormContainer = ({ onCancel, onSuccess }) => {
  const handleSubmit = async (values) => {
    try {
      onSuccess(values);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <CheckInForm onSubmit={handleSubmit} cancelAction={onCancel} />
    </>
  );
};

CheckInFormContainer.propTypes = {};

export default CheckInFormContainer;
