import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import NewProcessForm from "./orderForm.view";
import * as OrderServices from "../../../../services/OrderServices";
import * as ProductionProcessServices from "../../../../services/ProductionProcessServices";
import * as ItemService from "../../../../services/ItemService";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";
import { PRODUCTION_PROCESS_PARTIDORA } from "../../../../constants/index";

const NewProcessFormContainer = ({ uuid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [process, setProcess] = useState(null);
  const [calibres, setCalibres] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [initValues, setInitValues] = useState({});
  const [outSelected, setOutSelected] = useState(null);
  const [calSelected, setCalSelected] = useState([]);
  // const [data, setData] = useState(null);

  const handlerOnChangeVariety = useCallback(
    async (value, varietyInit, varietyCodeInit) => {
      const variety = varieties.find((va) => va.code === value);
      const filter = {
        varietyCode: variety ? variety.varietyCode : varietyCodeInit,
      };
      ItemService.filterCalibres(filter).then((result) => {
        if (result && result.length > 0) {
          const dataCalibre = result.map((item) => {
            return {
              code: item.uuid,
              label: `${variety?.variety || varietyInit} ${item.calibre}`,
            };
          });
          dataCalibre.unshift({ code: "", label: "" });
          setCalibres(dataCalibre);
          if (!varietyInit) {
            setOutSelected(null);
          }
        }
      });
    },
    [varieties]
  );

  const setInitialValues = useCallback(
    async (order) => {
      const data = {
        varietyCode: order.item.code,
        origin: order.origin,
        destination: order.destination,
        batch: order.batch,
        harvestYear: order.harvestYear,
      };
      await handlerOnChangeVariety(
        order.item.code,
        order.variety,
        order.varietyCode
      );
      if (order.outPoints) {
        // let newCal = [...calSelected];

        const points = Object.keys(order.outPoints);
        const newOut = points.length > 0 ? {} : null;
        for (const point of points) {
          newOut[point] = order.outPoints[point].dataItem.uuid;
          // newCal.push(order.outPoints[point].dataItem.uuid);
        }
        // setCalSelected(newCal);
        setOutSelected(newOut);
      }
      setInitValues(data);
    },
    [handlerOnChangeVariety]
  );

  const loadData = useCallback(async () => {
    const resultProcess = await ProductionProcessServices.getProcess(
      PRODUCTION_PROCESS_PARTIDORA
    );
    if (resultProcess) {
      setProcess(resultProcess);
    }
    const resultItems = await ItemService.getVarietiesCascara();
    if (resultItems && resultItems.length > 0) {
      const dataVarieties = resultItems.map((item) => {
        return {
          code: item.code,
          label: item.name,
          varietyCode: item.varietyCode,
          variety: item.variety,
        };
      });
      setVarieties(dataVarieties);
    }
    if (uuid) {
      const resultOrder = await OrderServices.getDetail(uuid);
      if (resultOrder) {
        await setInitialValues(resultOrder);
        // setData(resultOrder);
      }
    }
  }, [setInitialValues, uuid]);

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (values) => {
    let title = "";
    let text = "";
    let icon = "";

    try {
      const okAction = () => {
        navigate(PATHS.LIST_ORDERS);
      };
      const varietySelected = varieties.find(
        (va) => va.code === values.varietyCode
      );
      values.variety = varietySelected.variety;
      values.varietyCode = varietySelected.varietyCode;
      values.itemCode = varietySelected.code;
      let result;
      if (uuid) {
        result = await OrderServices.updateEntry(values, outSelected, uuid);
      } else {
        result = await OrderServices.registerEntry(values, outSelected);
      }
      if (result) {
        title = t("forms.order.messages.register.title");
        text = t("forms.order.messages.register.success");
        icon = "success";
      }
      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const handlerOnOutSelected = async (value, output) => {
    let newOut;
    let newCal = [...calSelected];

    if (!outSelected) {
      newOut = {};
    } else {
      newOut = { ...outSelected };
    }

    if (value.value) {
      if (newOut[output.uuid]) {
        const oldPoint = newOut[output.uuid];
        const index = newCal.indexOf(oldPoint);
        if (index !== -1) {
          newCal.splice(index, 1);
        }
      }
      newOut[output.uuid] = value.value;
      newCal.push(value.value);
    } else {
      const index = newCal.indexOf(newOut[output.uuid]);
      if (index !== -1) {
        newCal.splice(index, 1);
      }
      delete newOut[output.uuid];
    }

    setOutSelected(newOut);
    setCalSelected(newCal);
  };

  return (
    <NewProcessForm
      initialValues={initValues}
      onSubmit={handleSubmit}
      dataProcess={process}
      varieties={varieties}
      onChangeVariety={handlerOnChangeVariety}
      calibres={calibres}
      outSelected={outSelected}
      calSelected={calSelected}
      onOutSelected={handlerOnOutSelected}
    />
  );
};

NewProcessFormContainer.propTypes = {};

export default NewProcessFormContainer;
