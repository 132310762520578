import { store } from "../store/index";
import {
  getJunctionData,
  register,
  addBB,
  finish,
  remove,
} from "../store/actions/JunctionActions";

/**
 * List items
 */
export const getJunction = async () => {
  const result = await store.dispatch(getJunctionData());
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Register new junction
 */
export const createJunction = async (values) => {
  const result = await store.dispatch(register(values));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Register new junction
 */
export const registerBB = async (values, type) => {
  values.type = type;
  const result = await store.dispatch(addBB(values));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Register new junction
 */
export const finishJunction = async () => {
  const result = await store.dispatch(finish());
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Remove item junction
 */
export const removeItem = async (uuid) => {
  const result = await store.dispatch(remove([uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};
