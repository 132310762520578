import { createSelector } from "reselect";
import { TURN_VIEW_CALENDAR } from "../../constants";

const getStateNavSelector = (state) => state.nav;

export const getLanguageSelector = createSelector(
  getStateNavSelector,
  (nav) => {
    if (nav && nav.language) {
      return nav.language;
    }

    return undefined;
  }
);

export const getTurnViewTypeSelector = createSelector(
  getStateNavSelector,
  (nav) => {
    if (nav && nav.turnView) {
      return nav.turnView;
    }

    return TURN_VIEW_CALENDAR;
  }
);
