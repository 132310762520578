import { store } from "../store/index";
import {
  register,
  getLast,
  filterEventTurns,
  filterTurns,
  getResume,
  createTurns,
  deleteUserTurn,
  getTurnData,
  updateTurn,
  close,
  listTurnToExport,
} from "../store/actions/TurnActions";
/**
 * List order
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterTurns(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List order
 */
export const listToExport = async (filter) => {
  const result = await store.dispatch(listTurnToExport(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const getLastTurn = async () => {
  const result = await store.dispatch(getLast());
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const registerTurn = async (values) => {
  let result = await store.dispatch(register(values));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const closeTurn = async (values) => {
  const obj = { ...values };
  let result = await store.dispatch(close(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * List turns
 */
export const listTurnEvent = async (filter, isToClose = false) => {
  const result = await store.dispatch(filterEventTurns(filter, isToClose));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List turns
 */
export const resume = async (filter) => {
  const result = await store.dispatch(getResume(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Send holidays
 * @returns
 */
export const sendTurns = async (dates, userSelected, hourType) => {
  const obj = {
    dates: dates,
    user: userSelected.value,
    type: hourType.value,
  };
  const result = await store.dispatch(createTurns(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const deleteTurn = async (uuid) => {
  const result = await store.dispatch(deleteUserTurn([uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const getTurn = async (uuid) => {
  const result = await store.dispatch(getTurnData([uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * Send holidays
 * @returns
 */
export const editTurn = async (userSelected, uuid) => {
  const obj = {
    user: userSelected.value,
  };
  const result = await store.dispatch(updateTurn(obj, [uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};
