import React, { useState } from "react";
import List from "../itemsFlowsList";
import Filter from "../itemsFlowsFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import * as ItemService from "../../../../services/ItemService";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import { ALMOND_TYPE } from "../../../../constants/index";

const queryClient = new QueryClient();

const ItemsFilterListContainer = (props) => {
  const { t } = useTranslation();

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.items.title") },
  ];
  const [filters, setFilters] = useState(undefined);

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const exportToExcel = async () => {
    const filter = {
      filters,
      sort: "code",
    };
    ItemService.listToExport(filter).then((result) => {
      const data = result.map((item) => {
        const type = ALMOND_TYPE.find((st) => st.code === item.type);
        return {
          A: item.code,
          B: item.variety,
          C: item.name,
          D: item.calibre === "CAS" || item.type === 4 ? "-" : item.calibre,
          E: t(type.label),
        };
      });
      data.unshift({
        A: "Código",
        B: "Variedad",
        C: "Artículo",
        D: "Calibre",
        E: "Tipo",
      });

      const filename = `Items_export_${moment(new Date()).format(
        "DDMMyyyyHHmm"
      )}`;
      const sheetName = "Listado de Artículos";
      var ws = XLSX.utils.json_to_sheet(data, {
        header: ["A", "B", "C", "D", "E"],
        skipHeader: true,
        UTC: true,
        dateNF: "dd-mm-yyyy hh:mm;@",
      });
      // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.items.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <div className="row justify-content-center mt-4 mb-5">
        <div className="col-12 col-md-11 text-center">
          <button
            type="button"
            className="btn btn-lg btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
    </>
  );
};

ItemsFilterListContainer.propTypes = {};

export default ItemsFilterListContainer;
