import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { withTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./outFilter.data.form";
import InputDate from "../../../utils/InputDate";
import InputText from "../../../utils/InputText";

const OutFilterForm = (props) => {
  const { t, handleSubmit, filtered, onClean } = props;
  return (
    <div className="row no-margin">
      <div className="col-12 mb-3">
        <div className="accordion" id="outFilter">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                {t("forms.out.filter")}
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#outFilter"
            >
              <div className="accordion-body">
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-3">
                      <Field
                        id="batch"
                        className="form-control"
                        labelField={t("forms.out.labels.batch")}
                        name="batch"
                        component={InputText}
                        fieldType="text"
                        placeholder=""
                      />
                    </div>

                    <div className="col-6">
                      <div className="row">
                        <div className="col-6">
                          <Field
                            id="dateInit"
                            labelField={t("forms.out.labels.dateInit")}
                            className="form-control"
                            name="dateInit"
                            component={InputDate}
                            language="es"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        <div className="col-6">
                          <Field
                            id="dateEnd"
                            labelField={t("forms.out.labels.dateEnd")}
                            className="form-control"
                            name="dateEnd"
                            component={InputDate}
                            language="es"
                            dateFormat="dd/MM/yyyy"
                            defaultHours={23}
                            defaultMinutes={59}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <Field
                        id="itemCode"
                        className="form-control"
                        labelField={t("forms.out.labels.itemCode")}
                        name="itemCode"
                        component={InputText}
                        fieldType="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary me-2"
                      >
                        <i className="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-secondary"
                        onClick={() => onClean(props.resetForm)}
                        title={t("cleanFilter")}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OutFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
};

const OutFilterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "OutFilterForm",
})(withTranslation()(OutFilterForm));

export default OutFilterFormF;
