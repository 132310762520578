import React, { useState, useCallback } from "react";
import NewReProcess02 from "./newReProcess02.view";
import NewReProcess01 from "./newReProcess01.view";
import NewReProcess03 from "./newReProcess03.view";

import * as OrderServices from "../../../services/OrderServices";
import * as LocationServices from "../../../services/LocationServices";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import BarCodeScanner from "../../utils/Scanner";
import { useTranslation } from "react-i18next";
import showDialog from "../../utils/ShowDialog/ShowDialog";
import PATHS from "../../../constants/paths";
import {
  LOCATION_TYPE_ENTRY,
  LOCATION_TYPE_OUTPUT,
  LOCATION_TYPE_CHANGE,
  OUT_PRODUCTION_TYPE,
  REPROCESS_TYPE,
  OUT_CALIBRE,
} from "../../../constants/index";
import { useNavigate } from "react-router-dom";
import { ButtonClose } from "./newProcess.styled";
import { BarcodeFormat } from "@zxing/library";

const NewProcessFormContainer = ({ isReprocess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [type, setType] = useState("");
  const [initial, setInitial] = useState({});
  const [processData, setProcessData] = useState(null);
  const [isPrincipal, setIsPrincipal] = useState(null);
  const [workPartidora, setWorkPartidora] = useState(null);
  const [item, setItem] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    let title = "";
    let text = "";
    let icon = "";

    const okAction = () => {
      navigate(PATHS.HOME);
    };

    try {
      let result;

      if (type === REPROCESS_TYPE) {
        result = await OrderServices.registerEntryData(
          initial,
          processData,
          workPartidora
        );
        if (result) {
          title = t("forms.process.messages.processProd.title");
          if (result && result.outBatchNoExist) {
            text = t("forms.process.messages.processProd.successWarning");
          } else {
            text = t("forms.process.messages.processProd.success");
          }
          icon = "success";
        }
      } else {
        if (processData.type === LOCATION_TYPE_CHANGE) {
          result = await OrderServices.registerChange(initial, processData);
          if (result) {
            title = t("forms.process.messages.processChange.title");
            text = t("forms.process.messages.processChange.success");
            icon = "success";
          }
        } else {
          result = await OrderServices.registerOut(
            initial,
            processData,
            isPrincipal
          );
          if (result) {
            title = t("forms.process.messages.processProd.title");
            text = t("forms.process.messages.processProd.success");
            icon = "success";
          }
        }
      }

      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      //console.log('Error', error);
    }
  };

  const manageCode01 = async (codeInit) => {
    // const tt = "02000000710001011123083017241129371000";
    const code = `${codeInit}`;

    // const initCode01 = tt.substring(0, 2);
    const idItem = code.substring(2, 16); //itemCode
    // const initCode02 = tt.substring(16, 18);
    const productionDate = code.substring(18, 24);
    // const initCode03 = tt.substring(24, 26);
    const validateDate = code.substring(26, 32);
    // const initCode04 = tt.substring(32, 34);
    const weight = code.substring(34); //outWeight

    const yearP = productionDate.substring(0, 2);
    const monthP = productionDate.substring(2, 4);
    const dayP = productionDate.substring(4);

    const yearV = validateDate.substring(0, 2);
    const monthV = validateDate.substring(2, 4);
    const dayV = validateDate.substring(4);

    const newInitial = { ...initial };
    newInitial.itemCode = `${Number(idItem)}`;
    newInitial.productionDate = new Date(`20${yearP}-${monthP}-${dayP}`);
    // newInitial.harvestYear = Number(`20${yearP}`);
    newInitial.validateDate = new Date(`20${yearV}-${monthV}-${dayV}`);
    newInitial.outWeight = Number(weight);

    setInitial(newInitial);
  };

  const manageCode02 = async (codeInit) => {
    // const tt = "000000000000002356171023242G5819";
    const code = `${codeInit}`;
    // if (code.length === 34 || code.length === 35) {
    // const initCode01 = code.substring(0, 2);
    const sscc = code.substring(2, 20); //sscc
    // const initCode02 = code.substring(18, 20);
    const batchCode = code.substring(22); //outBatch

    const newinitial = { ...initial };
    newinitial.outBatch = batchCode;
    newinitial.sscc = sscc;

    newinitial.harvestYear = Number(`20${batchCode.substring(0, 2)}`);

    let juliano = "";
    if (batchCode.length >= 12) {
      juliano = batchCode.substring(4, 7);
    } else {
      juliano = batchCode.substring(2, 5);
    }

    const currentJuliano = obtenerDiaJuliano(new Date()) + "";
    if (juliano && currentJuliano !== juliano) {
      const okAction = () => {
        setInitial(newinitial);
      };

      const cancelAction = () => {
        // setWorkPartidora(false);
        // nextStep();
      };

      showDialog(
        t("forms.process.dialog.juliano.title"),
        t("forms.process.dialog.juliano.text"),
        "warning",
        true,
        t("forms.process.dialog.juliano.buttonOk"),
        false,
        okAction,
        t("forms.process.dialog.juliano.buttonNo"),
        cancelAction
      );
    } else {
      setInitial(newinitial);
    }

    // }
  };

  function obtenerDiaJuliano(fecha) {
    var inicioAño = new Date(fecha.getFullYear(), 0, 0);
    var milisegundosEnUnDia = 86400000; // 24 horas * 60 minutos * 60 segundos * 1000 milisegundos
    var diferencia = fecha - inicioAño;
    var diaJuliano = Math.floor(diferencia / milisegundosEnUnDia);

    return diaJuliano;
  }

  const handleScanBarCode01 = async (value) => {
    manageCode01(value);
  };

  const handleScanBarCode02 = async (value) => {
    manageCode02(value);
  };

  const onDetectedMachineCode = async (error, data) => {
    if (data) {
      LocationServices.checkLocationAndPartido(data).then((result) => {
        let typeD = "";
        if (result) {
          if (result.type === LOCATION_TYPE_ENTRY) {
            typeD = REPROCESS_TYPE;
          } else if (result.type === LOCATION_TYPE_OUTPUT) {
            typeD = OUT_PRODUCTION_TYPE;
          } else if (result.type === LOCATION_TYPE_CHANGE) {
            typeD = OUT_PRODUCTION_TYPE;
          }
          setType(typeD);
          setProcessData(result);

          handleNextToProductReader({ type: typeD, processData: result });
        }
      });
    }
  };

  const handleScanMachine = useCallback(async (value) => {
    await onDetectedMachineCode("", value);
    // handleNextToProductReader();
  }, []);

  const nextStep = () => {
    setStep(() => step + 1);
  };

  const prevStep = () => {
    setStep(() => step - 1);
  };

  const handleNextReprocess = async (values) => {
    setInitial(values);
    const isCalibrado = OUT_CALIBRE.find((out) => out === processData.code);
    if (type === REPROCESS_TYPE) {
      nextStep();
    } else if (isCalibrado && type === OUT_PRODUCTION_TYPE) {
      OrderServices.checkItemWithOutput(values.itemCode, processData.uuid).then(
        (result) => {
          if (result && result.response === "OK") {
            setItem(result.item);
            nextStep();
          } else {
            showDialog(
              t("forms.process.messages.itemAllowed.title"),
              t("forms.process.messages.itemAllowed.text"),
              "error",
              false
            );
          }
        }
      );
    } else {
      nextStep();
    }
  };

  const handleNextToProductReader = async ({ type, processData }) => {
    if (
      type === REPROCESS_TYPE &&
      processData &&
      processData.workAlone === false &&
      processData.orderOpen
    ) {
      const okAction = () => {
        setWorkPartidora(true);
        nextStep();
      };

      const cancelAction = () => {
        setWorkPartidora(false);
        nextStep();
      };

      showDialog(
        t("forms.process.dialog.workAlone.title"),
        t("forms.process.dialog.workAlone.text"),
        "warning",
        true,
        t("forms.process.dialog.workAlone.buttonOk"),
        false,
        okAction,
        t("forms.process.dialog.workAlone.buttonNo"),
        cancelAction
      );
    } else if (
      type === REPROCESS_TYPE &&
      processData &&
      processData.workAlone === false &&
      !processData.orderOpen
    ) {
      setWorkPartidora(false);
      nextStep();
    } else {
      if (processData && processData.isPrincipalMachine === true) {
        const okAction = () => {
          setIsPrincipal(true);
          nextStep();
        };

        const cancelAction = () => {
          setIsPrincipal(false);
          nextStep();
        };

        showDialog(
          t("forms.process.dialog.isPrincipal.title"),
          t("forms.process.dialog.isPrincipal.text"),
          "warning",
          true,
          t("forms.process.dialog.isPrincipal.buttonOk"),
          false,
          okAction,
          t("forms.process.dialog.isPrincipal.buttonNo"),
          cancelAction
        );
      } else {
        nextStep();
      }
    }
  };

  const handleOnCleanMachine = async () => {
    setType("");
    setProcessData(null);
    setWorkPartidora(null);
  };

  const renderProcessStep = () => {
    if (step === 1) {
      return (
        <NewReProcess01
          processData={processData}
          onSubmit={handleNextToProductReader}
          onScan1={handleScanMachine}
          onClean={handleOnCleanMachine}
        />
      );
    }
    if (step === 2) {
      return (
        <NewReProcess02
          initialValues={initial}
          processData={processData}
          onSubmit={handleNextReprocess}
          onScan1={handleScanBarCode01}
          onScan2={handleScanBarCode02}
          goBack={prevStep}
        />
      );
    }
    if (step === 3) {
      return (
        <NewReProcess03
          item={item}
          batchData={initial}
          processData={processData}
          workPartidora={workPartidora}
          goBack={prevStep}
          onSubmit={handleSubmit}
        />
      );
    }
  };

  return <>{renderProcessStep()}</>;
};

NewProcessFormContainer.propTypes = {};

export default NewProcessFormContainer;
