import React from "react";
import { flexRender } from "@tanstack/react-table";
import { Table as BTable } from "react-bootstrap";
import DefaultPagination from "./DefaultPagination";

const PaginateListView = ({ table }) => {
  // const rerender = React.useReducer(() => ({}), {})[1];

  return (
    <>
      {table.getState().totalDocs > 0 ? (
        <div className="p-2">
          <BTable bsPrefix="" striped bordered hover responsive size="sm">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ position: "relative", width: header.getSize() }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`}
                        ></div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="middle">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </BTable>
          <DefaultPagination table={table}></DefaultPagination>
        </div>
      ) : (
        <div className="row mt-4 mb-4">
          <div className="col-12 text-center">No se encontraron registros.</div>
        </div>
      )}
    </>
  );
};

export default PaginateListView;
