import React from "react";
import FilterList from "../../../components/user/items/itemsFlowsFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const ItemListPage = (props) => {
  return <Layout body={<FilterList />} />;
};

ItemListPage.propTypes = {};

export default ItemListPage;
