import styled from "styled-components";

export const TitleBB = styled.div`
  font-size: 1rem;
  margin-bottom: 5px;
  color: #f9ae17;
  font-weight: 600;
`;

export const ButtonRemove = styled.button`
  font-size: 1rem;
  color: #ff0000;
  font-weight: 600;
  background: transparent;
  border: 1px solid #ff0000;
  margin-bottom: 10px;
`;

export const TitleJunction = styled.div`
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #203b34;
  font-weight: 600;
  text-decoration: underline;
`;
export const ButtonClose = styled.button`
  font-size: 1rem;
  border: 0px;
  background: transparent;
`;

export const IconButton = styled.i`
  font-size: 1rem;
  margin-right: 10px;
`;

export const Title = styled.h3`
  /* font-size: 1rem; */
  margin-right: 10px;
`;

export const ContainerData = styled.div`
  border: 1px solid #f9ae17;
  padding: 10px 15px;
  border-radius: 10px;
`;

export const LabelData = styled.span`
  font-weight: bold;
  color: #203b34;
  margin-right: 10px;
`;

export const ContainerBB = styled.div`
  border: 1px solid #203b34;
  padding: 10px 15px;
  min-height: 250px;
`;
