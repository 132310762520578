import { createAction } from "redux-actions";
import { PAGE_SIZE_10 } from "../../constants/index";
import { apiPost, apiGet, apiDelete, apiPut } from "../../api/index";
import {
  urlTurnHistoryLastSecured,
  urlTurnHistorySecured,
  urlTurnEventsHistorySecured,
  urlResumeTurnHistorySecured,
  urlCreateHistorySecured,
  urlTurnHistoryInitSecured,
  urlTurnHistoryCloseSecured,
  urlExportTurnSecured,
} from "../../api/urls";
import moment from "moment-timezone";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.type !== undefined) filter += "&type=" + filters.type;
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      filter += "&dateInit=" + dateInit.valueOf();
    }
    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      filter += "&dateEnd=" + dateEnd.valueOf();
    }
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

const generateEventFilterQuery = (filters, isToClose) => {
  let filter = "?";

  if (filters) {
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      if (!isToClose) {
        dateInit.startOf("day");
      }
      filter += "dateInit=" + dateInit.valueOf();
    }
    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      if (!isToClose) {
        dateEnd.endOf("day");
      }
      filter += "&dateEnd=" + dateEnd.valueOf();
    }
    if (filters.allowAll) {
      filter += "&allowAll=" + filters.allowAll;
    }
  }

  return filter;
};

/**
 * Filter list orders.
 */
export const filterTurns = createAction("FILTER_TURNS", (filter, param) =>
  apiGet(urlTurnHistorySecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const filterEventTurns = createAction(
  "FILTER_EVENT_TURNS",
  (filter, isToClose) =>
    apiGet(
      urlTurnEventsHistorySecured + generateEventFilterQuery(filter, isToClose),
      undefined,
      true
    )()
);

/**
 * Filter list orders.
 */
export const listTurnToExport = createAction(
  "EXPORT_FILTER_TURNS",
  (filter, param) =>
    apiGet(urlExportTurnSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Get last process.
 */
export const getTurnData = createAction("GET_TURN", (param) =>
  apiGet(urlTurnHistorySecured, param, undefined)()
);

/**
 * Get last process.
 */
export const getLast = createAction("GET_LAST_TURN", () =>
  apiGet(urlTurnHistoryLastSecured, undefined, undefined)()
);

/**
 * register process.
 */
export const register = createAction("REGISTER_TURN", (obj) =>
  apiPost(urlTurnHistoryInitSecured, obj, undefined, true)()
);

/**
 * register process.
 */
export const close = createAction("CLOSE_TURN", (obj) =>
  apiPost(urlTurnHistoryCloseSecured, obj, undefined, true)()
);

/**
 * Filter list orders.
 */
export const getResume = createAction("FILTER_EVENT_TURNS", (filter) =>
  apiGet(
    urlResumeTurnHistorySecured + generateEventFilterQuery(filter),
    undefined,
    true
  )()
);

/**
 * Send Holidays.
 */
export const createTurns = createAction("CREATE_TURNS", (obj) =>
  apiPost(urlCreateHistorySecured, obj, undefined, true)()
);

/**
 * Delete error.
 */
export const deleteUserTurn = createAction("DELETE_TURN", (param) =>
  apiDelete(urlTurnHistorySecured, param, undefined)()
);

/**
 * Update Turn.
 */
export const updateTurn = createAction("UPDATE_TURN", (obj, param) =>
  apiPut(urlTurnHistorySecured, obj, param, true)()
);
