import React from "react";
import ExtControlForm from "../../../components/user/extControl/extControlForm";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const NewExtControlPage = () => {
  let { uuid } = useParams();
  return (
    <Layout
      body={
        <>
          <ExtControlForm uuid={uuid} />
        </>
      }
    />
  );
};

NewExtControlPage.propTypes = {};

export default NewExtControlPage;
