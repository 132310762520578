import styled from "styled-components";

export const BodyContainer = styled.div`
  background-color: #fdfdfd;
  min-height: 100vh;
  color: #000;
  /* background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; */
  /* background-position: bottom 90%; */

  /* background-image: ${({ $deviceSize }) =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/${
      $deviceSize <= 3 ? "logo-grande.png" : "de_prado_cover.png"
    }')`}; */
  /* background-size: ${({ $deviceSize }) =>
    $deviceSize <= 3 ? "70%" : "100%"}; */
`;

export const SideBarContainer = styled.div`
  width: 250px;
  height: 100%;
  position: fixed;
  /* background-image: url("assets/themes/deprado/images/fondo-menu.png"); */
  background-color: ${({ theme }) =>
    theme ? theme.sidebar.backgroundColor : "#fff"};
  transition: 0.5s;
  overflow-y: auto;
`;

export const OffCanvas = styled.div`
  top: 0;
  left: 0;
  width: 250px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) =>
    theme ? theme.sidebar.backgroundColor : "#fff"};
  transform: translateX(-100%);
`;
