import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { withTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./orderFilter.data.form";
import InputSelect from "../../../utils/InputSelect";
import { ORDER_TYPE, ORIGINS, DESTINIES } from "../../../../constants/index";
import InputDate from "../../../utils/InputDate";
import InputText from "../../../utils/InputText";
import * as ItemService from "../../../../services/ItemService";

const OrderFilterForm = (props) => {
  const { t, handleSubmit, filtered, onClean } = props;
  const [varieties, setVarieties] = useState([]);

  const loadData = async () => {
    const resultItems = await ItemService.getVarietiesCascara();
    if (resultItems && resultItems.length > 0) {
      const dataVarieties = resultItems.map((item) => {
        return {
          code: item.itemUuid,
          label: item.name,
        };
      });
      setVarieties(dataVarieties);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="row no-margin">
      <div className="col-12 mb-3">
        <div className="accordion" id="orderFilter">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                {t("forms.order.filter")}
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#orderFilter"
            >
              <div className="accordion-body">
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-3">
                      <Field
                        id="batch"
                        className="form-control"
                        labelField={t("forms.order.labels.batch")}
                        name="batch"
                        component={InputText}
                        fieldType="text"
                        placeholder=""
                      />
                    </div>
                    <div className="col-3">
                      <Field
                        id="type"
                        name="type"
                        labelField={t("forms.order.labels.type")}
                        component={InputSelect}
                        optionLabel="label"
                        optionValue="code"
                        options={ORDER_TYPE.map((item) => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        placeholder=""
                        classNamePrefix="dark"
                        addOptionEmpty
                        classNameContainer="flex-grow-1"
                      />
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-6">
                          <Field
                            id="dateInit"
                            labelField={t("forms.order.labels.dateInit_ini")}
                            className="form-control"
                            name="dateInit"
                            component={InputDate}
                            language="es"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        <div className="col-6">
                          <Field
                            id="dateEnd"
                            labelField={t("forms.order.labels.dateEnd_ini")}
                            className="form-control"
                            name="dateEnd"
                            component={InputDate}
                            language="es"
                            dateFormat="dd/MM/yyyy"
                            defaultHours={23}
                            defaultMinutes={59}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-4">
                      <Field
                        id="itemUuid"
                        name="itemUuid"
                        labelField={t("forms.order.labels.product")}
                        component={InputSelect}
                        placeholder=""
                        optionLabel="label"
                        optionValue="code"
                        options={varieties.map((item) => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        classNamePrefix="dark"
                        addOptionEmpty
                        classNameContainer="flex-grow-1"
                      />
                    </div>

                    <div className="col-4">
                      <Field
                        id="origin"
                        name="origin"
                        labelField={t("forms.order.labels.origin")}
                        // className="form-control"
                        component={InputSelect}
                        placeholder=""
                        optionLabel="label"
                        optionValue="code"
                        options={ORIGINS.map((item) => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        addOptionEmpty
                        classNamePrefix="dark"
                      />
                    </div>
                    <div className="col-4">
                      <Field
                        id="destination"
                        name="destination"
                        labelField={t("forms.order.labels.destination")}
                        // className="form-control"
                        component={InputSelect}
                        placeholder=""
                        optionLabel="label"
                        optionValue="code"
                        options={DESTINIES.map((item) => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        addOptionEmpty
                        classNamePrefix="dark"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary me-2"
                      >
                        <i className="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-secondary"
                        onClick={() => onClean(props.resetForm)}
                        title={t("cleanFilter")}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OrderFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
};

const OrderFilterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "OrderFilterForm",
})(withTranslation()(OrderFilterForm));

export default OrderFilterFormF;
