import { store } from "../store/index";
import {
  filterAllItems,
  getAllVarieties,
  filterCalibresVariety,
  getAllVarietiesCascara,
  filterItemsAdm,
  listItemsToExport,
} from "../store/actions/ItemActions";

/**
 * List items
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterItemsAdm(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List order
 */
export const filterItems = async (filter) => {
  const result = await store.dispatch(filterAllItems(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get varieties
 */
export const getVarietiesCascara = async () => {
  const result = await store.dispatch(getAllVarietiesCascara());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get varieties
 */
export const getVarieties = async () => {
  const result = await store.dispatch(getAllVarieties());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List calibres
 */
export const filterCalibres = async (filter) => {
  const result = await store.dispatch(filterCalibresVariety(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List order
 */
export const listToExport = async (filter) => {
  const result = await store.dispatch(listItemsToExport(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
