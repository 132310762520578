import styled from "styled-components";

export const DateContainer = styled.div`
  padding: 5px 12px;
  background-color: #ccc;
  color: #000;
  border-radius: 10px;
  font-size: 1rem;
  margin-right: 10px;
`;

export const DeleteDateButton = styled.button`
  background: transparent;
  color: #000;
  border: 0px;
  font-size: 1rem;
`;
