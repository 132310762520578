const urlBase = `${process.env.REACT_APP_API_PROTOCOL}://${
  process.env.REACT_APP_API_HOST
}${
  process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ""
}/api/`;

/** AUTH **/
export const urlGetLoginItemTemp = `${urlBase}v1/items/login`;

export const urlRefreshToken = `${urlBase}v1/user/refresh-token`;
export const urlLogout = `${urlBase}v1/user/logout`;
export const urlGetProfile = `${urlBase}v1/user-s/profile`;
export const urlGetSuperVisor = `${urlBase}v1/user-s/supervisors`;

export const urlPreRegisterEmployee = `${urlBase}v1/employeeRequest-s/preRegister`;
export const urlOrderSecured = `${urlBase}v1/order-s`;
export const urlOrderRegisterSecured = `${urlBase}v1/order-s/register`;
export const urlOrderRegisterReproSecured = `${urlBase}v1/order-s/registerRepro`;
export const urlCheckItemOrderSecured = `${urlBase}v1/order-s/checkItem`;
export const urlOrderCloseReproSecured = `${urlBase}v1/order-s/close`;
export const urlOrderBlockReproSecured = `${urlBase}v1/order-s/block`;
export const urlOrderOpenSecured = `${urlBase}v1/order-s/open`;

export const urlHistoryPauseSecured = `${urlBase}v1/stopHistory-s`;
export const urlStopHistoryPauseMachineSecured = `${urlBase}v1/stopHistory-s/pauseMachine`;
export const urlStopHistoryPauseSecured = `${urlBase}v1/stopHistory-s/pause`;
export const urlPlayHistoryPauseSecured = `${urlBase}v1/stopHistory-s/play`;
export const urlStopHistoryLastSecured = `${urlBase}v1/stopHistory-s/last`;
export const urlStopHistoryEventSecured = `${urlBase}v1/stopHistory-s/events`;
export const urlHolidaysSecured = `${urlBase}v1/stopHistory-s/holidays`;
export const urlExportStopSecured = `${urlBase}v1/stopHistory-s/export`;

export const urlMoveSecured = `${urlBase}v1/moveFlow-s`;
export const urlExportMoveSecured = `${urlBase}v1/moveFlow-s/export`;
export const urlMoveDataFilterSecured = `${urlBase}v1/moveFlow-s/dataFilter`;
export const urlMoveNotificationSecured = `${urlBase}v1/moveFlow-s/error`;
export const urlMoveHistorySecured = `${urlBase}v1/moveFlow-s/history/error`;

export const urlProcessSecured = `${urlBase}v1/outPutProductionOrder-s`;
export const urlProcessRegisterSecured = `${urlBase}v1/outPutProductionOrder-s/register`;
export const urlProcessRegisterChangeSecured = `${urlBase}v1/outPutProductionOrder-s/registerChange`;
export const urlExportOutsSecured = `${urlBase}v1/outPutProductionOrder-s/export`;

export const urlLocationStatusSecured = `${urlBase}v1/location-s/status`;
export const urlLocationSecured = `${urlBase}v1/location-s`;
export const urlProdProcessSecured = `${urlBase}v1/productionProcess-s`;

export const urlItemsSecured = `${urlBase}v1/items-s`;
export const urlItemsAdmSecured = `${urlBase}v1/items-s/listAdm`;
export const urlItemsCalibresSecured = `${urlBase}v1/items-s/calibres`;
export const urlItemsVarietiesSecured = `${urlBase}v1/items-s/varieties`;
export const urlItemsVarietiesCascaraSecured = `${urlBase}v1/items-s/varieties/cascara`;
export const urlExportItemsSecured = `${urlBase}v1/items-s/export`;

export const urlAuxItemsSecured = `${urlBase}v1/auxItems-s`;

export const urlJunctionSecured = `${urlBase}v1/junction-s`;
export const urlAddBBJunctionSecured = `${urlBase}v1/junction-s/addBB`;
export const urlFinishJunctionSecured = `${urlBase}v1/junction-s/finish`;
export const urlRemoveItemJunctionSecured = `${urlBase}v1/junction-s/item`;

export const urlExtControlSecured = `${urlBase}v1/extControl-s`;
export const urlGetExtControlSecured = `${urlBase}v1/extControl-s/get`;
export const urlExportExtSecured = `${urlBase}v1/extControl-s/export`;

export const urlTurnHistorySecured = `${urlBase}v1/turnHistory-s`;
export const urlTurnEventsHistorySecured = `${urlBase}v1/turnHistory-s/events`;
export const urlTurnHistoryLastSecured = `${urlBase}v1/turnHistory-s/last`;
export const urlResumeTurnHistorySecured = `${urlBase}v1/turnHistory-s/resume`;
export const urlCreateHistorySecured = `${urlBase}v1/turnHistory-s/bulkCreate`;
export const urlTurnHistoryInitSecured = `${urlBase}v1/turnHistory-s/init`;
export const urlTurnHistoryCloseSecured = `${urlBase}v1/turnHistory-s/close`;
export const urlExportTurnSecured = `${urlBase}v1/turnHistory-s/export`;
