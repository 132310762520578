import React from "react";
import JunctionList from "../../../components/user/junction/junctionResume";
import Layout from "../../../components/layouts/verticalLayout";

const JunctionListPage = (props) => {
  return <Layout body={<JunctionList />} />;
};

JunctionListPage.propTypes = {};

export default JunctionListPage;
