import React from "react";
import FilterList from "../../../components/user/extControl/extControlFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const ExtControlListPage = (props) => {
  return <Layout body={<FilterList />} />;
};

ExtControlListPage.propTypes = {};

export default ExtControlListPage;
