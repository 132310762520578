import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ContainerData, LabelData } from "./newProcess.styled";
import Breadcrumb from "../../utils/breadCrumbs";
import PATHS from "../../../constants/paths";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

const NewProcess01 = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [read, setRead] = useState("");
  const [value] = useDebounce(read, 500);
  const inputRef = useRef(null);

  const { onScan1, processData, onSubmit, onClean } = props;

  const breads = [{ label: t("home"), path: PATHS.HOME }];
  breads.push({ label: t("forms.process.activity") });
  breads.push({ label: t("forms.process.processStep01") });

  const goBack = () => {
    navigate(PATHS.HOME);
  };

  useEffect(() => {
    if (value.length === 26) {
      onScan1(value);
      setRead("");
    }
  }, [value]);

  const reset = () => {
    setRead("");
    inputRef.current.focus();
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11">
          <h3 className="title">{t(`forms.process.activity`)}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11 d-flex justify-content-center">
          <div id="crumbs">
            <ul>
              <li>
                <div className="active">
                  <i className="fa-solid fa-industry"></i>{" "}
                  {t(`forms.process.labels.point`)}
                </div>
              </li>
              <li>
                <div>
                  <i className="fa-solid fa-box"></i>{" "}
                  {t(`forms.process.labels.product`)}
                </div>
              </li>
              <li>
                <div>
                  <i className="fa-solid fa-magnifying-glass"></i>{" "}
                  {t("forms.process.labels.resume")}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {!processData ? (
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-md-4">
            <div className="input-group mb-3">
              <input
                autoFocus
                ref={inputRef}
                type="text"
                value={read}
                className="form-control"
                autoComplete="off"
                onChange={(event) => {
                  setRead(event.target.value);
                }}
              />
              <button
                className="btn btn-secondary"
                type="button"
                id="button-addon2"
                onClick={() => reset()}
              >
                <i className="fa-solid fa-ban"></i>
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11">
          {processData ? (
            <div className="row mb-2">
              <div className="col-12">
                <ContainerData>
                  <div className="row">
                    <div className="col-12">
                      <LabelData>{t(`forms.process.labels.point`)}:</LabelData>
                      {t(processData.name, { code: processData.code })}
                    </div>
                  </div>
                  {processData.process ? (
                    <div className="row">
                      <div className="col-12">
                        <LabelData>
                          {t("forms.process.labels.reprocessName")}:
                        </LabelData>
                        {processData.process?.name}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </ContainerData>
                <div className="mt-2 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() => onClean()}
                    className="btn btn-lg btn-secondary me-3"
                  >
                    <i className="fa-regular fa-trash-can iconButton"></i>
                    {t("forms.process.buttons.reLead")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row justify-content-center mb-5">
            <div className="col-10 col-sm-11 text-center mt-5">
              <button
                type="button"
                onClick={() => goBack()}
                className="btn btn-lg btn-secondary me-3"
              >
                <i className="fa-solid fa-ban iconButton"></i>
                {t("forms.process.buttons.cancel")}
              </button>
              <button
                type="button"
                onClick={() => onSubmit()}
                className="btn btn-lg btn-primary"
              >
                {t("forms.process.buttons.continue")}
                <i className="fa-solid fa-angles-right iconButton"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

NewProcess01.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

export default NewProcess01;
