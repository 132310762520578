import styled from "styled-components";

export const ButtonAction = styled.button`
  margin-right: 2px;
  color: #f9ae17;
  font-size: 1.5rem;
  border: 0px;
  background: transparent;

  &:hover {
    color: #f9ae17;
    font-size: 1.5rem;
  }
`;

export const ButtonClose = styled.button`
  font-size: 1rem;
  border: 0px;
  background: transparent;
`;

export const TitleComment = styled.div`
  font-size: 1.2rem;
  color: #203b34;
  text-decoration: underline;
`;

export const Value = styled.div`
  margin-left: 5px;
`;
