import React from "react";
import NotificationForm from "./notificationErrorForm.view";

const NotificationErrorFormContainer = ({ onCancel, onSuccess }) => {
  const handleSubmit = async (values) => {
    try {
      onSuccess(values);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <NotificationForm onSubmit={handleSubmit} cancelAction={onCancel} />
    </>
  );
};

NotificationErrorFormContainer.propTypes = {};

export default NotificationErrorFormContainer;
