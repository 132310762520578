import { store } from "../store/index";
import {
  filterMoves,
  dataFilter,
  listMovesToExport,
  notificationError,
  deleteMoveFlow,
  getMoveFlow,
  update,
  filterHistoryMoves,
} from "../store/actions/MoveFlowsActions";
/**
 * List order
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterMoves(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List order
 */
export const listHistoryErrorFilter = async (filter) => {
  const result = await store.dispatch(filterHistoryMoves(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List order
 */
export const listToExport = async (filter) => {
  const result = await store.dispatch(listMovesToExport(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Data form filter
 */
export const loadDataFilter = async () => {
  const result = await store.dispatch(dataFilter());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const sendError = async (values, moveUuid) => {
  const obj = {
    comment: values.comment,
  };
  const result = await store.dispatch(notificationError(obj, [moveUuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const deleteMove = async (uuid) => {
  const result = await store.dispatch(deleteMoveFlow([uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const getMove = async (uuid) => {
  const result = await store.dispatch(getMoveFlow([uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const updateMove = async (values, uuid) => {
  values.workPartidora = values.workPartidora === "true";
  const result = await store.dispatch(update(values, [uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};
