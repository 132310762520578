import { store } from "../store/index";
import { getProductionProcess } from "../store/actions/ProductionProcessAction";

/**
 * List order
 */
export const getProcess = async (code) => {
  const result = await store.dispatch(getProductionProcess(code));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
