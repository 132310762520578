import List from "../../moveFlows/moveFlowsListOperator";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const LastProcessContainer = () => {
  return (
    <div className="row no-margin">
      <div className="col-12 no-padding">
        <QueryClientProvider client={queryClient}>
          <List sortBy="-date" />
          {/* <List  />filter={{ orderUuid: uuid }} */}
        </QueryClientProvider>
      </div>
    </div>
  );
};

export default LastProcessContainer;
