import * as Yup from "yup";

export const dataModel = {
  dateInit: "",
  dateEnd: "",
  batch: "",
  varietyCode: "",
  silo: "",
};

export const validationDataModel = Yup.object().shape({
  dateInit: Yup.date().optional("forms.validations.required"),
  dateEnd: Yup.date().optional("forms.validations.required"),
  batch: Yup.string().optional(""),
  varietyCode: Yup.string().optional(""),
  silo: Yup.string().optional(""),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
