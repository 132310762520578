import * as Yup from "yup";

export const dataModel = {
  itemCode: "",
  outWeight: 0,
  outBatch: "",
  harvestYear: 0,
  productionDate: "",
  validateDate: "",
  sscc: "",
  comments: "",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    itemCode: Yup.string().required("forms.validations.required"),
    outWeight: Yup.number()
      .required("forms.validations.required")
      .min(1, { message: "forms.validations.min", args: { min: 0 } }),
    outBatch: Yup.string()
      .required("forms.validations.required")
      .max(15, { message: "forms.validations.batch", args: { min: 0 } }),
    harvestYear: Yup.number().required("forms.validations.required"),
    comments: Yup.string().optional("forms.validations.required"),
    productionDate: Yup.date()
      .typeError("forms.validations.date")
      .required("forms.validations.required"),
    validateDate: Yup.date()
      .typeError("forms.validations.date")
      .required("forms.validations.required"),
    sscc: Yup.string().required("forms.validations.required"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
