import React, { useState } from "react";
import HolidaysForm from "./holidaysForm.view";
import moment from "moment-timezone";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";
import * as StopServices from "../../../../services/StopServices";

const HolidaysFormContainer = ({ onCancel, onSuccess }) => {
  const [holidays, setHolidays] = useState([]);
  const [isPeriod, setIsPeriod] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (startDate, endDate) => {
    try {
      let dates = [];
      if (isPeriod) {
        if (!startDate || !endDate) {
          showDialog(
            t("forms.turnHistory.message.holidays.title"),
            t("forms.turnHistory.message.holidays.errorDateEmpty"),
            "error",
            false,
            undefined,
            false
          );
          return false;
        }
        const init = new Date(startDate);
        const end = new Date(endDate);
        if (end < init) {
          showDialog(
            t("forms.turnHistory.message.holidays.title"),
            t("forms.turnHistory.message.holidays.errorDateMin"),
            "error",
            false,
            undefined,
            false
          );
          return false;
        }
        dates = [
          { id: "start", date: init },
          { id: "end", date: end },
        ];
      } else {
        dates = holidays;
      }

      if (dates && dates.length > 0) {
        const result = await StopServices.sendHolidays(dates, isPeriod);
        if (result) {
          showDialog(
            t("forms.turnHistory.message.holidays.title"),
            t("forms.turnHistory.message.holidays.success"),
            "success",
            false,
            undefined,
            false
          );
          onSuccess();
        }
      } else {
        showDialog(
          t("forms.turnHistory.message.holidays.title"),
          t("forms.turnHistory.message.holidays.errorHolydaysEmpty"),
          "error",
          false,
          undefined,
          false
        );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleAddHolidays = async (value) => {
    const newHolidays = holidays ? [...holidays] : [];
    const id = moment(new Date(value)).format("DDMMyyyy");
    const index = newHolidays.find((h) => h.id === id);
    if (!index) {
      newHolidays.push({
        id: id,
        date: new Date(value),
      });
      setHolidays(newHolidays);
    } else {
      showDialog(
        t("forms.turnHistory.message.holidays.title"),
        t("forms.turnHistory.message.holidays.errorDuplicated"),
        "error",
        false,
        undefined,
        false
      );
    }
  };

  const handleRemoveHolidays = async (value) => {
    const newHolidays = [...holidays];
    const index = newHolidays.findIndex((h) => h.id === value);
    newHolidays.splice(index, 1);
    setHolidays(newHolidays);
  };

  const onCancelAction = () => {
    setHolidays(null);
    onCancel();
  };

  const handlerOnIsPeriod = async (value) => {
    setIsPeriod(value);
  };

  return (
    <>
      <HolidaysForm
        holidays={holidays}
        onSubmit={handleSubmit}
        addHolidays={handleAddHolidays}
        removeHolidays={handleRemoveHolidays}
        cancelAction={onCancelAction}
        isPeriod={isPeriod}
        onChangePeriod={handlerOnIsPeriod}
      />
    </>
  );
};

HolidaysFormContainer.propTypes = {};

export default HolidaysFormContainer;
