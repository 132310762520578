import React, { useEffect, useState, useCallback } from "react";
// import PropTypes from "prop-types";
import MoveFlowForm from "./moveFlowForm.view";
import * as MoveFlowServices from "../../../../services/MoveFlowServices";
import * as LocationServices from "../../../../services/LocationServices";
import * as ItemService from "../../../../services/ItemService";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const MoveFlowFormContainer = ({ uuid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [initValues, setInitValues] = useState({});
  const [locations, setLocations] = useState([]);
  const [data, setData] = useState(null);

  const setInitialValues = useCallback(async (move) => {
    const data = {
      locationUuid: move.locationUuid,
      itemCode: move.itemCode,
      batch: move.batch,
      totalWeight: move.totalWeight,
      harvestYear: move.harvestYear,
      date: move.date,
      workPartidora: move.workPartidora ? "true" : "false",
    };
    setInitValues(data);
  }, []);

  const loadData = useCallback(async () => {
    const resultLocations = await LocationServices.getAllLocations();
    if (resultLocations) {
      setLocations(resultLocations);
    }
    const resultItems = await ItemService.filterItems();
    if (resultItems) {
      setItems(resultItems);
    }
    if (uuid) {
      const resultMove = await MoveFlowServices.getMove(uuid);
      if (resultMove) {
        setInitialValues(resultMove);
        setData(resultMove);
      }
    }
  }, [uuid]);

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (values) => {
    let title = "";
    let text = "";
    let icon = "";
    try {
      const okAction = () => {
        navigate(PATHS.MOVE_ERROR_LIST);
      };
      let result;
      if (uuid) {
        result = await MoveFlowServices.updateMove(values, uuid);
      }
      if (result) {
        title = t("forms.moveFlow.messages.update.title");
        text = t("forms.moveFlow.messages.update.success");
        icon = "success";
      }
      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      // console.log("Error", error);
    }
  };

  return (
    <MoveFlowForm
      initialValues={initValues}
      onSubmit={handleSubmit}
      items={items}
      locations={locations}
    />
  );
};

MoveFlowFormContainer.propTypes = {};

export default MoveFlowFormContainer;
