import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { DateContainer, DeleteDateButton } from "./styled";
import Select from "react-select";
import { HOURS_TYPES } from "../../../../constants";

const getLocale = () => require(`date-fns/locale/es/index.js`);

const HolidaysForm = (props) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const {
    onSubmit,
    cancelAction,
    addTurn,
    turns,
    removeTurn,
    hourType,
    onSelectHourType,
    users,
    userSelected,
    onSelectUser,
  } = props;
  let defaultValue = { value: "", label: "" };

  const types = [];
  for (const type of HOURS_TYPES) {
    types.push({
      value: type.code,
      label: t(type.label),
    });
  }

  const finalUsers = [];
  if (users) {
    for (const user of users) {
      finalUsers.push({
        value: user.uuid,
        label: t(user.email),
      });
    }
  }

  const renderHolidays = () => {
    return turns.map((holy) => {
      return (
        <DateContainer key={holy.id}>
          {moment(holy.date).format("DD/MM/yyyy HH:mm")}
          <DeleteDateButton
            type="button"
            onClick={() => removeTurn(holy.id)}
            className="ms-2"
          >
            <i className="fa-solid fa-xmark"></i>
          </DeleteDateButton>
        </DateContainer>
      );
    });
  };

  const submitForm = () => {
    onSubmit();
  };

  const addDate = () => {
    if (startDate) {
      addTurn(startDate);
      setStartDate(null);
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <h3 className="title">{t(`forms.turnHistory.turnForm`)}</h3>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11">
          <div className="row">
            <div className="col-12 mt-2 d-flex">
              <label className="control-label mandatory">
                {t("forms.turnHistory.labels.userTurn")}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <Select
                id="user"
                name="user"
                value={userSelected || defaultValue}
                onChange={(value) => {
                  onSelectUser(value);
                }}
                options={finalUsers}
                classNamePrefix="dark"
                placeholder=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2 d-flex">
              <label className="control-label mandatory">
                {t("forms.turnHistory.labels.turnType")}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <Select
                id="turnHourType"
                name="turnHourType"
                value={hourType || defaultValue}
                onChange={(value) => {
                  onSelectHourType(value);
                }}
                options={types}
                classNamePrefix="dark"
                placeholder=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2 d-flex">
              <label className="control-label mandatory">
                {t("forms.turnHistory.labels.dateTurn")}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2 d-flex">
              <DatePicker
                id="dateTurn"
                name="dateTurn"
                className={`inputFormDate form-control`}
                selected={startDate}
                locale={getLocale()}
                dateFormat={"dd/MM/yyyy HH:mm"}
                showTimeSelect
                onChange={(date) => setStartDate(date)}
              />
              <button
                type="button"
                onClick={() => addDate()}
                className="btn btn-md btn-secondary ms-2"
              >
                {t(`forms.turnHistory.buttons.addHolidays`)}
              </button>
            </div>
          </div>

          {turns && turns.length > 0 && (
            <>
              <div className="row">
                <div className="col-12 mt-2 d-flex">
                  {t("forms.turnHistory.labels.dateSelected")}
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-2 d-flex">{renderHolidays()}</div>
              </div>
            </>
          )}

          <div className="row justify-content-center">
            <div className="col-10 col-sm-11 text-center mt-5 mb-5">
              <button
                type="button"
                onClick={() => cancelAction()}
                className="btn btn-lg btn-secondary me-2"
              >
                {t(`forms.turnHistory.buttons.cancel`)}
              </button>
              <button
                type="button"
                onClick={() => submitForm()}
                className="btn btn-lg btn-primary"
              >
                {t(`forms.turnHistory.buttons.save`)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

HolidaysForm.propTypes = {
  holidays: PropTypes.array,
  onSubmit: PropTypes.func,
  addTurn: PropTypes.func,
  cancelAction: PropTypes.func,
};

export default HolidaysForm;
