import React from "react";
import Form from "../../../components/user/auxItems/auxItemsNew";
import Layout from "../../../components/layouts/verticalLayout";

const AuxItemFormPage = (props) => {
  return <Layout body={<Form />} />;
};

AuxItemFormPage.propTypes = {};

export default AuxItemFormPage;
