import { Navigate, Routes, Route, useNavigate } from "react-router-dom";
import HomePage from "./pages/home";
import NewProcessPage from "./pages/newProcess";
import AuthConfirmPage from "./pages/authConfirm";
import LoginPage from "./pages/login";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PATHS from "./constants/paths";
import OrderListPage from "./pages/order/list";
import NewOrderPage from "./pages/order/form";
import DetailOrderPage from "./pages/order/detail";
import OutListPage from "./pages/output/list";
import StopListPage from "./pages/stopHistory/list";
import MoveListPage from "./pages/moveFlow/list";
import ErrorMoveListPage from "./pages/moveFlow/errors";
import ItemListPage from "./pages/items/list";
import AuxItemListPage from "./pages/auxItems/list";
import AuxItemFormPage from "./pages/auxItems/form";
import JunctionPage from "./pages/junction/list";
import ExtControlListPage from "./pages/extControl/list";
import NewExtControlPage from "./pages/extControl/form";
import TurnHistoryPage from "./pages/turnHistory/list";
import CloseTurnHistoryPage from "./pages/turnHistory/close";
import { setNavigate } from "./utils/navigate/navigation";
import MoveFlowFormPage from "./pages/moveFlow/form";

function App() {
  // const navigate = useNavigate();
  // const { user } = AuthContainer.useContainer();
  const NavigateSetter = () => {
    const navigate = useNavigate();
    setNavigate(navigate);
    return null;
  };

  return (
    <>
      <NavigateSetter />

      <Routes>
        {/* <Route path="/logout" element={<Logout />} /> */}
        <Route path="/factory" element={<ProtectedRoutes />}>
          <Route path={PATHS.HOME} exact element={<HomePage />} />
          <Route
            path={PATHS.ORDER_DETAIL}
            exact
            element={<DetailOrderPage />}
          />
          <Route
            path={PATHS.UPDATE_MOVEFLOW}
            exact
            element={<MoveFlowFormPage />}
          />

          <Route path={PATHS.NEW_ORDER} exact element={<NewOrderPage />} />
          <Route path={PATHS.LIST_ORDERS} exact element={<OrderListPage />} />
          <Route path={PATHS.NEW_PROCESS} exact element={<NewProcessPage />} />
          <Route path={PATHS.UPDATE_ORDER} exact element={<NewOrderPage />} />
          <Route path={PATHS.OUT_LIST} exact element={<OutListPage />} />
          <Route path={PATHS.STOP_LIST} exact element={<StopListPage />} />
          <Route path={PATHS.MOVE_LIST} exact element={<MoveListPage />} />
          <Route path={PATHS.ITEMS_LIST} exact element={<ItemListPage />} />
          <Route path={PATHS.JUNCTIONS} exact element={<JunctionPage />} />
          <Route
            path={PATHS.AUX_ITEMS_LIST}
            exact
            element={<AuxItemListPage />}
          />
          <Route path={PATHS.TURN_LIST} exact element={<TurnHistoryPage />} />
          <Route
            path={PATHS.CLOSE_TURN}
            exact
            element={<CloseTurnHistoryPage />}
          />

          <Route
            path={PATHS.EXT_CONTROL_LIST}
            exact
            element={<ExtControlListPage />}
          />
          <Route
            path={PATHS.NEW_EXT_CONTROL}
            exact
            element={<NewExtControlPage />}
          />
          <Route
            path={PATHS.UPDATE_EXT_CONTROL}
            exact
            element={<NewExtControlPage />}
          />
          <Route
            path={PATHS.NEW_AUX_ITEMS}
            exact
            element={<AuxItemFormPage />}
          />
          <Route
            path={PATHS.MOVE_ERROR_LIST}
            exact
            element={<ErrorMoveListPage />}
          />
        </Route>

        <Route
          path={PATHS.EXTERNAL_CONFIRM_LOGIN}
          exact
          element={<AuthConfirmPage />}
        />
        <Route path="/login" exact element={<LoginPage />} />
        <Route path="/" element={<Navigate to={"/login"} />} />
      </Routes>
    </>
  );
}

export default App;
