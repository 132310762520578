import { createAction } from "redux-actions";
import { PAGE_SIZE_10 } from "../../constants/index";
import { apiGet, apiPut, apiDelete } from "../../api/index";
import {
  urlMoveSecured,
  urlMoveDataFilterSecured,
  urlExportMoveSecured,
  urlMoveNotificationSecured,
  urlMoveHistorySecured,
} from "../../api/urls";
import moment from "moment-timezone";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.type !== undefined) filter += "&type=" + filters.type;
    if (filters.batch !== undefined) filter += "&batch=" + filters.batch;
    if (filters.locationUuid !== undefined)
      filter += "&locationUuid=" + filters.locationUuid;
    if (filters.itemVariety !== undefined)
      filter += "&itemVariety=" + filters.itemVariety;
    if (filters.itemCalibre !== undefined)
      filter += "&itemCalibre=" + filters.itemCalibre;
    if (filters.userUuid !== undefined)
      filter += "&userUuid=" + filters.userUuid;
    if (filters.itemCode !== undefined)
      filter += "&itemCode=" + filters.itemCode;
    if (filters.workPartidora !== undefined)
      filter += "&workPartidora=" + filters.workPartidora;
    if (filters.hasError !== undefined)
      filter += "&hasError=" + filters.hasError;
    if (filters.itemType !== undefined)
      filter += "&itemType=" + filters.itemType;
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      filter += "&dateInit=" + dateInit.valueOf();
    }
    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      filter += "&dateEnd=" + dateEnd.valueOf();
    }
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Delete error.
 */
export const getMoveFlow = createAction("GET_MOVE", (param) =>
  apiGet(urlMoveSecured, param, undefined)()
);

/**
 * Filter list orders.
 */
export const filterMoves = createAction("FILTER_MOVES", (filter, param) =>
  apiGet(urlMoveSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const filterHistoryMoves = createAction(
  "FILTER_HISTORY_MOVES",
  (filter, param) =>
    apiGet(urlMoveHistorySecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const listMovesToExport = createAction(
  "EXPORT_FILTER_MOVES",
  (filter, param) =>
    apiGet(urlExportMoveSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Get data filters.
 */
export const dataFilter = createAction("DATA_FILTERS", () =>
  apiGet(urlMoveDataFilterSecured, undefined, true)()
);

/**
 * Notification error.
 */
export const notificationError = createAction(
  "ERROR_NOTIFICATION",
  (obj, param) => apiPut(urlMoveNotificationSecured, obj, param, undefined)()
);

/**
 * Delete error.
 */
export const deleteMoveFlow = createAction("DELETE_MOVE", (param) =>
  apiDelete(urlMoveSecured, param, undefined)()
);

/**
 * Update process.
 */
export const update = createAction("UPDATE_MOVEFLOW", (obj, param) =>
  apiPut(urlMoveSecured, obj, param, true)()
);
