import { createAction } from "redux-actions";
import { PAGE_SIZE_10 } from "../../constants/index";
import { apiGet, apiPut, apiPost } from "../../api/index";
import {
  urlExtControlSecured,
  urlGetExtControlSecured,
  urlExportExtSecured,
} from "../../api/urls";
import moment from "moment-timezone";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.batch !== undefined) filter += "&batch=" + filters.batch;
    if (filters.silo !== undefined) filter += "&silo=" + filters.silo;
    if (filters.varietyCode !== undefined)
      filter += "&varietyCode=" + filters.varietyCode;
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      filter += "&dateInit=" + dateInit.valueOf();
    }
    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      filter += "&dateEnd=" + dateEnd.valueOf();
    }
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list orders.
 */
export const filterExtControl = createAction(
  "FILTER_EXT_CONTROL",
  (filter, param) =>
    apiGet(urlExtControlSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const listExtToExport = createAction(
  "EXPORT_FILTER_EXT",
  (filter, param) =>
    apiGet(urlExportExtSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Get data filters.
 */
export const getExtControl = createAction("GET_EX_CONTROL", (param) =>
  apiGet(urlGetExtControlSecured, param, true)()
);

/**
 * Register control error.
 */
export const registerData = createAction("CONTROL_REGISTER", (obj) =>
  apiPost(urlExtControlSecured, obj, undefined, undefined)()
);

/**
 * Update control.
 */
export const updateExt = createAction("UPDATE_CONTROL", (obj, param) =>
  apiPut(urlExtControlSecured, obj, param, undefined)()
);
