import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ContainerData, LabelData } from "./auxItemsNew.styled";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { AUX_TYPE } from "../../../../constants/index";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import Select from "react-select";

const RegisterAuxItems = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [read, setRead] = useState("");
  const [value] = useDebounce(read, 500);
  const inputRef = useRef(null);

  let defaultValue = { value: "", label: "" };

  const { onScan1, onSubmit, onSelectType, typeSelected, infoTag } = props;

  const breads = [{ label: t("home"), path: PATHS.HOME }];
  breads.push({ label: t("forms.auxItems.title"), path: PATHS.AUX_ITEMS_LIST });
  breads.push({ label: t("forms.auxItems.new") });

  const goBack = () => {
    navigate(PATHS.AUX_ITEMS_LIST);
  };

  useEffect(() => {
    onScan1(value, typeSelected);
    setRead("");
  }, [value]);

  const reset = () => {
    setRead("");
    inputRef.current.focus();
  };

  const types = [];
  for (const type of AUX_TYPE) {
    types.push({
      value: type.code,
      label: t(type.label),
    });
  }

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11">
          <h3 className="title">{t(`forms.auxItems.new`)}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <label className="control-label mandatory">
            {t("forms.auxItems.labels.type")}
          </label>
          <Select
            id="type"
            name="type"
            value={typeSelected || defaultValue}
            onChange={(value) => {
              onSelectType(value);
            }}
            options={types}
            classNamePrefix="dark"
            placeholder=""
          />
        </div>
      </div>
      <div className="row justify-content-center mt-2">
        <div className="col-12 col-md-6">
          <label className="control-label mandatory">
            {t("forms.auxItems.labels.scan")}
          </label>
          <div className="input-group mb-3">
            <input
              autoFocus
              ref={inputRef}
              type="text"
              value={read}
              className="form-control"
              autoComplete="off"
              onChange={(event) => {
                setRead(event.target.value);
              }}
            />
            <button
              className="btn btn-secondary"
              type="button"
              id="button-addon2"
              onClick={() => reset()}
            >
              <i className="fa-solid fa-ban"></i>
            </button>
          </div>
        </div>
      </div>
      {(typeSelected || infoTag) && (
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-md-4">
            {typeSelected && (
              <div className="row">
                <div className="col-12">
                  {`${t("forms.auxItems.labels.type")}: `}{" "}
                  <b>{typeSelected?.label}</b>
                </div>
              </div>
            )}
            {infoTag && (
              <>
                <div className="row">
                  <div className="col-12">
                    {`${t("forms.auxItems.labels.batch")}: `}
                    <b>{infoTag?.batch}</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {`${t("forms.auxItems.labels.itemCode")}: `}
                    <b>{infoTag?.itemCode}</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {`${t("forms.auxItems.labels.amount")}: `}
                    <b>{infoTag?.amount}</b>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11">
          <div className="row justify-content-center mb-5">
            <div className="col-10 col-sm-11 text-center mt-5">
              <button
                type="button"
                onClick={() => goBack()}
                className="btn btn-lg btn-secondary me-3"
              >
                <i className="fa-solid fa-ban iconButton"></i>
                {t("forms.auxItems.buttons.cancel")}
              </button>
              <button
                type="button"
                onClick={() => onSubmit()}
                className="btn btn-lg btn-primary"
              >
                {t("forms.auxItems.buttons.save")}
                <i className="fa-solid fa-angles-right iconButton"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RegisterAuxItems.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

export default RegisterAuxItems;
