import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { DateContainer, DeleteDateButton } from "./styled";

const getLocale = () => require(`date-fns/locale/es/index.js`);

const HolidaysForm = (props) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const {
    onSubmit,
    cancelAction,
    addHolidays,
    holidays,
    removeHolidays,
    isPeriod,
    onChangePeriod,
  } = props;

  const renderHolidays = () => {
    return holidays.map((holy) => {
      return (
        <DateContainer key={holy.id}>
          {moment(holy.date).format("DD/MM/yyyy")}
          <DeleteDateButton
            type="button"
            onClick={() => removeHolidays(holy.id)}
            className="ms-2"
          >
            <i className="fa-solid fa-xmark"></i>
          </DeleteDateButton>
        </DateContainer>
      );
    });
  };

  const submitForm = () => {
    if (isPeriod) {
      onSubmit(startDate, endDate);
    } else {
      onSubmit();
    }
  };

  const addDate = () => {
    if (startDate) {
      addHolidays(startDate);
      setStartDate(null);
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <h3 className="title">{t(`forms.turnHistory.holidaysForm`)}</h3>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11">
          <div className="row">
            <div className="col-12 mt-2 d-flex">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={isPeriod}
                  id="emptyTickets"
                  onChange={(e) => onChangePeriod(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="emptyTickets">
                  {t("forms.turnHistory.labels.isPeriod")}
                </label>
              </div>
            </div>
          </div>
          {!isPeriod ? (
            <>
              <div className="row">
                <div className="col-12 mt-2 d-flex">
                  <label className="control-label mandatory">
                    {t("forms.turnHistory.labels.date")}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-2 d-flex">
                  <DatePicker
                    id="dateHoliday"
                    name="dateHoliday"
                    className={`inputFormDate form-control`}
                    selected={startDate}
                    locale={getLocale()}
                    dateFormat={"dd/MM/yyyy"}
                    onChange={(date) => setStartDate(date)}
                  />
                  <button
                    type="button"
                    onClick={() => addDate()}
                    className="btn btn-md btn-secondary ms-2"
                  >
                    {t(`forms.turnHistory.buttons.addHolidays`)}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12 col-md-6 mt-2">
                  <label className="control-label mandatory">
                    {t("forms.turnHistory.labels.from")}
                  </label>
                  <DatePicker
                    id="dateInit"
                    name="dateInit"
                    className={`inputFormDate form-control`}
                    selected={startDate}
                    locale={getLocale()}
                    dateFormat={"dd/MM/yyyy HH:mm"}
                    showTimeSelect
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <label className="control-label mandatory">
                    {t("forms.turnHistory.labels.to")}
                  </label>
                  <DatePicker
                    id="dateEnd"
                    name="dateEnd"
                    className={`inputFormDate form-control`}
                    selected={endDate}
                    locale={getLocale()}
                    dateFormat={"dd/MM/yyyy HH:mm"}
                    showTimeSelect
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>
            </>
          )}

          {holidays && holidays.length > 0 && (
            <>
              <div className="row">
                <div className="col-12 mt-2 d-flex">
                  {t("forms.turnHistory.labels.dateSelected")}
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-2 d-flex">{renderHolidays()}</div>
              </div>
            </>
          )}

          <div className="row justify-content-center">
            <div className="col-10 col-sm-11 text-center mt-5 mb-5">
              <button
                type="button"
                onClick={() => cancelAction()}
                className="btn btn-lg btn-secondary me-2"
              >
                {t(`forms.turnHistory.buttons.cancel`)}
              </button>
              <button
                type="button"
                onClick={() => submitForm()}
                className="btn btn-lg btn-primary"
              >
                {t(`forms.turnHistory.buttons.save`)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

HolidaysForm.propTypes = {
  holidays: PropTypes.array,
  onSubmit: PropTypes.func,
  addHolidays: PropTypes.func,
  cancelAction: PropTypes.func,
};

export default HolidaysForm;
