import { createAction } from "redux-actions";
import { apiGet } from "../../api/index";
import { urlLocationSecured, urlLocationStatusSecured } from "../../api/urls";

/**
 * Filter list orders.
 */
export const getLocationByCode = createAction("GET_LOCATION", (param) =>
  apiGet(urlLocationStatusSecured, param, true)()
);

/**
 * Filter list orders.
 */
export const getLocations = createAction("GET_LOCATIONS", (param) =>
  apiGet(urlLocationSecured, param, true)()
);
