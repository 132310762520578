import React from "react";
import ProcessForm from "../../components/user/newProcess";
import Layout from "../../components/layouts/verticalLayout";

const NewProcessPage = () => {
  return (
    <Layout
      body={
        <>
          <ProcessForm />
        </>
      }
    />
  );
};

NewProcessPage.propTypes = {};

export default NewProcessPage;
