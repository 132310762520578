import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Container } from "./login.styled";

const LoginForm = (props) => {
  const { gotoLogin } = props;
  const { t } = useTranslation();
  const [isReadyForInstall, setIsReadyForInstall] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);

  const downloadApp = async () => {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  };

  return (
    <div className="login-page row justify-content-center align-items-center no-margin">
      <div className="text-center">
        <button
          type="button"
          className="btn btn-lg btn-primary"
          onClick={() => gotoLogin()}
        >
          {t("login")}
        </button>
        {isReadyForInstall && (
          <button
            type="button"
            className="btn btn-lg btn-block"
            onClick={() => downloadApp()}
          >
            Instalar
          </button>
        )}
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  gotoLogin: PropTypes.func,
};

export default LoginForm;
