import * as Yup from "yup";

export const dataModel = {
  dateInit: "",
  dateEnd: "",
  type: "",
  itemUuid: "",
  origin: "",
  destination: "",
  batch: "",
};

export const validationDataModel = Yup.object().shape({
  dateInit: Yup.date().optional("forms.validations.required"),
  dateEnd: Yup.date().optional("forms.validations.required"),
  type: Yup.string().optional(""),
  itemUuid: Yup.string().optional(""),
  origin: Yup.string().optional(""),
  destination: Yup.string().optional(""),
  batch: Yup.string().optional(""),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
