export const USER_PERMISSIONS = {
  STAFF: "STAFF",
  ADD_OUTPUT: "ADD_OUTPUT",
  LIST_INPUTS: "LIST_INPUTS",
  LIST_OUTPUT: "LIST_OUTPUT",
  LIST_USER_OUTPUT: "LIST_USER_OUTPUT",
  LIST_STOP_HISTORY: "LIST_STOP_HISTORY",
  EXECUTE_STOP: "EXECUTE_STOP",
  LIST_MOVE: "LIST_MOVE",
  EXPORT_MOVE: "EXPORT_MOVE",
  FILTER_MOVE: "FILTER_MOVE",
  ERROR_LIST: "ERROR_LIST",
  LIST_ITEMS: "LIST_ITEMS",
  LIST_QUALITY: "LIST_QUALITY",
  REGISTER_QUALITY: "REGISTER_QUALITY",
  EDIT_QUALITY: "EDIT_QUALITY",
  VIEW_ALL_CALENDAR: "VIEW_ALL_CALENDAR",
  VIEW_JUNCTIONS: "VIEW_JUNCTIONS",
  VIEW_AUX_ITEMS: "VIEW_AUX_ITEMS",
  VIEW_TURNS: "VIEW_TURNS",
  CUSTOM_STOP: "CUSTOM_STOP",
  LIST_TURNS: "LIST_TURNS",
  ADD_HOLIDAYS: "ADD_HOLIDAYS",
  REGISTER_TURNS: "REGISTER_TURNS",
  EDIT_POST: "EDIT_POST",
};
