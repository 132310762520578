import React from "react";
import OrderForm from "../../../components/user/order/orderForm";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const NewOrderPage = () => {
  let { uuid } = useParams();
  return (
    <Layout
      body={
        <>
          <OrderForm uuid={uuid} />
        </>
      }
    />
  );
};

NewOrderPage.propTypes = {};

export default NewOrderPage;
