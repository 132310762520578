import { createAction } from "redux-actions";
import { apiGet, apiPost, apiDelete } from "../../api/index";
import {
  urlJunctionSecured,
  urlAddBBJunctionSecured,
  urlFinishJunctionSecured,
  urlRemoveItemJunctionSecured,
} from "../../api/urls";

/**
 * Get junction data.
 */
export const getJunctionData = createAction("GET_JUNCTION_DATA", () =>
  apiGet(urlJunctionSecured, undefined, true)()
);

/**
 * Register auxItems.
 */
export const register = createAction("CREATE_JUNCTION", (obj) =>
  apiPost(urlJunctionSecured, obj, undefined, true)()
);

/**
 * Register auxItems.
 */
export const addBB = createAction("REGISTER_BB", (obj) =>
  apiPost(urlAddBBJunctionSecured, obj, undefined, true)()
);

/**
 * Finish.
 */
export const finish = createAction("FINISH_JUNCTION", () =>
  apiPost(urlFinishJunctionSecured, {}, undefined, true)()
);

/**
 * Finish.
 */
export const remove = createAction("FINISH_JUNCTION", (param) =>
  apiDelete(urlRemoveItemJunctionSecured, param, true)()
);
