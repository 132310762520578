import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import { useStopAndPlay } from "../../stopHistory/hook/useStopAndPlay";
import { useTurn } from "../../turnHistory/hook/useTurn";

import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";

const LastProcessContainer = () => {
  const { t } = useTranslation();
  const { lastStop, stopLine, playLine, stopMachine } = useStopAndPlay();
  const { lastTurn, registerTurn } = useTurn();

  const navigate = useNavigate();
  const context = useContext(AbilityContext);

  const executeAction = (action) => {
    switch (action) {
      case "newProcess":
        navigate(PATHS.NEW_PROCESS);
        break;
      case "stop":
        stopLine();
        break;
      case "stopMachine":
        stopMachine();
        break;
      case "play":
        playLine();
        break;
      case "checkTurn":
        registerTurn();
        break;

      default:
        break;
    }
  };

  return (
    <div className="row no-margin">
      <div className="col-12 no-padding">
        <div className="d-grid gap-2">
          <button
            className="btn btn-secondary btn-lg"
            type="button"
            onClick={() => executeAction("newProcess")}
          >
            <span>{t("actions.readPoint")}</span>
          </button>
          <Can do={USER_PERMISSIONS.EXECUTE_STOP}>
            <>
              {lastStop && lastStop.status === 1 ? (
                <button
                  className="btn btn-danger btn-lg"
                  type="button"
                  onClick={() => executeAction("play")}
                >
                  <span>{t("actions.play")}</span>
                </button>
              ) : (
                <button
                  className="btn btn-danger btn-lg"
                  type="button"
                  onClick={() => executeAction("stop")}
                >
                  <span>{t("actions.stop")}</span>
                </button>
              )}
              {/* <button
                className="btn btn-danger btn-lg"
                type="button"
                onClick={() => executeAction("stopMachine")}
              >
                <span>{t("actions.stopMachine")}</span>
              </button> */}
            </>
          </Can>
          <Can do={USER_PERMISSIONS.REGISTER_TURNS}>
            <button
              className="btn btn-primary btn-lg"
              type="button"
              onClick={() => executeAction("checkTurn")}
            >
              {lastTurn && lastTurn.status === 1 ? (
                <span>{t("actions.checkOut")}</span>
              ) : (
                <span>{t("actions.checkIn")}</span>
              )}
            </button>
          </Can>
        </div>
      </div>
    </div>
  );
};

export default LastProcessContainer;
