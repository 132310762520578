import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import { useSelector } from "react-redux";
import { getTurnViewTypeSelector } from "../../../../store/selectors/navSelector";
import * as GeneralServices from "../../../../services/GeneralServices";
import { TURN_VIEW_CALENDAR, TURN_VIEW_LIST } from "../../../../constants";
import TurnViewCalendar from "../turnViewCalendar";
import TurnViewList from "../turnViewList";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";

const TurnHistoryFilterListContainer = (props) => {
  const { t } = useTranslation();
  const viewType = useSelector(getTurnViewTypeSelector);
  const context = useContext(AbilityContext);

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.turnHistory.title") },
  ];

  const selectView = async (view) => {
    await GeneralServices.setTurnViewType(view);
  };

  const renderPage = () => {
    if (viewType === TURN_VIEW_LIST) {
      return (
        <>
          <TurnViewList></TurnViewList>
        </>
      );
    } else {
      return (
        <>
          <TurnViewCalendar></TurnViewCalendar>
        </>
      );
    }
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.turnHistory.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <Can do={USER_PERMISSIONS.LIST_TURNS}>
        <div className="row justify-content-center mb-4">
          <div className="col-12 col-md-11">
            <ul className="nav nav-underline">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    viewType === TURN_VIEW_CALENDAR ? "active" : ""
                  }`}
                  type="button"
                  onClick={() => selectView(TURN_VIEW_CALENDAR)}
                >
                  {t("forms.turnHistory.calendar")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    viewType === TURN_VIEW_LIST ? "active" : ""
                  }`}
                  type="button"
                  onClick={() => selectView(TURN_VIEW_LIST)}
                >
                  {t("forms.turnHistory.list")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Can>
      {renderPage()}
    </>
  );
};

TurnHistoryFilterListContainer.propTypes = {};

export default TurnHistoryFilterListContainer;
