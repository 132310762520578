import { createAction } from "redux-actions";
import { apiGet, apiPost } from "../../api/index";
import { urlAuxItemsSecured } from "../../api/urls";
import { PAGE_SIZE_10 } from "../../constants/index";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.code !== undefined) filter += "&code=" + filters.code;
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list orders.
 */
export const filterAuxItems = createAction(
  "FILTER_AUX_ITEMS",
  (filter, param) =>
    apiGet(urlAuxItemsSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Register auxItems.
 */
export const register = createAction("REGISTER_AUX_ITEM", (obj) =>
  apiPost(urlAuxItemsSecured, obj, undefined, undefined)()
);

// /**
//  * Filter list orders.
//  */
// export const filterAllItems = createAction("FILTER_ALL_ITEM", (filter) =>
//   apiGet(urlItemsSecured + generateFilterAllQuery(filter), undefined, true)()
// );

// /**
//  * Get all varieties.
//  */
// export const getAllVarietiesCascara = createAction(
//   "GET_ALL_VARIETIES_CASCARA",
//   () => apiGet(urlItemsVarietiesCascaraSecured, undefined, true)()
// );

// /**
//  * Get all varieties.
//  */
// export const getAllVarieties = createAction("GET_ALL_VARIETIES", () =>
//   apiGet(urlItemsVarietiesSecured, undefined, true)()
// );

// /**
//  * Filter calibres.
//  */
// export const filterCalibresVariety = createAction(
//   "FILTER_ALL_CALIBRES",
//   (filter) =>
//     apiGet(
//       urlItemsCalibresSecured + generateFilterAllQuery(filter),
//       undefined,
//       true
//     )()
// );

// /**
//  * Filter list orders.
//  */
// export const listItemsToExport = createAction(
//   "EXPORT_FILTER_ITEMS",
//   (filter, param) =>
//     apiGet(urlExportItemsSecured + generateFilterQuery(filter), param, true)()
// );
