import React, { useState, useCallback, useEffect } from "react";
import List from "../../outProcess/outProcessList";
// import Filter from "../orderFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import * as OrderServices from "../../../../services/OrderServices";
import { ContainerData, LabelData } from "./orderDetail.styled";
import moment from "moment-timezone";
import Breadcrumb from "../../../utils/breadCrumbs";

const queryClient = new QueryClient();

const OrderDetailContainer = ({ uuid }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.order.title"), path: PATHS.LIST_ORDERS },
    { label: t("forms.order.detail_title") },
  ];

  useEffect(() => {
    OrderServices.getDetail(uuid).then((order) => {
      setData(order);
    });
  }, [uuid]);

  const goBack = async () => {
    navigate(PATHS.LIST_ORDERS);
  };

  const renderPoints = () => {
    const points = Object.keys(data.outPoints);
    return points.map((point) => {
      const { dataPoint, dataItem } = data.outPoints[point];
      return (
        <div key={point} className="row">
          <div className="col-12">
            <LabelData>{t(dataPoint.name)}:</LabelData>
            {dataItem.name}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11">
          <h3 className="title">{t("forms.order.detail_title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          {data ? (
            <>
              <div className="row">
                <div className="col-12 col-md-8 col-lg-4">
                  <div className="row mt-4 mt-md-5 mb-3">
                    <div className="col-12 subtitle">
                      {t("forms.order.entryData")}
                    </div>
                  </div>
                  <ContainerData>
                    <div className="row">
                      <div className="col-12">
                        <LabelData>{t("forms.order.labels.batch")}:</LabelData>
                        {data.batch}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <LabelData>
                          {t("forms.order.labels.entryPoint")}:
                        </LabelData>
                        {data.entryPoint}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <LabelData>
                          {t("forms.order.labels.product")}:
                        </LabelData>
                        {data.variety}
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-12">
                        <LabelData>{t("forms.order.labels.batch")}Estado:</LabelData>
                        {data.status}
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-12">
                        <LabelData>
                          {t("forms.order.labels.dateInit")}:
                        </LabelData>
                        {moment(new Date(data.dateInit)).format(
                          "DD-MM-yyyy HH:mm:ss"
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <LabelData>
                          {t("forms.order.labels.dateBlock")}:
                        </LabelData>
                        {data.dateBlocked
                          ? moment(new Date(data.dateBlocked)).format(
                              "DD-MM-yyyy HH:mm:ss"
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <LabelData>
                          {t("forms.order.labels.dateEnd")}:
                        </LabelData>
                        {data.dateEnd
                          ? moment(new Date(data.dateEnd)).format(
                              "DD-MM-yyyy HH:mm:ss"
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <LabelData>{t("forms.order.labels.origin")}:</LabelData>
                        {data.origin}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <LabelData>
                          {t("forms.order.labels.destination")}:
                        </LabelData>
                        {data.destination}
                      </div>
                    </div>
                  </ContainerData>
                  {data.outPoints ? (
                    <>
                      <div className="row mt-4 mt-md-5 mb-3">
                        <div className="col-12 subtitle">
                          {t("forms.order.labels.outPoint")}
                        </div>
                      </div>
                      <ContainerData>{renderPoints()}</ContainerData>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 col-md-12 col-lg-8 mt-4 mt-md-5">
                  <div className="row no-margin">
                    <div className="col-12 subtitle">
                      {t("forms.order.labels.outList")}
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-12">
                      <QueryClientProvider client={queryClient}>
                        <List filters={{ orderUuid: uuid }} />
                      </QueryClientProvider>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          
        </div>
      </div> */}
      <div className="row justify-content-center mt-5 mb-5">
        <div className="col-12 col-md-11 d-flex justify-content-center">
          <button
            className="btn btn-secondary btn-lg me-3"
            onClick={() => goBack()}
          >
            {t("forms.order.buttons.backOrderList")}
          </button>
        </div>
      </div>
    </>
  );
};

OrderDetailContainer.propTypes = {};

export default OrderDetailContainer;
