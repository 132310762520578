import React from "react";
import FilterList from "../../../components/user/turnHistory/turnHistoryFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const TurnListPage = (props) => {
  return <Layout body={<FilterList />} />;
};

TurnListPage.propTypes = {};

export default TurnListPage;
