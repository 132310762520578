import React from "react";
import CloseTurn from "../../../components/user/turnHistory/closeTurn";
import Layout from "../../../components/layouts/verticalLayout";

const CloseTurnPage = (props) => {
  return <Layout body={<CloseTurn />} />;
};

CloseTurnPage.propTypes = {};

export default CloseTurnPage;
