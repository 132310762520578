import React from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./blockOrder.data.form";
import InputText from "../../../utils/InputText";

const BlockOrderForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, cancelAction, changeSilo } = props;

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <h3 className="title">
            {t(
              `forms.order.dialog.block.${changeSilo ? "titleSilo" : "title"}`
            )}
          </h3>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11 marg-btm-1rem">
          <p>
            {t(`forms.order.dialog.block.${changeSilo ? "textSilo" : "text"}`)}
          </p>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12 mt-2">
                <Field
                  id="totalWeight"
                  className="form-control"
                  labelField={t("forms.order.labels.totalWeight")}
                  name="totalWeight"
                  component={InputText}
                  fieldType="number"
                  placeholder=""
                  mandatory
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center mt-5 mb-5">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t(
                    `forms.order.dialog.block.${
                      changeSilo ? "buttonNoSilo" : "buttonNo"
                    }`
                  )}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t(
                    `forms.order.dialog.block.${
                      changeSilo ? "buttonOkSilo" : "buttonOk"
                    }`
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

BlockOrderForm.propTypes = {
  handleSubmit: PropTypes.func,
  cancelAction: PropTypes.func,
};

const BlockOrderFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "BlockOrderForm",
})(BlockOrderForm);

export default BlockOrderFormF;
