import React, { useState } from "react";
import List from "../stopHistoryList";
import Filter from "../stopFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import * as StopServices from "../../../../services/StopServices";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import {
  LIST_CATEGORIES,
  MACHINES,
  ALL_CAUSES,
} from "../../../../constants/index";
import { converToTime } from "../../../../utils/formats/index";

const queryClient = new QueryClient();

const StopHistoryFilterListContainer = (props) => {
  const { t } = useTranslation();

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.stopHistory.title") },
  ];
  const [filters, setFilters] = useState(undefined);
  const navigate = useNavigate();

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const exportToExcel = async () => {
    const filter = {
      filters,
      sort: "stopDate",
    };
    StopServices.listToExport(filter).then((result) => {
      const data = result.map((stop) => {
        const cat = LIST_CATEGORIES.find((c) => c.code === stop.category);
        const cause = ALL_CAUSES.find((c) => c.code === stop.cause);
        const machine = MACHINES.find((c) => c.code === stop.machine);

        return {
          A: new Date(stop.stopDate),
          B: stop.initDate ? new Date(stop.initDate) : "-",
          C: stop.time ? converToTime(stop.time) : "-",
          D: stop.category ? t(cat.label) : "",
          E: stop.cause ? t(cause.label) : "",
          F: stop.machine ? t(machine.label) : "",
          G: stop.commentsIni ? stop.commentsIni : "-",
          H: stop.commentsEnd ? stop.commentsEnd : "-",
          I: stop.userStop.email,
          J: stop.userInit ? stop.userInit.email : "-",
        };
      });
      data.unshift({
        A: "Parada",
        B: "Reinicio",
        C: "Duración",
        D: "Tipo",
        E: "Motivo",
        F: "Equipo que genera paro",
        G: "Comentarios al parar",
        H: "Comentarios al reanudar",
        I: "Usuario Parada",
        J: "Usuario Reinicio",
      });

      const filename = `Paradas_export_${moment(new Date()).format(
        "DDMMyyyyHHmm"
      )}`;
      const sheetName = "Listado de paradas";
      var ws = XLSX.utils.json_to_sheet(data, {
        header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
        skipHeader: true,
        UTC: true,
        dateNF: "dd-mm-yyyy hh:mm;@",
      });
      // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.stopHistory.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div>
      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-11 mt-3 text-end">
          <button
            type="button"
            className="btn btn-md btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
    </>
  );
};

StopHistoryFilterListContainer.propTypes = {};

export default StopHistoryFilterListContainer;
