import { useState, useEffect } from "react";
import * as TurnServices from "../../../../services/TurnServices";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import { ButtonClose } from "./style";
import CheckInForm from "../checkIn";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";

export const useTurn = (refresh) => {
  const dispatch = useDispatch();
  const [lastTurn, setLastTurn] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    TurnServices.getLastTurn().then((result) => {
      setLastTurn(result);
    });
  }, []);

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const showCheckInDialog = () => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <CheckInForm
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={(values) => register(values)}
                ></CheckInForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const register = async (values = null) => {
    const result = await TurnServices.registerTurn(values);
    if (result === "ERROR_TURN_OPEN") {
      showCheckInDialog();
    } else {
      setLastTurn(result);
      if (refresh) {
        refresh();
      }
      handlerClosePopup("ModalForm");
      showDialog(
        t(`forms.turnHistory.message.register.title`),
        t("forms.turnHistory.message.register.text"),
        "success",
        false,
        t("forms.turnHistory.message.register.buttonOk"),
        false
      );
    }
  };

  const closeTurn = async (values = null) => {
    if (!values.weight || values.weight <= 0) {
      showDialog(
        t(`forms.turnHistory.message.close.title`),
        t("forms.turnHistory.message.close.errorWeight"),
        "error",
        false,
        t("forms.turnHistory.message.close.buttonOk"),
        false
      );
      return false;
    }
    const result = await TurnServices.closeTurn(values);
    setLastTurn(result);
    if (refresh) {
      refresh();
    }
    navigate(PATHS.HOME);
    handlerClosePopup("ModalForm");
    showDialog(
      t(`forms.turnHistory.message.close.title`),
      t("forms.turnHistory.message.close.text"),
      "success",
      false,
      t("forms.turnHistory.message.close.buttonOk"),
      false
    );
  };

  const registerTurn = async () => {
    if (lastTurn) {
      navigate(PATHS.CLOSE_TURN);
      // dispatch(
      //   show("ModalForm", {
      //     body: (
      //       <>
      //         <div className="row mb-3">
      //           <div className="col-12 text-end">
      //             <ButtonClose
      //               type="button"
      //               onClick={() => handlerClosePopup("ModalForm")}
      //             >
      //               <i className="fa-solid fa-xmark"></i>
      //             </ButtonClose>
      //           </div>
      //         </div>
      //         <div className="row">
      //           <div className="col-12">
      //             <CheckOutForm
      //               onCancel={() => handlerClosePopup("ModalForm")}
      //               onSuccess={(values) => register(values)}
      //             ></CheckOutForm>
      //           </div>
      //         </div>
      //       </>
      //     ),
      //   })
      // );
    } else {
      register({});
    }
  };

  return { lastTurn, registerTurn, closeTurn };
};
