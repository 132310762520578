import { store } from "../store/index";
import {
  register,
  filterOrder,
  registerProc,
  getOrder,
  filterOutProcess,
  registerRepro,
  checkItemOut,
  blockOrderProd,
  closeOrderProd,
  registerProcChange,
  update,
  listMovesToExport,
  getOrderOpen,
} from "../store/actions/OrderActions";
import moment from "moment-timezone";
import { NORMAL_ENTRY_CODE } from "../constants/index";
/**
 * List order
 */
export const listFilterOrders = async (filter) => {
  const result = await store.dispatch(filterOrder(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const registerEntry = async (values, outSelected) => {
  const obj = {
    varietyCode: values.varietyCode,
    variety: values.variety,
    origin: values.origin,
    destination: values.destination,
    batch: values.batch,
    harvestYear: values.harvestYear,
    itemCode: values.itemCode,
  };
  obj.type = NORMAL_ENTRY_CODE;
  //Salida --> item
  if (outSelected) {
    obj.outPoints = outSelected;
  }
  const result = await store.dispatch(register(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const updateEntry = async (values, outSelected, uuidOrder) => {
  const obj = {
    varietyCode: values.varietyCode,
    variety: values.variety,
    origin: values.origin,
    destination: values.destination,
    batch: values.batch,
    harvestYear: values.harvestYear,
    itemCode: values.itemCode,
  };
  obj.type = NORMAL_ENTRY_CODE;
  //Salida --> item
  if (outSelected) {
    obj.outPoints = outSelected;
  }
  const result = await store.dispatch(update(obj, [uuidOrder]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const registerOut = async (values, processData, isPrincipal) => {
  const obj = { ...values };
  obj.productionDate = moment(values.productionDate).valueOf();
  obj.validateDate = moment(values.validateDate).valueOf();
  obj.outPoint = processData.uuid;
  obj.isPrincipal = isPrincipal !== null ? isPrincipal : false;
  const result = await store.dispatch(registerProc(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

export const registerChange = async (values, processData) => {
  const obj = {
    outPoint: processData.uuid,
    outBatch: values.outBatch,
  };
  const result = await store.dispatch(registerProcChange(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

export const registerEntryData = async (
  initial,
  processData,
  workPartidora
) => {
  const obj = {
    batch: initial.outBatch,
    weight: initial.outWeight,
    entryPoint: processData.uuid,
    itemUuid: initial.itemCode,
    workPartidora: workPartidora !== null ? workPartidora : false,
  };
  const result = await store.dispatch(registerRepro(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const getOpen = async () => {
  const result = await store.dispatch(getOrderOpen());
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const getDetail = async (uuid) => {
  const result = await store.dispatch(getOrder(uuid));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * List out process
 */
export const listFilterOutProcess = async (filter) => {
  const result = await store.dispatch(filterOutProcess(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const checkItemWithOutput = async (itemCode, output) => {
  const result = await store.dispatch(checkItemOut([itemCode, output]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const blockOrder = async (values, orderUuid, addCopy) => {
  const obj = {
    totalWeight: values.totalWeight,
    orderUuid: orderUuid,
    addCopy: addCopy,
  };
  if (values.origin) {
    obj.origin = values.origin;
  }
  const result = await store.dispatch(blockOrderProd(obj));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const closeOrder = async (orderUuid) => {
  const result = await store.dispatch(closeOrderProd([orderUuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * List order
 */
export const listToExport = async (filter) => {
  const result = await store.dispatch(listMovesToExport(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
