import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import {
  ContainerBB,
  TitleBB,
  TitleJunction,
  ButtonRemove,
} from "./juctionResume.styled";

const JunctionView = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, openJunction, registerBB, finish, removeItem } = props;

  let sumIn = 0;
  let sumOut = 0;

  if (data) {
    if (data.in && data.in.length > 0) {
      for (const dataIn of data.in) {
        sumIn += dataIn.weight;
      }
    }
    if (data.out && data.out.length > 0) {
      for (const dataOut of data.out) {
        sumOut += dataOut.weight;
      }
    }
  }

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.junction.title") },
  ];

  const renderBB = (bbs) => {
    if (bbs && bbs.length > 0) {
      return bbs.map((bb) => {
        return (
          <div className="row mb-1" key={bb.batch}>
            <div className="col-4">{bb.batch}</div>
            <div className="col-4 text-center">{`${bb.itemCode} (${bb.variety})`}</div>
            <div className="col-2 text-end">{`${bb.weight}Kg`}</div>
            <div className="col-2 text-end">
              <ButtonRemove type="button" onClick={() => removeItem(bb)}>
                <i className="fa-solid fa-trash-can"></i>
              </ButtonRemove>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="row">
          <div className="col-12 text-center">{t("forms.junction.noBb")}</div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11">
          <h3 className="title">{t(`forms.junction.title`)}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />

      {!data && (
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-md-11 text-center">
            <div>{t("forms.junction.noJunction")}</div>
            <div>
              <button
                type="button"
                className="btn btn-lg btn-primary mt-5"
                onClick={() => openJunction()}
              >
                {t("forms.junction.buttons.init")}
              </button>
            </div>
          </div>
        </div>
      )}
      {data && (
        <>
          <div className="row justify-content-center mt-4">
            <TitleJunction className="col-12 col-md-11 d-flex justify-content-between">
              <div>
                {`${t("forms.junction.junctionOpen")} (${moment(
                  data.dateInit
                ).format("DD-MM-yyyy HH:mm")})`}
              </div>
              <button
                type="button"
                className="btn btn-md btn-secondary"
                onClick={() => finish(sumIn, sumOut)}
              >
                {t("forms.junction.buttons.finish")}
              </button>
            </TitleJunction>
          </div>
          <div className="row justify-content-center mt-2">
            <div className="col-12 col-md-11">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <TitleBB className="col-12 d-flex justify-content-between">
                      <div>{t("forms.junction.bbIn")}</div>
                      <div>{`Total: ${sumIn}Kg`}</div>
                    </TitleBB>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <ContainerBB>{renderBB(data.in)}</ContainerBB>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <TitleBB className="col-12 d-flex justify-content-between">
                      <div>{t("forms.junction.bbOut")}</div>
                      <div>{`Total: ${sumOut}Kg`}</div>
                    </TitleBB>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <ContainerBB>{renderBB(data.out)}</ContainerBB>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 text-center">
                  <button
                    type="button"
                    className="btn btn-lg btn-primary mt-5"
                    onClick={() => registerBB("IN")}
                  >
                    {t("forms.junction.buttons.in")}
                  </button>
                </div>
                <div className="col-6 text-center">
                  <button
                    type="button"
                    className="btn btn-lg btn-primary mt-5"
                    onClick={() => registerBB("OUT")}
                  >
                    {t("forms.junction.buttons.out")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

JunctionView.propTypes = {
  // handleSubmit: PropTypes.func,
  // initialValues: PropTypes.object,
};

export default JunctionView;
