import React, { useState } from "react";
import List from "../orderList";
import Filter from "../orderFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";

const queryClient = new QueryClient();

const OrderFilterListContainer = (props) => {
  const { t } = useTranslation();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.order.title") },
  ];

  const [filters, setFilters] = useState(undefined);
  const navigate = useNavigate();

  const goToForm = async () => {
    navigate(PATHS.NEW_ORDER);
  };

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.order.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11 d-flex justify-content-center">
          <button
            className="btn btn-secondary btn-lg"
            onClick={() => goToForm()}
          >
            {t("forms.order.buttons.new")}
          </button>
        </div>
      </div>
    </>
  );
};

OrderFilterListContainer.propTypes = {};

export default OrderFilterListContainer;
