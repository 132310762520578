import React from "react";
import OrderDetail from "../../../components/user/order/orderDetail";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const DetailOrderPage = () => {
  let { uuid } = useParams();
  return (
    <Layout
      body={
        <>
          <OrderDetail uuid={uuid} />
        </>
      }
    />
  );
};

DetailOrderPage.propTypes = {};

export default DetailOrderPage;
