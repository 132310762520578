import * as Yup from "yup";

export const dataModel = {
  comment: "",
  category: "",
  cause: "",
  machine: "",
  initDate: "",
  endDate: "",
};

export const validationDataModel = (edit, custom) => {
  if (custom) {
    return Yup.object().shape({
      category: Yup.string().required("forms.validations.required"),
      cause: Yup.string().required("forms.validations.required"),
      machine: Yup.string().optional("forms.validations.required"),
      comment: Yup.string().optional("forms.validations.required"),
      initDate: Yup.date().required("forms.validations.required"),
      endDate: Yup.date()
        .required("forms.validations.required")
        .min(Yup.ref("initDate"), "forms.validations.dateGreaterThan"),
    });
  } else {
    return Yup.object().shape({
      category: Yup.string().required("forms.validations.required"),
      cause: Yup.string().required("forms.validations.required"),
      machine: Yup.string().optional("forms.validations.required"),
      comment: Yup.string().optional("forms.validations.required"),
    });
  }
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
