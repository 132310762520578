import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import ExtControlForm from "./extControlForm.view";
import * as OrderServices from "../../../../services/OrderServices";
import * as ItemService from "../../../../services/ItemService";
import * as ExtControlServices from "../../../../services/ExtControlServices";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const NewExtControlFormContainer = ({ uuid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [varieties, setVarieties] = useState([]);
  const [initValues, setInitValues] = useState({});
  const [custom, setCustom] = useState(false);
  const [order, setOrder] = useState(null);

  const setInitialValues = useCallback(async (order, uuidExt = null) => {
    let data = {};
    if (uuidExt) {
      data = {
        date: new Date(order.date),
        variety: order.itemCode,
        silo: order.silo,
        batch: order.batch,
        humFactoryIn: order.humFactoryIn,
        humPartidoraIn: order.humPartidoraIn,
        humSmartOut: order.humSmartOut,
        humSecOut: order.humSecOut,
        comments: order.comments,
      };
    } else {
      data = {
        date: new Date(),
        variety: order.item.code,
        silo: order.origin,
        batch: order.batch,
      };
      setOrder(order);
    }

    setInitValues(data);
  }, []);

  const loadData = useCallback(async () => {
    const resultItems = await ItemService.getVarietiesCascara();
    if (resultItems && resultItems.length > 0) {
      const dataVarieties = resultItems.map((item) => {
        return {
          code: item.code,
          label: item.name,
          varietyCode: item.varietyCode,
          variety: item.variety,
        };
      });
      setVarieties(dataVarieties);
    }
    if (uuid) {
      let result = await ExtControlServices.getExt(uuid);
      if (result) {
        setInitialValues(result, uuid);
        setCustom(true);
      }
    } else {
      const resultOrder = await OrderServices.getOpen();
      if (resultOrder) {
        setInitialValues(resultOrder);
      } else {
        setCustom(true);
      }
    }
  }, [setInitialValues, uuid]);

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (values) => {
    let title = "";
    let text = "";
    let icon = "";
    try {
      const okAction = () => {
        navigate(PATHS.EXT_CONTROL_LIST);
      };
      const varietySelected = varieties.find(
        (va) => va.code === values.variety
      );
      values.variety = varietySelected.variety;
      values.varietyCode = varietySelected.varietyCode;
      values.itemCode = varietySelected.code;
      let result;
      if (uuid) {
        result = await ExtControlServices.update(values, uuid);
      } else {
        result = await ExtControlServices.register(values, custom);
      }
      if (result) {
        title = t("forms.extControl.messages.register.title");
        text = t("forms.extControl.messages.register.success");
        icon = "success";
      }
      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const handlerOnCustom = async (value) => {
    if (!value && order) {
      setInitialValues(order);
      setCustom(value);
    } else if (value && order) {
      setCustom(value);
    } else if (!order) {
      setCustom(true);
    }
  };

  return (
    <ExtControlForm
      uuid={uuid}
      initialValues={initValues}
      onSubmit={handleSubmit}
      varieties={varieties}
      custom={custom}
      onCustomRegister={handlerOnCustom}
    />
  );
};

NewExtControlFormContainer.propTypes = {};

export default NewExtControlFormContainer;
