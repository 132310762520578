import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./readBB.data.form";
import InputText from "../../../utils/InputText";
import InputSelect from "../../../utils/InputSelect";
import InputDate from "../../../utils/InputDate";
import { ContainerData, LabelData } from "./juctionResume.styled";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

const ReadBbForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, onScan1, onScan2, goBack, action } = props;
  const navigate = useNavigate();
  const [read, setRead] = useState("");
  // const [firstReader, setFirstReader] = useState(false);
  const [value] = useDebounce(read, 500);
  const inputRef = useRef(null);
  const breads = [{ label: t("home"), path: PATHS.HOME }];
  breads.push({ label: t("forms.process.activity") });
  breads.push({ label: t("forms.process.processStep02") });

  const harvestYears = [];
  const currentYear = new Date().getFullYear();

  for (let index = 0; index < 5; index++) {
    harvestYears.push({
      code: currentYear - index,
      label: currentYear - index,
    });
  }

  useEffect(() => {
    const type = value.substring(0, 2);

    if (type === "02") {
      onScan1(read);
      setRead("");
    }
    if (type === "00") {
      onScan2(read);
      setRead("");
    }
  }, [value]);

  // useEffect(() => {
  //   if (props.values.itemCode && props.values.outBatch) {
  //     props.submitForm();
  //     // props.onSubmit(props.values);
  //   }
  // }, [props.values.itemCode, props.values.outBatch]);

  const reset = () => {
    setRead("");
    inputRef.current.focus();
  };

  // handleSubmit
  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11">
          <h3 className="title">{`${t(`forms.junction.titlePoint`)} (${t(
            `forms.junction.action.${action}`
          )})`}</h3>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-4">
          <div className="input-group mb-3">
            <input
              autoFocus
              ref={inputRef}
              type="text"
              value={read}
              autoComplete="off"
              className="form-control"
              onChange={(event) => {
                setRead(event.target.value);
              }}
            />
            <button
              className="btn btn-secondary"
              type="button"
              id="button-addon2"
              onClick={() => reset()}
            >
              <i className="fa-solid fa-ban"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3 mt-2">
                <Field
                  id="harvestYear"
                  name="harvestYear"
                  labelField={t("forms.process.labels.harvestYear")}
                  // className="form-control"
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={harvestYears.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                  readOnly
                />
              </div>
              <div className="col-12 col-md-4 col-lg-4 mt-2">
                <Field
                  id="productionDate"
                  labelField={t("forms.process.labels.productionDate")}
                  className="form-control"
                  name="productionDate"
                  component={InputDate}
                  language="es"
                  dateFormat="dd/MM/yyyy"
                  mandatory
                  readOnly
                />
              </div>
              <div className="col-12 col-md-4 col-lg-4 mt-2">
                <Field
                  id="validateDate"
                  labelField={t("forms.process.labels.validateDate")}
                  className="form-control"
                  name="validateDate"
                  component={InputDate}
                  language="es"
                  dateFormat="dd/MM/yyyy"
                  mandatory
                  readOnly
                />
              </div>
              <div className="col-12 col-md-4 col-lg-2 mt-2">
                <Field
                  id="outWeight"
                  className="form-control"
                  labelField={t("forms.process.labels.outWeight")}
                  name="outWeight"
                  component={InputText}
                  fieldType="text"
                  placeholder=""
                  mandatory
                  readOnly
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2 mt-2">
                <Field
                  id="itemCode"
                  className="form-control"
                  labelField={t("forms.process.labels.itemCode")}
                  name="itemCode"
                  component={InputText}
                  fieldType="text"
                  placeholder=""
                  mandatory
                  readOnly
                />
              </div>
              <div className="col-12 col-md-3 col-lg-3 mt-2">
                <Field
                  id="outBatch"
                  className="form-control"
                  labelField={t("forms.process.labels.outBatch")}
                  name="outBatch"
                  component={InputText}
                  fieldType="text"
                  placeholder=""
                  mandatory
                  readOnly
                />
              </div>
              <div className="col-12 col-md-4 col-lg-4 mt-2">
                <Field
                  id="sscc"
                  className="form-control"
                  labelField={t("forms.process.labels.sscc")}
                  name="sscc"
                  component={InputText}
                  fieldType="text"
                  placeholder=""
                  mandatory
                  readOnly
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 mt-2">
                <Field
                  id="comments"
                  className="form-control"
                  labelField={t("forms.process.labels.comments")}
                  name="comments"
                  component={InputTextarea}
                  placeholder=""
                />
              </div>
            </div> */}
            <div className="row justify-content-center mb-5">
              <div className="col-10 col-sm-11 text-center mt-5">
                <button
                  type="button"
                  onClick={() => goBack(props.resetForm)}
                  className="btn btn-lg btn-cancel me-3"
                >
                  <i className="fa-solid fa-ban iconButton"></i>
                  {t("forms.process.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.process.buttons.continue")}
                  <i className="fa-solid fa-angles-right iconButton"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

ReadBbForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const ReadBBFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "ReadBbForm",
})(ReadBbForm);

export default ReadBBFormF;
